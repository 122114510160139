import {
  Button,
  Card,
  Col,
  Input,
  Row,
  Spin,
  Table,
  Typography,
  notification,
} from "antd";
import React, { useState, useEffect } from "react";
import { AuthService } from "../../Shared/Auth.service";
import { MetamaskService } from "../../Shared/Metamask.service";
import { TAPRegistryService } from "../../Shared/TAPRegistry/TAPRegistry.service";
import { environment } from "../../../environments/environment";
import { SharedService } from "../../Shared/Shared.service";
import TransactionModal from "../../Shared/TransactionModal";
import TxFeeDelegationModal from "../../Shared/TxFeeDelegationModal";
import { STFactoryService } from "../../Shared/STFactory/STFactory.service";

const { Title } = Typography;
const authService = new AuthService();
const tapRegistryService = new TAPRegistryService();
const sharedService = new SharedService();

// const sTFactoryService = new STFactoryService();
const useUserContext = () => authService.useUserContext();
const useSelectedWalletContext = () =>
  new MetamaskService().useSelectedWalletContext();

interface IContract {
  name: string;
  address: string;
  owner: string;
}

const minifyAddress = (hash: string) => {
  return sharedService.minifyAddress(hash);
};

const etherscanURL = sharedService.etherscanURL;

export default function TransferSCArchitectureOwnership() {
  const { userInfo } = useUserContext();
  const { selectedWallet, networkId } = useSelectedWalletContext();
  const [transactions, setTransactions] = useState<
    { submitting?: boolean; receipt?: any; details: string }[]
  >([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDelegationModalVisible, setIsDelegationModalVisible] =
    useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [contracts, setContracts] = useState<IContract[]>();
  const [newOwner, setNewOwner] = useState<string>("");
  const [selectedContract, setSelectedContract] = useState<IContract>();

  const columns = [
    {
      title: "Smart Contract name",
      dataIndex: "name",
    },
    {
      title: "Smart Contract address",
      dataIndex: "address",
      render: (value: string) => (
        <a
          href={`${etherscanURL[networkId as string]}/address/${value}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {minifyAddress(value)}
        </a>
      ),
    },
    {
      title: "Curent owner",
      dataIndex: "owner",
      render: (value: string) => (
        <a
          href={`${etherscanURL[networkId as string]}/address/${value}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {minifyAddress(value)}
        </a>
      ),
    },
    {
      title: "Action",
      render: (value: any, record: IContract) => (
        <>
          <Button
            size="small"
            type="primary"
            onClick={() => openDelegationModal(record)}
          >
            Transfer Ownership
          </Button>
        </>
      ),
    },
  ];

  useEffect(() => {
    (async () => {
      if (!userInfo || !selectedWallet) return;

      // console.log("sTFactoryService.TAPRegistry()", await sTFactoryService.TAPRegistry());

      const registryList = await tapRegistryService.getRegistryList();
      console.log(registryList);

      const owners = await Promise.all([
        tapRegistryService.owner(environment.tapRegistryAddress),
        tapRegistryService.owner(
          registryList.find((r) => r.name === "SECURITYTOKENREGISTRY")
            ?.registryAddress as string
        ),
        tapRegistryService.owner(
          registryList.find((r) => r.name === "STFACTORY")?.registryAddress as string
        ),
      ]);
      console.log(owners);

      const _contracts = [
        {
          name: "TAP Registry",
          address: environment.tapRegistryAddress,
          owner: owners[0],
        },
        {
          name: "Security Token Registry",
          address: registryList.find((r) => r.name === "SECURITYTOKENREGISTRY")
            ?.registryAddress as string,
          owner: owners[1],
        },
        {
          name: "Security Token Factory",
          address: registryList.find((r) => r.name === "STFACTORY")
            ?.registryAddress as string,
          owner: owners[2],
        },
      ];

      setContracts(_contracts);
      setLoading(false);
    })();
  }, [userInfo, networkId, selectedWallet]);

  const openDelegationModal = (record: IContract) => {
    if (!sharedService.isEthereumAddress(newOwner)) {
      return notification.error({
        message: "Error",
        description: "Invalid new Owner address",
      });
    }

    if (newOwner?.toLowerCase() === record.owner.toLowerCase()) {
      return notification.error({
        message: "Error",
        description:
          "New Owner address cannot be the same as the current owner",
      });
    }

    if (selectedWallet?.toLowerCase() !== record.owner.toLowerCase()) {
      return notification.error({
        message: "Select the current Owner in your Metamask Wallet",
        description: "You are not the current owner of this Smart Contract",
      });
    }

    setSelectedContract(record);
    setIsDelegationModalVisible(true);
  };

  const closeTransactionModal = () => {
    setIsModalVisible(false);
    setTransactions([]);
    setSelectedContract(undefined);
  };

  const transferOwnership = async (prop: { delegate: boolean }) => {
    setIsModalVisible(true);
    setTransactions([{ details: "Transferring Ownership", submitting: true }]);

    try {
      const receipt = await tapRegistryService.transferOwnership(
        selectedContract?.address as string,
        selectedWallet as string,
        newOwner,
        { delegate: prop.delegate }
      );

      setTransactions((prev) => {
        const current = sharedService.clone(prev);
        current[0].receipt = receipt;
        return current;
      });

      if (receipt?.status) {
        setContracts((prev) => {
          const current = sharedService.clone(prev) as IContract[];
          const contract = current?.find(
            (c) => c.address === selectedContract?.address
          );
          if (contract) contract.owner = newOwner;
          return current;
        });
      }
    } catch (err) {
      console.error(err);
    }

    setTransactions((prev) => {
      const current = sharedService.clone(prev);
      current[0].submitting = false;
      return current;
    });
  };

  const updateNewOwner = (e: any) => {
    setNewOwner(e.target.value);
  };

  return (
    <Row justify="center">
      <Col span={24}>
        {loading && (
          <div style={{ textAlign: "center" }}>
            <br />
            <Spin size="large" />
          </div>
        )}

        {!loading && (
          <Card>
            <div style={{ textAlign: "center" }}>
              <Title level={2}>
                Transfer Smart contract Architecture Ownership
              </Title>
              <br />
              <br />
              <Row justify="center">
                <Col span={10}>
                  <h3 style={{ fontWeight: "bold", margin: 0 }}>New Owner</h3>
                  <Input value={newOwner} onChange={updateNewOwner} />
                </Col>
              </Row>
              <br />
              <br />
              <p style={{ color: "darkorange", fontWeight: "bold" }}>
                Select the current Owner in your Metamask Wallet in order to
                Transfer the Ownership
              </p>
            </div>

            <Table
              columns={columns}
              dataSource={contracts}
              pagination={false}
              rowKey="address"
            />
          </Card>
        )}

        <TransactionModal
          title={"Withdrawal Request"}
          transactions={transactions}
          isModalVisible={isModalVisible}
          closeModal={() => closeTransactionModal()}
        />

        <TxFeeDelegationModal
          isVisible={isDelegationModalVisible}
          onSubmit={({ delegate }) => {
            setIsDelegationModalVisible(false);
            transferOwnership({ delegate });
          }}
          onCancel={() => setIsDelegationModalVisible(false)}
        />
      </Col>
    </Row>
  );
}
