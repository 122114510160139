import React, { useState, useEffect } from "react";
import { TokenConfigurationProcess } from "./Shared/interfaces";
import { TokenConfigurationService } from "./TokenConfigurations/TokenConfiguration.service";
import { Select } from "antd";

const { Option } = Select;

const tokenConfigurationService = new TokenConfigurationService();

export default function TokenConfigSelect(props: {
  value: TokenConfigurationProcess;
  onChange: (tokenConfigurationProcess: TokenConfigurationProcess) => void;
  onLoad: () => void;
}) {
  const [tokenConfigurationProcesses, setTokenConfigurationProcesses] =
    useState<TokenConfigurationProcess[]>();

  useEffect(() => {
    (async () => {
      const _tokenConfigurationProcessesData =
        await tokenConfigurationService.getTokenConfigurationProcesses();

      const _tokenConfigurationProcesses =
        _tokenConfigurationProcessesData.data as TokenConfigurationProcess[];

      const filteredProcesses = [
        ...new Set(
          _tokenConfigurationProcesses.map((config) => config.tokenSymbol)
        ),
      ]
        .filter((tokenSymbol) => !!tokenSymbol)
        .map((tokenSymbol) => {
          return _tokenConfigurationProcesses
            .filter((config) => config.tokenSymbol === tokenSymbol)
            .sort((a, b) => b.creationTS - a.creationTS)[0];
        });

      setTokenConfigurationProcesses(filteredProcesses);
      props.onChange(filteredProcesses[0]);
      props.onLoad();
    })();
  }, []);

  const updateValue = (_id: string) => {
    const processFound = tokenConfigurationProcesses?.find(
        (config) => config._id === _id
    );

    props.onChange(processFound!);

  }

  return (
    <div style={{ textAlign: "center" }}>
      <br />
      <h3 style={{marginBottom: '10px'}}>Select Token</h3>
      {!tokenConfigurationProcesses && <div>Loading...</div>}
      {tokenConfigurationProcesses &&
        <Select
          size="large"
          showSearch
          placeholder="Select Token"
          value={props.value?._id}
          onChange={updateValue
          }
        >
          {tokenConfigurationProcesses?.map((config) => (
            <Option key={config._id} value={config._id}>
              {config.tokenSymbol}
            </Option>
          ))}
        </Select>
      }
      <br />
      <br />
    </div>
  );
}
