import { TrustedForwarderService } from "../TrustedForwarder.service";
import {environment} from '../../../environments/environment';
import { MetamaskService } from "../Metamask.service";
import { StoredRegistry } from "../interfaces";



const trustedForwarderService = new TrustedForwarderService();

export class TAPRegistryService {

  private get web3() {
    return MetamaskService.web3;
  }

  public contract = {
    address: environment.tapRegistryAddress,
    ABI: require('./ABI.json')
  }

  transferOwnership(
    contractAddress: string,
    owner: string,
    newOwner: string,
    extra: {delegate: boolean} = {delegate: false}
  ) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, contractAddress);

    const method = contractInstance.methods.transferOwnership(newOwner);

    if (!extra.delegate) return method.send({ from: owner });

    return trustedForwarderService.delegateTransaction({
      encodedFunction: method.encodeABI(),
      contractAddress: this.contract.address,
      signerAddress: owner
    });
  }

  async owner(contractAddress: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, contractAddress);
    return contractInstance.methods.owner().call() as string;
  }

  async getRegistryList() {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, this.contract.address);

    return contractInstance.methods.getRegistryList().call() as StoredRegistry[];
  }

}