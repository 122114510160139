// import * as firebase from 'firebase/app';
import {APIResponse, RegLaunched, User} from "./interfaces";
import jwtDecode from "jwt-decode";
import axios from "axios";
import { environment } from "../../environments/environment";
import BigNumber from "bignumber.js";
import { MetamaskService } from "./Metamask.service";
import _ from "lodash";
import ipfsClient from 'ipfs-http-client';
import ERC20Facet from "./SecurityToken/Facets/ERC20Facet";
import ERC1410Facet from "./SecurityToken/Facets/ERC1410Facet";
import ERC1594Facet from "./SecurityToken/Facets/ERC1594Facet";
import GeneralTransferManagerFacet from "./SecurityToken/Facets/GeneralTransferManagerFacet";
import MainFacet from "./SecurityToken/Facets/MainFacet";
import OwnershipFacet from "./SecurityToken/Facets/OwnershipFacet";
import Rule144Facet from "./SecurityToken/Facets/Rule144Facet";
import VestingFacet from "./SecurityToken/Facets/VestingFacet";
import WhitelistFacet from "./SecurityToken/Facets/WhitelistFacet";

const headers = {
  "Content-Type": "application/json",
  apiKey: environment.apiKey,
  "Acces-Control-Allow-Origin": "*",
  //'acces-control-allow-origin':'*'
};

export class SharedService {
  private APIURL = environment.APIURL;

  etherscanURL = {
    "1": "https://etherscan.io",
    "42": "https://kovan.etherscan.io",
    "137": "https://polygonscan.com"
  };

  ethereumNetworks = {
    "1": "Mainnet",
    "2": "Morden",
    "3": "Ropsten",
    "4": "Rinkeby",
    "5": "Goerli",
    "42": "Kovan",
    "137": "Polygon"
  };

  allowedNetworks = environment.allowedNetworks as string[];

  public menuHeader: { [key in User["role"]]: string } = {
    "platform issuer admin": "PLATFORM ISSUER ADMIN",
    "issuer super admin": "ISSUER SUPER ADMIN",
    employee: "EMPLOYEE",
    contractor_consultant_Advisor: "CONTRACTOR/CONSULTANT/ADVISOR",
    "issuer token admin": "ISSUER TOKEN ADMIN",
    "platform super admin": "PLATFORM SUPER ADMIN",
    investor: "INVESTOR",
    "issuer counsel": "ISSUER COUNSEL",
    "issuer company officer": "ISSUER COMPANY OFFICER",
    "platform compliance admin": "PLATFORM COMPLIANCE ADMIN",
    "issuer compliance admin": "ISSUER COMPLIANCE ADMIN",
  };

  public underlyingAssetOptions = [
    {
      value: "equity",
      name: "Equity",
    },
    {
      value: "real estate",
      name: "Real Estate",
    },
    {
      value: "corporate bond",
      name: "Corporate bond",
    },
    {
      value: "fund",
      name: "Fund",
    },

    {
      value: "dividend",
      name: "Dividend",
    },
  ];

  public statusOptions = [
    {
      value: "pending",
      name: "Pending",
    },
    {
      value: "verified",
      name: "Verified",
    },
    {
      value: "rejected",
      name: "Rejected",
    },
  ];

  public regOptions = [
    {
      name: "Regulation D Rule 506(c)",
      value: "regulation-d",
      shortValue: "reg-d",
      camelCaseValue: "regD",
    },
    {
      name: "Regulation S Category 3",
      value: "regulation-s",
      shortValue: "reg-s",
      camelCaseValue: "regS",
    },
    {
      name: "Regulation A+",
      value: "regulation-a-plus",
      shortValue: "reg-a-plus",
      camelCaseValue: "regAPlus",
    },
    {
      name: "Regulation CF",
      value: "regulation-cf",
      shortValue: "reg-cf",
      camelCaseValue: "regCf",
    },
  ];

  public typeOfSecurityOptions = [
    {
      name: "Equity Backed Securities",
      value: "equity backed securities",
    },
    // {
    //   name: 'Interest Paying Securities',
    //   value: 'interest paying securities'
    // },
    // {
    //   name: 'Convertibles',
    //   value: 'convertibles'
    // },
    // {
    //   name: 'Warrants',
    //   value: 'warrants'
    // },
    {
      name: "Preferential Securities",
      value: "preferential securities",
    },
    {
      name: "Dividend Paying Securities",
      value: "dividend paying securities",
    },
    // {
    //   name: 'Real Estate Token',
    //   value: 'real estate token'
    // },
  ];

  public commonStockOptions = [
    {
      name: "Seed Capital",
      value: "seed capital",
    },
    {
      name: "Series A",
      value: "series a",
    },
    {
      name: "Series B",
      value: "series b",
    },
    {
      name: "Series C",
      value: "series c",
    },
    // {
    //   name: "Common Stock - Serie A",
    //   value: "common stock serie a",
    // },
    // {
    //   name: "Common Stock - Serie C",
    //   value: "common stock serie c",
    // },
    // {
    //   name: "Preferred Stock - Serie A",
    //   value: "preferred stock serie a",
    // },
    // {
    //   name: "Preferred Stock - Serie B",
    //   value: "preferred stock serie b",
    // },
    // {
    //   name: "Preferred Stock - Serie C",
    //   value: "preferred stock serie c",
    // },
  ];

  public equitySecurityClassOptions = [
    {
      name: "No Class",
      value: "no class",
    },
    {
      name: "Class A",
      value: "class a",
    },
    {
      name: "Class B",
      value: "class b",
    },
    {
      name: "Class c",
      value: "class c",
    },
  ];

  public preferentialSecurityClassOptions = [
    {
      name: "Class A",
      value: "class a",
    },
    {
      name: "Class B",
      value: "class b",
    },
    {
      name: "Class c",
      value: "class c",
    },
  ];

  corporateRolesOptions = [
    {
      name: "Director",
      value: "director",
    },
    {
      name: "Large Shareholder",
      value: "large shareholder",
    },
    {
      name: "Executive Officer",
      value: "executive officer",
    },
  ];

  adminRolesOptions = [
    {
      name: "Issuer Token Admin",
      value: "issuer token admin",
    },
    {
      name: "Issuer Company Officer",
      value: "issuer company officer",
    },
    {
      name: "Issuer Compliance Admin",
      value: "issuer compliance admin",
    },
    {
      name: "Issuer Super Admin",
      value: "issuer super admin",
    },
    {
      name: "Issuer Counsel",
      value: "issuer counsel",
    },
  ];

  public underlyingAssetName = (value: string) =>
    this.underlyingAssetOptions.find((option) => option.value === value)?.name;
  public statusName = (value: string) =>
    this.statusOptions.find((option) => option.value === value)?.name;
  public regulationName = (value: string) =>
    this.regOptions.find((option) => option.value === value)?.name;
  public typeOfSecurityName = (value: string) =>
    this.typeOfSecurityOptions.find((option) => option.value === value)?.name;
  public commonStockName = (value: string) =>
    this.commonStockOptions.find((option) => option.value === value)?.name;


  assetName (regLaunched: RegLaunched) {
    const regulation = this.regOptions.find(
      (regOp) =>
        regOp.shortValue === regLaunched?.regulation
    )?.name as string;

    const typeOfSecurity = this.typeOfSecurityOptions.find(
      opt => opt.value === regLaunched?.typeOfSecurity
    )?.name;

    const commonStock = this.commonStockOptions.find(
      opt => opt.value === regLaunched?.commonStock
    )?.name;

    return (
      regulation
      + ' - ' +
      typeOfSecurity
      +
      (commonStock ? (' - ' + commonStock) : '')
    );
  }

  async getCountries() {
    return axios.get<any, APIResponse>(`${this.APIURL}/shared/getCountries`, {
      headers,
    });
  }
  async getUSAStates() {
    return axios.get<any, APIResponse>(`${this.APIURL}/shared/getUSAStates`, {
      headers,
    });
  }
  setToken(token: string) {
    localStorage.setItem("token", token);
  }

  getToken() {
    return localStorage.getItem("token");
  }

  deleteToken() {
    localStorage.removeItem("token");
  }

  get isValidToken() {
    const token = this.getToken();
    return !!token && (jwtDecode(token) as any).exp >= Date.now() / 1000;
  }

  async getAuthHeader() {
    return { ...headers, Authorization: this.getToken() };
  }

  minifyAddress(address: string) {
    return `${address.substr(0, 7)}...${address.substr(address.length - 7)}`;
  }

  minifyTxHash(hash: string) {
    return `${hash.substr(0, 10)}...${hash.substr(hash.length - 4)}`;
  }

  public countdown(upgradeTime: number, callback) {
    let seconds = Math.floor(upgradeTime / 1000);

    const countdownTimer = setInterval(() => {
      const days = Math.floor(seconds / 24 / 60 / 60);
      const hoursLeft = Math.floor(seconds - days * 86400);
      const hours = Math.floor(hoursLeft / 3600);
      const minutesLeft = Math.floor(hoursLeft - hours * 3600);
      const minutes = Math.floor(minutesLeft / 60);
      const remainingSeconds = seconds % 60;

      let displayable = {
        days,
        hours,
        minutes,
        seconds: remainingSeconds,
      };

      callback(displayable, false);

      if (seconds === 0) {
        clearInterval(countdownTimer);
        callback(displayable, true);
      } else {
        seconds--;
      }
    }, 1000);

    return countdownTimer;
  }

  public getFileType(name: string) {
    return name.substr(name.lastIndexOf(".") + 1);
  }

  // public newFileName(type:string, oldName?: string) {
  //   const db = firebase.firestore();
  //   let id = db.collection('uniqueId').doc().id;
  //   return `${oldName? `${oldName}-` : ''}${id}.${type}`;
  // }
  
  public uploadDocumentAsync = async (APIURL:string, req: FormData) => {
    return fetch(APIURL, {
      method: 'POST',
      headers: {
        'apiKey': environment.apiKey,
      },
      body: req
    })
  }

  public uploadDocument(
    APIURL: string,
    req: FormData,
    progressCb,
    errorCb,
    completeCb
  ) {
    // const token = await this.sharedService.getToken();
    const xhr = new XMLHttpRequest();

    xhr.upload.addEventListener("progress", (oEvent) => {
      if (oEvent.lengthComputable)
        progressCb(
          new BigNumber((oEvent.loaded / oEvent.total) * 100)
            .decimalPlaces(0)
            .toNumber()
        );
    });
    xhr.addEventListener("load", () => completeCb(JSON.parse(xhr.response)));
    xhr.addEventListener("error", () => errorCb());
    // `${this.APIURL}/tasks/uploadDocument`
    xhr.open("POST", APIURL);
    // xhr.setRequestHeader('Authorization', token);
    xhr.setRequestHeader("apiKey", environment.apiKey);
    xhr.send(req);
  }

  async uploadDocumentToIPFS(prop: {
    file: File | undefined
  }) {
    if(!prop.file) return;

    const ipfs = ipfsClient.create({
      host: 'ipfs.infura.io',
      port: 5001,
      protocol: 'https',
      // const auth =
      //   'Basic ' + Buffer.from(projectId + ':' + projectSecret).toString('base64')

      // headers: {
      //   authorization: auth
      // }
    });

    const res = await ipfs.add(prop.file);
    return {hash: res.path};
  }

  getIPFSDocumentURL(prop: {hash: string}) {
    // return `https://ipfs.io/ipfs/${prop.hash}`;
    return `https://ipfs.infura.io/ipfs/${prop.hash}`;
  }


  private get web3() {
    return MetamaskService.web3;
  }

  isEthereumAddress(address: string) {
    return this.web3.utils.isAddress(address);
  }

  stringToBytes32(value: string) {
    return this.web3.utils.utf8ToHex(value);
  }

  bytes32ToString(value: string) {
    return this.web3.utils.hexToUtf8(value);
  }

  clone = _.cloneDeep;

  async getDetailByWalletAddress(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/shared/getDetailByWalletAddress`,
      data,
      { headers }
    );
  }

  async changeSelectedRole(selectedRole: string) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/users/changeSelectedRole`,
      { selectedRole },
      { headers: await this.getAuthHeader() }
    );
  }

  async getCurrencyList(){
    return axios.get<any, APIResponse>(
        `${this.APIURL}/shared/getCurrencyList`,
        { headers: await this.getAuthHeader() }
      );
  }

  capitalizeFirstLetter(value: string) {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }

  camelCaseToText(value: string) {
    return this.capitalizeFirstLetter(value).replace(/([a-z0-9])([A-Z])/g, '$1 $2');
  }

  private securityTokenABI = [
    ...require('./SecurityToken/Facets/ERC20Facet/ABI.json'),
    ...require('./SecurityToken/Facets/ERC1410Facet/ABI.json'),
    ...require('./SecurityToken/Facets/ERC1594Facet/ABI.json'),
    ...require('./SecurityToken/Facets/GeneralTransferManagerFacet/ABI.json'),
    ...require('./SecurityToken/Facets/MainFacet/ABI.json'),
    ...require('./SecurityToken/Facets/OwnershipFacet/ABI.json'),
    ...require('./SecurityToken/Facets/Rule144Facet/ABI.json'),
    ...require('./SecurityToken/Facets/VestingFacet/ABI.json'),
    ...require('./SecurityToken/Facets/WhitelistFacet/ABI.json'),
  ];

  private static formatFunctionCallName(value: string) {
    if(value === 'addInvestorLockedAmountMulti') return 'issueTokensWithLockPeriod';
    if(value === 'assignVestingScheduleTemplateMulti') return 'assignVestingScheduleTemplates';
    return value;
  }



  private getSecurityTokenSignatures() {
    return this.securityTokenABI
      .filter(ABIEl => ABIEl.type === "function")
      .map(ABIEl => ({
        function: ABIEl.name as string,
        signature: this.web3.eth.abi.encodeFunctionSignature(ABIEl),
        label: this.camelCaseToText(SharedService.formatFunctionCallName(ABIEl.name))
    }));
  }

  findSecurityTokenSignatures(prop: {data: string}) {
    return this.getSecurityTokenSignatures().find(result =>
      prop.data
        .toLowerCase()
        .startsWith(result.signature.toLowerCase())
    );
  }





}
