import { MetamaskService } from "../../../Metamask.service";
import { TrustedForwarderService } from "../../../TrustedForwarder.service";
import { Price } from "../../../interfaces";

const trustedForwarderService = new TrustedForwarderService();

export default class EscrowFacet {
  contract = {
    ABI: require("./ABI.json"),
  };

  private get web3() {
    return MetamaskService.web3;
  }

  async escrowLastPartition(tokenAddress: string) {
    const contractInstance = new this.web3.eth.Contract(
      this.contract.ABI,
      tokenAddress
    );
    return (await contractInstance.methods
      .escrowLastPartition()
      .call()) as string;
  }

  async getDepositTokens(tokenAddress: string) {
    const contractInstance = new this.web3.eth.Contract(
      this.contract.ABI,
      tokenAddress
    );

    return (await contractInstance.methods
      .getDepositTokens()
      .call()) as string[];
  }

  async getTotalInvestedAmounts(tokenAddress: string, depositToken: string[]) {
    const contractInstance = new this.web3.eth.Contract(
      this.contract.ABI,
      tokenAddress
    );
    return (await contractInstance.methods
      .getTotalInvestedAmounts(depositToken)
      .call()) as string[];
  }

  async getTotalInvestors(tokenAddress: string, depositToken: string) {
    const contractInstance = new this.web3.eth.Contract(
      this.contract.ABI,
      tokenAddress
    );
    return (await contractInstance.methods
      .getTotalInvestors(depositToken)
      .call()) as string;
  }

  async getEscrowApprovers(tokenAddress: string) {
    const contractInstance = new this.web3.eth.Contract(
      this.contract.ABI,
      tokenAddress
    );
    return (await contractInstance.methods
      .getEscrowApprovers()
      .call()) as string[];
  }

  async getDepositTokenPrice(tokenAddress: string, depositToken: string) {
    const contractInstance = new this.web3.eth.Contract(
      this.contract.ABI,
      tokenAddress
    );

    return (await contractInstance.methods
      .getDepositTokenPrice(depositToken)
      .call()) as Price;
  }

  async getUsersInvestedAmounts(
    tokenAddress: string,
    depositToken: string[],
    from: string[]
  ) {
    const contractInstance = new this.web3.eth.Contract(
      this.contract.ABI,
      tokenAddress
    );

    return (await contractInstance.methods
      .getUsersInvestedAmounts(depositToken, from)
      .call()) as string[];
  }

  async updatePriceFeeds(
    tokenAddress: string,
    owner: string,
    depositTokensToAdd: string[],
    aggregatorsToAdd: string[],
    depositTokensToRemove: string[],
    extra: { delegate: boolean } = { delegate: false }
  ) {
    const contractInstance = new this.web3.eth.Contract(
      this.contract.ABI,
      tokenAddress
    );
    const method = contractInstance.methods.updatePriceFeeds(
      depositTokensToAdd,
      aggregatorsToAdd,
      depositTokensToRemove
    );

    if (!extra.delegate) return method.send({ from: owner });

    return trustedForwarderService.delegateTransaction({
      encodedFunction: method.encodeABI(),
      contractAddress: tokenAddress,
      signerAddress: owner,
    });
  }

  async approveWithdrawal(
    tokenAddress: string,
    owner: string,
    extra: { delegate: boolean } = { delegate: false }
  ) {
    const contractInstance = new this.web3.eth.Contract(
      this.contract.ABI,
      tokenAddress
    );
    const method = contractInstance.methods.approveWithdrawal();

    if (!extra.delegate) return method.send({ from: owner });

    return trustedForwarderService.delegateTransaction({
      encodedFunction: method.encodeABI(),
      contractAddress: tokenAddress,
      signerAddress: owner,
    });
  }

  async withdrawCollectedAmount(
    tokenAddress: string,
    owner: string,
    depositToken: string,
    extra: { delegate: boolean } = { delegate: false }
  ) {
    const contractInstance = new this.web3.eth.Contract(
      this.contract.ABI,
      tokenAddress
    );
    const method =
      contractInstance.methods.withdrawCollectedAmount(depositToken);

    if (!extra.delegate) return method.send({ from: owner });

    return trustedForwarderService.delegateTransaction({
      encodedFunction: method.encodeABI(),
      contractAddress: tokenAddress,
      signerAddress: owner,
    });
  }

  async refundPayment(
    tokenAddress: string,
    owner: string,
    depositToken: string,
    extra: { delegate: boolean } = { delegate: false }
  ) {
    const contractInstance = new this.web3.eth.Contract(
      this.contract.ABI,
      tokenAddress
    );
    const method =
      contractInstance.methods.refundPayment(depositToken);

    if (!extra.delegate) return method.send({ from: owner });

    return trustedForwarderService.delegateTransaction({
      encodedFunction: method.encodeABI(),
      contractAddress: tokenAddress,
      signerAddress: owner,
    });
  }

  async investInSecurities(
    tokenAddress: string,
    owner: string,
    depositToken: string,
    usdAmount: string,
    depositTokenAmount: string,
    extra: { delegate: boolean } = { delegate: false }
  ) {
    console.log({
      tokenAddress,
      owner,
      depositToken,
      usdAmount,
      depositTokenAmount,
      extra,
    });
    const contractInstance = new this.web3.eth.Contract(
      this.contract.ABI,
      tokenAddress
    );
    const method = contractInstance.methods.investInSecurities(
      depositToken,
      usdAmount
    );

    if (!extra.delegate)
      return method.send({
        from: owner,
        value:
          depositToken === "0x0000000000000000000000000000000000000000"
            ? depositTokenAmount
            : "0",
      });

    return trustedForwarderService.delegateTransaction({
      encodedFunction: method.encodeABI(),
      contractAddress: tokenAddress,
      signerAddress: owner,
    });
  }

  async withdrawApproval(tokenAddress: string, owner: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return contractInstance.methods.approveWithdrawal().send({ from: owner });
}
async withdrawCollectedAmountFromContract(tokenAddress: string, owner: string, depositTokenAddress: string) {
  const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
  return contractInstance.methods.withdrawCollectedAmount(depositTokenAddress).send({ from: owner });
}

  async addPriceFeed(
    tokenAddress: string,
    owner: string,
    depositTokens: string[],
    aggregators: string[],
    extra: { delegate: boolean } = { delegate: false }
) {
    const contractInstance = new this.web3.eth.Contract(
        this.contract.ABI,
        tokenAddress
    );
    const method = contractInstance.methods.addPriceFeeds(
        depositTokens,
        aggregators);

    if (!extra.delegate) return method.send({ from: owner });

    return trustedForwarderService.delegateTransaction({
        encodedFunction: method.encodeABI(),
        contractAddress: tokenAddress,
        signerAddress: owner,
    });
}
}
