
export interface Aggregator {
  name: string;
  address: string;
  token: {
    name: string;
    symbol: string;
    address: string;
  }
  // tokenAddress: string | null;
}
const aggregators: {
  [chainId: string]: Aggregator[];
} = {
"80002": [
    {
      name: "USDC",
      address: "0x8C686294bF973E8D3AA174d00d1f4646AC469540",
      token: {
        name: "USD Coin",
        symbol: "USDC",
        address: "0x9b3dba97260c9851788968AfF3509Acc6F57e264"
      }
    },

    {
      name: "USDT",
      address: "0x11F070edA6bA91A591c021685237b713C60f0Ec0",
      token: {
        name: "Tether USD",
        symbol: "USDT",
        address: "0xd723DBc20c3Df2f06F4Ca5F217f91919C912d7f8"
      }
    }

  ],
  "137": [
    {
      name: "USDC",
      address: "0xfE4A8cc5b5B2366C1B58Bea3858e81843581b2F7",
      token: {
        name: "USD Coin",
        symbol: "USDC",
        address: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174"
      }
    },

    {
      name: "USDT",
      address: "0x0A6513e40db6EB1b165753AD52E80663aeA50545",
      token: {
        name: "Tether USD",
        symbol: "USDT",
        address: "0xc2132d05d31c914a87c6611c10748aeb04b58e8f"
      }
    }

  ]
};


export {aggregators}
