import { MetamaskService } from '../../../Metamask.service';
import { RegLaunched } from '../../../interfaces';
import {TrustedForwarderService} from "../../../TrustedForwarder.service";

const trustedForwarderService = new TrustedForwarderService();

export default class MainFacet {
  contract = {
    ABI: require('./ABI.json'),
  };

  private get web3() {
    return MetamaskService.web3;
  }

  public precision = 10;

  async relaunchToken(
    tokenAddress: string,
    owner: string,
    relaunchParams: {
      regulation: string;
      regDTransferableOutsideUSA: boolean;
      creationTS: number;
      typeOfSecurity: string;
      commonStock: string;
    },
    extra: {delegate: boolean} = {delegate: false}
  ) {
    console.log([
      relaunchParams.regulation,
      relaunchParams.regDTransferableOutsideUSA,
      relaunchParams.creationTS,
      relaunchParams.typeOfSecurity,
      relaunchParams.commonStock
    ])
    const contractInstance = new this.web3.eth.Contract(
      this.contract.ABI,
      tokenAddress
    );
    const method = contractInstance.methods.relaunchToken([
      relaunchParams.regulation,
      relaunchParams.regDTransferableOutsideUSA,
      relaunchParams.creationTS,
      relaunchParams.typeOfSecurity,
      relaunchParams.commonStock
    ]);

    if (!extra.delegate) return method.send({ from: owner });

    return trustedForwarderService.delegateTransaction({
      encodedFunction: method.encodeABI(),
      contractAddress: tokenAddress,
      signerAddress: owner
    });
  }

  async removeLegend(
    tokenAddress: string,
    owner: string,
    _partition: string,
    extra: {delegate: boolean} = {delegate: false}
  ) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    const method = contractInstance.methods.removeLegend(_partition);

    if (!extra.delegate) return method.send({ from: owner });

    return trustedForwarderService.delegateTransaction({
      encodedFunction: method.encodeABI(),
      contractAddress: tokenAddress,
      signerAddress: owner
    });
  }


  async addInvestorLockedAmountMulti(
    tokenAddress: string,
    owner: string,
    lockPeriod: number,
    _from: string[],
    _amount: string[],
    extra: {delegate: boolean} = {delegate: false}
  ) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    const method = contractInstance.methods.addInvestorLockedAmountMulti(lockPeriod, _from, _amount);

    if (!extra.delegate) return method.send({ from: owner });

    return trustedForwarderService.delegateTransaction({
      encodedFunction: method.encodeABI(),
      contractAddress: tokenAddress,
      signerAddress: owner
    });
  }

  async legendRemoved(tokenAddress: string, _partition: string) {
    const contractInstance = new this.web3.eth.Contract(
      this.contract.ABI,
      tokenAddress
    );
    return (await contractInstance.methods
      .legendRemoved(_partition)
      .call()) as boolean;
  }


  async getRegulationsLaunched(tokenAddress: string) {
    const contractInstance = new this.web3.eth.Contract(
      this.contract.ABI,
      tokenAddress
    );
    return (await contractInstance.methods
      .getRegulationsLaunched()
      .call()) as RegLaunched[];
  }

  async getRegLaunchedByIndex(tokenAddress: string, _index: number) {
    const contractInstance = new this.web3.eth.Contract(
      this.contract.ABI,
      tokenAddress
    );
    return (await contractInstance.methods
      .getRegLaunchedByIndex(_index)
      .call()) as RegLaunched;
  }

  async getPartitionInfo(tokenAddress: string, _partition: string) {
    const contractInstance = new this.web3.eth.Contract(
      this.contract.ABI,
      tokenAddress
    );
    return (await contractInstance.methods.getPartitionInfo(_partition).call()) as RegLaunched;
  }

  async regulation(tokenAddress: string) {
    const contractInstance = new this.web3.eth.Contract(
      this.contract.ABI,
      tokenAddress
    );
    return (await contractInstance.methods.regulation().call()) as string;
  }

  async monthUnit(tokenAddress: string) {
    const contractInstance = new this.web3.eth.Contract(
      this.contract.ABI,
      tokenAddress
    );
    return (await contractInstance.methods.monthUnit().call()) as string;
  }


  async issuerComplianceAdmin(tokenAddress: string) {
    const contractInstance = new this.web3.eth.Contract(
      this.contract.ABI,
      tokenAddress
    );
    return (await contractInstance.methods
      .issuerComplianceAdmin()
      .call()) as string;
  }

  async issuerCounsel(tokenAddress: string) {
    const contractInstance = new this.web3.eth.Contract(
      this.contract.ABI,
      tokenAddress
    );
    return (await contractInstance.methods.issuerCounsel().call()) as string;
  }

  async issuerSuperAdmin(tokenAddress: string) {
    const contractInstance = new this.web3.eth.Contract(
      this.contract.ABI,
      tokenAddress
    );
    return (await contractInstance.methods.issuerSuperAdmin().call()) as string;
  }

  async trustedForwarder(tokenAddress: string) {
    const contractInstance = new this.web3.eth.Contract(
      this.contract.ABI,
      tokenAddress
    );
    return (await contractInstance.methods.trustedForwarder().call()) as string;
  }

  async closeTokenSale(
      tokenAddress: string,
      owner: string,
      _isGoalReached: boolean,
      extra: {delegate: boolean} = {delegate: false}
  ) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    const method = contractInstance.methods.closeTokenSale(_isGoalReached);

    if (!extra.delegate) return method.send({ from: owner });

    return trustedForwarderService.delegateTransaction({
      encodedFunction: method.encodeABI(),
      contractAddress: tokenAddress,
      signerAddress: owner
    });
  }
}

