import React, { useEffect, useRef, useState } from "react";
import { Button, Checkbox, message, Typography, Tabs, Col, Row, Form, Input, Radio, Card, notification, Spin, Modal } from "antd";
import BankInfo from "../BankInfo";
import CardHOC from "../../CardHOC";
import { useForm } from "antd/lib/form/Form";
import { AuthService } from "../../Shared/Auth.service";
import { IssuerSuperAdminService } from "../IssuerSuperAdmin.service";
import { MetamaskService } from "../../Shared/Metamask.service";
import ERC20Facet from "../../Shared/SecurityToken/Facets/ERC20Facet";
import { SharedService } from "../../Shared/Shared.service";
import EscrowFacet from "../../Shared/SecurityToken/Facets/EscrowFacet";
import { TokenConfigurationService } from "../../TokenConfigurations/TokenConfiguration.service";
import { SecurityTokenRegistryService } from "../../Shared/SecurityTokenRegistery/SecurityTokenRegistry.service";
import { aggregators } from "../../Investor/Home/chainlinkAggregators";
import TxFeeDelegationModal from "../../Shared/TxFeeDelegationModal";

const { Title, Text } = Typography;
const { TabPane } = Tabs;

const issuerSuperAdminService = new IssuerSuperAdminService();
const authService = new AuthService();
const useSelectedWalletContext = () => new MetamaskService().useSelectedWalletContext();
const escrowFacet = new EscrowFacet();
const erc20Facet = new ERC20Facet();
const sharedService = new SharedService;
const tokenConfigurationService = new TokenConfigurationService();
const securityTokenRegistryService = new SecurityTokenRegistryService();


export const escrowFields = [
  { name: 'cryptoWalletProvider', label: 'Crypto Wallet Provider', type: 'default', required: true },
  { name: 'walletAddress', label: 'Token Address', type: 'default', required: true },
]

const useUserContext = () => authService.useUserContext();

const ManagePaymentMethod = () => {
  const [form] = useForm();
  const { userInfo } = useUserContext();
  const { selectedWallet, networkId } = useSelectedWalletContext();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEditionMode, setIsEditionMode] = useState(false);
  const [selectedTokens, setSelectedTokens] = useState([]);
  const [paymentInfo, setPaymentInfo] = useState();
  const [isFullScreenLoading, setIsFullScreenLoading] = useState(false);
  const [symbolDetailsAndSTData, setSymbolDetailsAndSTData] = useState(null);
  const [isDelegationModalVisible, setIsDelegationModalVisible] = useState(false);
  const [selectedData, setSelectedData] = useState(null);

  const [paymentMethods, setPaymentMethods] = useState({
    escrowCrypto: false,
    othersSelected: false,
  });

  useEffect(() => {
    fetchPaymentMethods();
    fetchTokenConfigurationData();
  }, []);

  const fetchPaymentMethods = async () => {
    try {
      const response = await issuerSuperAdminService.getSavedPaymentMethod({
        companyId: userInfo?.company?.id,
      });
      if (response.success && response.data) {
        setPaymentMethods({
          escrowCrypto: response.data.escrowCrypto,
          othersSelected: response.data.othersSelected,
        });
      }
    } catch (error) {
      console.error("Error fetching payment methods:", error);
      message.error("Failed to fetch payment methods");
    }
  };

  const fetchTokenConfigurationData = async () => {
    try {
      const tokenConfigurationProcess = await tokenConfigurationService.getLastTokenConfigurationProcess();
      const tokenSymbol = tokenConfigurationProcess.data?.tokenSymbol;
      if (tokenSymbol) {
        const symbolDetailsAndSTData = await securityTokenRegistryService.getSymbolDetailsAndSTData(tokenSymbol);
        setSymbolDetailsAndSTData(symbolDetailsAndSTData);

        form.setFieldsValue({
          cryptoWalletProvider: 'MetaMask',
          walletAddress: symbolDetailsAndSTData?.securityTokenData?.contractAddress || ''
        });
      }
    } catch (error) {
      console.error("Error fetching token configuration data:", error);
      message.error("Failed to fetch token configuration data");
    }
  };

  useEffect(() => {
    (async () => {
      setIsFullScreenLoading(true);

      const _escroData = await issuerSuperAdminService.getPaymentDetailsByPaymentTypeAndCompanyId(userInfo?.company?.id,"escrowCrypto",);
      if (_escroData && _escroData.data) {
        console.log(_escroData,"_escroData")
        setPaymentInfo(_escroData.data);
        setIsEditionMode(true);
        const selectedTokens = _escroData?.data?.cryptoType?.map(token => token?.token.symbol) || [];
        form.setFieldsValue({
          cryptoType: selectedTokens, 
        });
        // if (_escroData.data?.status == 'approved' || _escroData.data?.status == 'pending') {
        //   setDisableEdit(true);
        // }
      } else {
        setIsEditionMode(false);
      }

      setIsFullScreenLoading(false);
    })();
  }, []);


  const onSave = async () => {
    try {
      setIsSubmitting(true);
      const formData = await form.validateFields();
      const dataToSend = {
        ...paymentMethods,
        companyName: userInfo?.company?.name,
        companyId: userInfo?.company?.id,
        ...formData,
      };
      const response = await issuerSuperAdminService.savePaymentMethod(dataToSend);
      if (response.success) {
        message.success("Successfully saved payment methods");
        setPaymentMethods(prevState => ({
          ...prevState,
          escrowCrypto: response.data.escrowCrypto,
          othersSelected: response.data.othersSelected,
        }));
      }
    } catch (error) {
      console.error("Error saving payment methods:", error);
      message.error("Failed to save payment methods");
    } finally {
      setIsSubmitting(false);
    }
  };

  const onTokenChange = (checkedValues) => {
    setSelectedTokens(checkedValues);
    form.setFieldsValue({ cryptoType: checkedValues });
  };

  const handleEdit = () => {
    setIsEditionMode(false);
    form.setFieldsValue({
      ...paymentInfo,
      cryptoType:paymentInfo?.cryptoType?.map(token => token?.token?.symbol)
    });
    setSelectedTokens(paymentInfo?.cryptoType?.map(token => token?.token?.symbol));
  };

  const addPriceFeed = async (formInstance, delegate) => {
    try {
      setIsFullScreenLoading(true);
      const formValues = formInstance.getFieldsValue();
      const tokenAddress = formValues.walletAddress;
      const owner = selectedWallet || '';
      const selectedTokenSymbols = formValues.cryptoType;
      if (!networkId) {
        throw new Error('Network ID is not defined');
      }
  
      if (!aggregators[networkId]) {
        throw new Error(`No aggregators found for network ID: ${networkId}`);
      }
  
      if (!Array.isArray(selectedTokenSymbols) || selectedTokenSymbols.length === 0) {
        throw new Error('No tokens selected');
      }
  
      // Filter aggregators based on selected tokens
      const selectedAggregators = aggregators[networkId].filter(agg => 
        selectedTokenSymbols.includes(agg.token.symbol)
      );
  
      if (selectedAggregators.length === 0) {
        throw new Error('No matching aggregators found for selected tokens');
      }
  
      // Extract deposit token addresses and aggregator addresses
      const depositTokens = selectedAggregators.map(agg => agg.token.address);
      const aggregatorAddresses = selectedAggregators.map(agg => agg.address);
      console.log(tokenAddress,"tokenAddress")
      console.log(owner,"owner")
      console.log(depositTokens,"depositTokens")
      console.log(aggregatorAddresses,"aggregatorAddresses")
      console.log(delegate,"delegate")
      const response = await escrowFacet.addPriceFeed(tokenAddress, owner, depositTokens, aggregatorAddresses,{delegate: delegate});
      console.log('Transaction Response:', response);
      notification.success({
        message: 'Success',
        description: 'Price feeds added successfully.',
      });
      setIsFullScreenLoading(false);
    } catch (error) {
      setIsFullScreenLoading(false);
      console.error('Error in addPriceFeed:', error);
      notification.error({
        message: 'Error',
        description: error.message || 'Failed to add price feeds. Please try again.',
      });
    }
  };

  const savePaymentInfo = async (formValue) => {
    setIsFullScreenLoading(true);
    const tokenDetails = aggregators[networkId].filter(agg => 
      selectedTokens.includes(agg.token.symbol)
    );
    // const tokenDetails = environment.tokens.filter(token => selectedTokens.includes(token?.tokenName));
    const formValueWithTokens = {
      ...formValue,
      cryptoType: tokenDetails
    };
    const response = await issuerSuperAdminService.savePaymentDetailsByPaymentTypeAndCompanyId(userInfo?.company?.id,"escrowCrypto",formValueWithTokens )
    if (response) {
      setPaymentInfo(formValueWithTokens);
      setSelectedTokens(formValue.cryptoType);
      setIsEditionMode(true);
    }
    setIsFullScreenLoading(false);
  }

  const showAddPriceFeedsModal = (form) => {
    setSelectedData(form);
    setIsDelegationModalVisible(true);
};

const handleDelegationModalSubmit = async ({ delegate }) => {
  console.log(delegate,"delegate");
  console.log(selectedData,"selectedData");
  setIsDelegationModalVisible(false);
  await addPriceFeed(selectedData,delegate);
};

  return (
    <>
      <div style={{ textAlign: "left" }}>
        <Text>Please select the payment methods you want to use:</Text>
        <br />
        <br />
        <Checkbox
          checked={paymentMethods.escrowCrypto}
          onChange={(e) => setPaymentMethods(prev => ({ ...prev, escrowCrypto: e.target.checked }))}
          disabled={isSubmitting}
        >
          USDC/USDT Stable Coin
        </Checkbox>
        <br />
        <Checkbox
          checked={paymentMethods.othersSelected}
          onChange={(e) => setPaymentMethods(prev => ({ ...prev, othersSelected: e.target.checked }))}
          disabled={isSubmitting}
        >
          Others (Direct wire transfer)
        </Checkbox>
        <br />
      </div>
      <br />
      {/* {othersSelected && <BankInfo />} */}
      <Tabs defaultActiveKey="escrow" >
        {paymentMethods.escrowCrypto && <TabPane tab={<span>Escrow</span>} key="escrow" >
          <Card>
            <Title level={2} style={{ textAlign: 'center' }}>USDC/USDT Stable Coin</Title>
            <br />

            <Form labelAlign="left" form={form}
              onFinish={savePaymentInfo}>
              {escrowFields.map((input, index) => (
                <Form.Item
                  key={index}
                  label={input.label}
                  rules={[{ required: input.required, message: 'This field is required' }]}
                  labelCol={{ xs: { span: 24 }, sm: { span: 10, push: 2 } }}
                  wrapperCol={{ xs: { span: 24 }, sm: { span: 8 } }}
                  name={input.name}>

                  <Input disabled={true} />

                </Form.Item>
              ))
              }

              <Form.Item
                name="cryptoType"
                label="Crypto Type"
                rules={[{ required: true, message: 'Please select at least one crypto type' }]}
                labelCol={{ xs: { span: 24 }, sm: { span: 9, push: 2 } }}
                wrapperCol={{ xs: { span: 24 }, sm: { span: 8 } }}
              >
                <Checkbox.Group
                  options={aggregators[networkId]?.map(agg => ({
                    label: agg.token.name,
                    value: agg.token.symbol
                  })) || []}
                  onChange={onTokenChange}
                  disabled={isEditionMode}
                />
              </Form.Item>

              {userInfo?.role !== "investor" &&
                <Row justify='end'>
                  {isEditionMode && (
                    <Button
                      size='large'
                      type='primary'
                      onClick={() => showAddPriceFeedsModal(form)}
                    >
                      ADD PRICE FEEDS
                    </Button>
                  )}
                  &nbsp;&nbsp;
                  {isEditionMode && <Button size='large' type='primary' onClick={handleEdit}>
                    EDIT
                  </Button>}
                  &nbsp;&nbsp;
                  {!isEditionMode && <Button htmlType='submit' size='large' type='primary' loading={false}>
                    SAVE
                  </Button>}
                </Row>}
            </Form>



          </Card>
        </TabPane>}
        {paymentMethods.othersSelected && (
          <TabPane tab={<span>Bank</span>} key="bank">
            <BankInfo />
          </TabPane>
        )}
      </Tabs>
      <Row justify="end" className="mt-3">
        <Col span={24}>
          {(paymentMethods.othersSelected || paymentMethods.escrowCrypto) && (
            <Button
              type="primary"
              htmlType="button"
              onClick={onSave}
              loading={isSubmitting}
            >
              Save
            </Button>
          )}
        </Col>
      </Row>
      {isFullScreenLoading && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 9999
        }}>
          <Spin size="large" />
        </div>
      )}

      <TxFeeDelegationModal
            isVisible={isDelegationModalVisible}
            onSubmit={({delegate}) => {
                setIsDelegationModalVisible(false);
                handleDelegationModalSubmit({delegate}).then()
              }}
            onCancel={() => setIsDelegationModalVisible(false)}
      />
    </>
  );
};
export default () => {
  return (
    <CardHOC
      heading="Select Payment Method"
      component={<ManagePaymentMethod></ManagePaymentMethod>}
    ></CardHOC>
  );
};
