import React from 'react';
import { Collapse, Typography, Divider } from 'antd';
import './faq.css'; // Import custom CSS file

const { Panel } = Collapse;
const { Title, Paragraph } = Typography;

const FAQ = () => {
  const faqs = [
    {
      question: "How do I invest in Apex Token?",
      answer: (
        <div>
          To invest in Apex Token, follow these steps:
          <ol>
            <li><strong>Register:</strong> Visit <a href="https://invest.apexdefilabs.com" target="_blank" rel="noopener noreferrer">invest.apexdefilabs.com</a> and complete the registration process.</li>
            <li><strong>Email Verification:</strong> After registration, verify your email address to activate your account.</li>
            <li><strong>Investment Process:</strong> Click on the "Invest" button and accept the terms and conditions.</li>
            <li><strong>Complete KYC:</strong> Provide the required Know Your Customer (KYC) information. The Apex Defi Labs Compliance Admin will review and approve your KYC submission.</li>
            <li><strong>Document Upload:</strong> After KYC approval, upload any necessary documentation for Accredited Investor verification.</li>
            <li><strong>Payment:</strong> Once your Accredited Investor documents are approved, proceed with the payment for your investment.</li>
            <li><strong>Whitelist Wallet Request:</strong> After completing the payment, request to whitelist your wallet for the token allocation.</li>
          </ol>
        </div>
      ),
    },
    {
      question: "Who is eligible to invest in the Apex Token?",
      answer: "This offering is currently available exclusively to U.S. accredited investors under Regulation D 506(C).",
    },
    {
      question: "What is the price per Apex token?",
      answer: "The price per Apex token is $2.00.",
    },
    {
      question: "What payment methods are accepted?",
      answer: "Payments can be made via ACH, Wire Transfer, Check, or Stablecoins (USDC, USDT).",
    },
    {
      question: "What is the minimum investment amount?",
      answer: "The minimum investment for the current Regulation D 506(C) offering is $5,000.",
    },
    {
      question: "What is the ERC1400 Standard?",
      answer: "The ERC1400 is a blockchain-based token standard for security tokens that ensures compliance with technical requirements, including whitelisting, transfer restrictions, and enhanced security for Apex Tokens.",
    },
    {
      question: "Can non-U.S. investors participate in the offering?",
      answer: "No, this offering is currently available only to U.S. accredited investors in compliance with Regulation D 506(C).",
    },
    {
      question: "When will the tokens be issued?",
      answer: "Apex Tokens will be issued after the token sale concludes.",
    },
    {
      question: "What currencies are accepted for investment?",
      answer: "We accept USD, USDC, and USDT for investments.",
    },
    {
      question: "What is the role of the Apex Token?",
      answer: "Apex Tokens are tokenized securities backed by common stock, representing ownership in Apex Defi Labs Inc. Investors receive digital assets that correspond to equity in the company.",
    },
    {
      question: "What is Common Stock?",
      answer: "Common stock is a type of security that signifies ownership in a company. Holders of common stock are entitled to a share of the company’s profits and can benefit from capital appreciation.",
    },
    {
      question: "What is an Accredited Investor?",
      answer: (
        <div>
            In the United States, a person can be considered an accredited investor if they meet one of the following criteria:<ol>
            <li><b>Net Worth</b>: Have a net worth of at least $1,000,000, excluding the value of their primary residence.</li>
            <li><b>Income</b>: Have earned at least $200,000 per year for the last two years (or $300,000 combined with a spouse) and expect to maintain the same income level this year.</li>
            <li><b>Professional Licenses</b>: Hold a financial securities license in good standing, such as Series 7, Series 65, or Series 82.</li>
          </ol>
        </div>
      ),
    },
    {
      question: "What is the lock-in period for Apex Token investments?",
      answer: "Under Regulation D 506(C), Apex Tokens have a lock-in period of 12 months from the date of issuance, as governed by Rule 144.",
    },
    {
      question: "What are digital securities?",
      answer: "Digital securities represent traditional financial assets like stocks and bonds, but they are issued and traded using blockchain technology. While they leverage decentralized technology, they remain compliant with securities laws, offering investors security and transparency.",
    },
    {
      question: "What are securities?",
      answer: "Securities are financial instruments, such as stocks, bonds, or mutual funds, that represent ownership in a company or debt that the company must repay. In the U.S., securities are regulated by the Securities and Exchange Commission (SEC).",
    },
    {
      question: "What is MetaMask?",
      answer: "MetaMask is a popular crypto wallet that allows users to manage digital assets and interact with blockchain applications. You can use MetaMask to invest in Apex Tokens by linking your wallet during the investment process.",
    },
    {
      question: "What is a Wallet Address?",
      answer: (
        <div>
          A wallet address is a unique identifier for a cryptocurrency wallet. It is used to send and receive digital assets like Apex Tokens. You can generate a wallet address using MetaMask by following these steps:
          <ol>
            <li>Download the MetaMask browser extension or mobile app.</li>
            <li>Set up your wallet following the instructions.</li>
            <li>Copy your wallet address from the main interface to use for transactions.</li>
          </ol>
        </div>
      ),
    },
    {
      question: "What are Restricted Securities?",
      answer: "Restricted securities are those acquired in an unregistered, private sale from an issuer. They come with certain limitations, such as being unable to sell or transfer them until certain regulatory requirements, such as Rule 144 under the SEC, are met.",
    },
    {
      question: "How does the investment work after the lock-in period?",
      answer: "After the lock-in period expires, investors can transfer or sell their tokens, subject to compliance with the company’s guidelines and applicable securities regulations.",
    },
    {
      question: "What is the expected return on investment (ROI) for Apex Tokens?",
      answer: "The ROI will depend on the company’s financial performance and market conditions. While Apex Tokens represent equity in Apex Defi Labs, investors should conduct thorough research and understand the risks before investing.",
    },
    {
      question: "What happens if the token sale does not meet its minimum target?",
      answer: "In the event that the token sale does not meet the minimum fundraising target, Apex Defi Labs will notify investors of the next steps, which may include refunds or adjustments to the offering terms.",
    },
    {
      question: "Is there a secondary market for Apex Tokens?",
      answer: "Currently, there is no secondary market for Apex Tokens. After the lock-in period and subject to compliance with applicable laws, Apex Defi Labs may explore options for listing tokens on security token exchanges, if available.",
    },
    {
      question: "Is there a referral program for investors?",
      answer: "At present, Apex Defi Labs does not offer a referral program for investors. However, this may change in future offerings.",
    },
    {
      question: "Can I transfer my Apex Tokens to another wallet?",
      answer: "Yes, once the lock-in period has ended and subject to the company’s transfer guidelines, you will be able to transfer your Apex Tokens to another compatible wallet.",
    },
  ];

  return (
        <div className="faq-container" style={{marginTop:'130px'}}>
        <div style={{ padding: "20px" }}></div>
            <div style={{ textAlign: "center" }}>
            <p style={{color:'#141838',fontSize:'45px',fontWeight:'bold',paddingBottom:'60px',lineHeight:'55px'}}><span className="underline">Frequently Asked Questions</span></p>
        </div>      
        <Divider />
      <Collapse accordion className="faq-collapse">
        {faqs.map((faq, index) => (
          <Panel header={faq.question} key={index} className="faq-panel">
            <Paragraph>{faq.answer}</Paragraph>
          </Panel>
        ))}
      </Collapse>
    </div>
  );
};

export default FAQ;
