import React, { useEffect, useState } from "react";
import axios from "axios";
import { APIResponse } from "../Shared/interfaces";
import { environment } from "../../environments/environment";
import { SharedService } from "../Shared/Shared.service";
// @ts-ignore
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {MetamaskService} from "../Shared/Metamask.service";
import {Button, Card, Col, Row} from "antd";
import logo from "../../assets/metamask1.png";
import Title from "antd/lib/skeleton/Title";
import guidelineDoc from '../../assets/Documents/Metamaskguidelines.docx';

declare global {
  interface Window {
    ethereum: any;
  }
}

const sharedService = new SharedService();
const metamaskService = new MetamaskService();

function ConnectYourWallet() {
  const APIURL = environment.APIURL;

  useEffect(() => {
      if(metamaskService.isMetamaskInstalled){
          getUsers();
          getWalletAdress();
      }

  }, []);

  const [previousWalletAdress, setPreviousWalletAdress] = useState("");
  const [walletAdress, setWalletAdress] = useState("");
  const [userId, setUserId] = useState("");

  const getWalletAdress = () => {
    window.ethereum
      .request({ method: "eth_requestAccounts" })
      .then((res: any) => {
        // Return the address of the wallet
        console.log("Wallet address: ", res);
        setWalletAdress(res[0]!.toString());
      })
      .catch((error) => {
        console.error("Error fetching wallet address: ", error);
        setWalletAdress("");
      });
    window.ethereum.on("accountsChanged", function (accounts) {
      console.log("accounts: ", accounts[0]);
      setWalletAdress(accounts[0]);
    });
  };

  const getUsers = async () => {
    axios
      .post<any, APIResponse>(
        `${APIURL}/users/getUser`,// Time to reload your interface with accounts[0]!
        {},
        { headers: await sharedService.getAuthHeader() }
      )
      .then((response: any) => {
        console.log("getUsers Response: ", response);
        setUserId(response?.data?._id);
        setPreviousWalletAdress(response?.data?.oldWalletAddress);
        console.log("Previous Wallet Address:", response?.data?.walletAddress);
      })
      .catch((error: any) => {
        console.error("Error fetching user: ", error);
      });
  };

  const saveWalletAddress = async () => {
      if(metamaskService.isMetamaskInstalled){
          axios
              .post<any, APIResponse>(
                  `${APIURL}/account/addOrUpdateUserWalletAddress`,
                  {
                      userId: userId,
                      walletAddress: walletAdress,
                  },
                  { headers: await sharedService.getAuthHeader() }
              )
              .then((response: any) => {
                  console.log("saveWalletAddress Response: ", response);
                  if (response.success == true) {
                      toast("Successfully saved your wallet!");
                  }
                  if (response.success == false) {
                      toast(response.error.message);
                  }
              });
      }else{
          toast("Install Meta Mask");
      }

  };

  // @ts-ignore
    // @ts-ignore
    return (
    <>
        {metamaskService.isMetamaskInstalled ?(
      <div
        style={{
          display: "table",
          marginLeft: "auto",
          marginRight: "auto",
          paddingTop: "1rem",
          width: "100%",
          padding: "20px",
        }}
      >
        <h1 style={{color:"rgb(24, 106, 180)"}}>Connect your Wallet</h1>
        <span style={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <span style={{color:"#0e587a" , fontWeight:"bold"}}>Previous Wallet Address: </span>
          <input
            style={{ width: "65%", padding: "1px 5px" ,height: "40px",borderRadius: "5px"}}
            value={previousWalletAdress}
            disabled
          ></input>
        </span>
        <br></br>
        <span style={{ display: "flex", gap: "42px", alignItems: "center" }}>
          <span style={{color:"#0e587a" , fontWeight:"bold"}}>New Wallet Address: </span>
          <input
            style={{ width: "65%", padding: "1px 5px" ,height: "40px",borderRadius: "5px"}}
            value={walletAdress}
            disabled
          ></input>
        </span>
        <div style={{ display: "flex", justifyContent: "center", marginTop: "15px" }}>
        <span
          style={{
            background: "#28ae60",
            color: "white",
            padding: "8px",
            borderRadius: "8px",
            cursor: "pointer",
            marginTop: "15px",
            display: "table",
          }}
          onClick={saveWalletAddress}
        >
          Save your Wallet
        </span>
        </div>
      </div>):(
        <div style={{background: '#f0f2f5', minHeight:'100vh'}}>
            <br/><br/>
            <Row justify="center">
                <Col span={16}>
                    <Card>
                        <Row justify="center">
                            <Col span={16} style={{textAlign:'center'}}>
                                <h2>
                                    To Proceed, Connect TAP with Metamask
                                </h2>
                                <p>
                                    TAP uses the Metamask plugin to associate your profile and security token with your wallet. This association allows your wallet to be the key to controlling your security token. To use TAP, simply open the metamask extension in your browser and follow the instructions.
                                    Ethereum blockchains available:{' '}
                                    {sharedService.allowedNetworks.map(allowedNetwork => sharedService.ethereumNetworks[allowedNetwork]).join(', ')}
                                </p>

                                <br /><br />
                                <a href={guidelineDoc} target="_blank" download>Guidelines for Installing Metamask</a>

                            </Col>
                            <Col span={8}>
                                <img src={logo} alt="" width="100%"/>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </div>
            )}
      <ToastContainer />
    </>
  );
}

export default ConnectYourWallet;
