import { MetamaskService } from '../../../Metamask.service';


export default class ERC20Facet {
  contract = {
    ABI: require('./ABI.json'),
  };

  private get web3() {
    return MetamaskService.web3;
  }

  async name(tokenAddress: string) {
    const contractInstance = new this.web3.eth.Contract(
      this.contract.ABI,
      tokenAddress
    );
    return (await contractInstance.methods.name().call()) as string;
  }

  async symbol(tokenAddress: string) {
    const contractInstance = new this.web3.eth.Contract(
      this.contract.ABI,
      tokenAddress
    );
    return (await contractInstance.methods.symbol().call()) as string;
  }

  async decimals(tokenAddress: string) {
    const contractInstance = new this.web3.eth.Contract(
      this.contract.ABI,
      tokenAddress
    );
    return (await contractInstance.methods.decimals().call()) as string;
  }

  async totalSupply(tokenAddress: string) {
    const contractInstance = new this.web3.eth.Contract(
      this.contract.ABI,
      tokenAddress
    );
    return (await contractInstance.methods.totalSupply().call()) as string;
  }

  async balanceOf(tokenAddress: string, account: string) {
    const contractInstance = new this.web3.eth.Contract(
      this.contract.ABI,
      tokenAddress
    );
    return (await contractInstance.methods.balanceOf(account).call()) as string;
  }

  async transfer(tokenAddress: string, owner: string, to: string, value: string) {
    const contractInstance = new this.web3.eth.Contract(
      this.contract.ABI,
      tokenAddress
    );
    return contractInstance.methods
      .transfer(to, value)
      .send({ from: owner });
  }
  async getTokenDetails(tokenAddresses: string[], escrowAddress : string) {
    const tokenDetails = await Promise.all(
        tokenAddresses.map(async (address) => {
            const contractInstance = new this.web3.eth.Contract(this.contract.ABI, address);
            const [name, symbol, decimals,balance] = await Promise.all([
              this.name(address),
              this.symbol(address),
              this.decimals(address),
              this.balanceOf(address,escrowAddress),
            ]);
            return { address, name, symbol, decimals,balance };
        })
    );

    return tokenDetails;
  }


  async allowance(tokenAddress: string, owner: string, spender: string) {
    const contractInstance = new this.web3.eth.Contract(
      this.contract.ABI,
      tokenAddress
    );
    return (await contractInstance.methods.allowance(owner, spender).call()) as string;
  }

  async approve(tokenAddress: string, owner: string, spender: string, amount: string) {
    const contractInstance = new this.web3.eth.Contract(
      this.contract.ABI,
      tokenAddress
    );
    return contractInstance.methods
      .approve(spender, amount)
      .send({ from: owner });
  }

}
