import React, {
  useState,
  useEffect,
  Fragment
} from "react";
import CardHOC from "../CardHOC";
import {Button, Spin, Typography, Table, Descriptions} from "antd";
import {AuthService} from "../Shared/Auth.service";
import {MetamaskService} from "../Shared/Metamask.service";
import {TrustedForwarderService} from "../Shared/TrustedForwarder.service";
import {SymbolDetailsAndSTData, TokenConfigurationProcess, TrustedForwarderWallet} from "../Shared/interfaces";
import {SharedService} from "../Shared/Shared.service";
import {environment} from "../../environments/environment";
import moment from "moment";
import {SecurityTokenRegistryService} from "../Shared/SecurityTokenRegistery/SecurityTokenRegistry.service";
import {TokenConfigurationService} from "../TokenConfigurations/TokenConfiguration.service";
import BigNumber from "bignumber.js";

const { Text, Title } = Typography;

const trustedForwarderService = new TrustedForwarderService();
const sharedService = new SharedService();
const securityTokenRegistryService = new SecurityTokenRegistryService();
const tokenConfigurationService = new TokenConfigurationService();

const useUserContext = () => new AuthService().useUserContext();
const useSelectedWalletContext = () => new MetamaskService().useSelectedWalletContext();

export default function TrustedForwarder() {
  const {userInfo} = useUserContext();
  const [loading, setLoading] = useState<boolean>(true);
  const {selectedWallet, networkId} = useSelectedWalletContext();
  const [trustedForwarder, setTrustedForwarder] = useState<TrustedForwarderWallet>();
  const [balance, setBalance] = useState<string>();
  const [submitting, setSubmitting] = useState(false);
  const [filter, setFilter] = useState({limit: 10, searchPage: 1});
  const [trustedForwarderTxs, setTrustedForwarderTxs] = useState<any[]>();
  const [loadingMoreTxs, setLoadingMoreTxs] = useState<boolean>();
  const [canLoadMoreTxs, setCanLoadMoreTxs] = useState(true);
  const [mappedWallets, setMappedWallets] = useState<any>({});
  const [symbolDetailsAndSTData, setSymbolDetailsAndSTData] = useState<SymbolDetailsAndSTData>();

  useEffect(() => {
    (async () => {
      if(!userInfo || !selectedWallet) return;

      const _trustedForwarder = (await trustedForwarderService.getTrustedForwarder()).data as TrustedForwarderWallet;

      if(!_trustedForwarder) return setLoading(false);
      setTrustedForwarder(_trustedForwarder);

      const [_balance, mappedWalletsRes, _tokenConfigurationProcessRes] = await Promise.all([
        MetamaskService.web3.eth.getBalance(_trustedForwarder.address),
        trustedForwarderService.getWalletIssuers(),
        tokenConfigurationService.getLastTokenConfigurationProcess()
      ]);

      const _mappedWallets = mappedWalletsRes.data;
      _mappedWallets[securityTokenRegistryService.contract.address.toLowerCase()] = `Security Token Registry`;
      _mappedWallets[_trustedForwarder.address.toLowerCase()] = `Trusted Forwarder`;

      const _tokenConfigurationProcess: TokenConfigurationProcess = _tokenConfigurationProcessRes.data;

      if (_tokenConfigurationProcess?.tokenSymbol) {
        const _symbolDetailsAndSTData = await securityTokenRegistryService.getSymbolDetailsAndSTData(
          _tokenConfigurationProcess.tokenSymbol
        );
        setSymbolDetailsAndSTData(_symbolDetailsAndSTData);

        if (_symbolDetailsAndSTData?.symbolDetails.isDeployed) {
          _mappedWallets[_symbolDetailsAndSTData.securityTokenData.contractAddress.toLowerCase()] =
            `${_symbolDetailsAndSTData.securityTokenData.name} (${_symbolDetailsAndSTData.securityTokenData.symbol})`;
        }
      }

      setMappedWallets(mappedWalletsRes.data);
      setBalance(_balance);
      setLoading(false);
    })();
  },[userInfo, selectedWallet]);


  useEffect(() => {
    (async () => {

      if(!trustedForwarder) return;

      const [ _trustedForwarderTxsRes] = await Promise.all([
        trustedForwarderService.getTrustedForwarderTransactions({
          address: trustedForwarder.address,
          searchPage: filter.searchPage,
          limit: filter.limit
        })
      ]);

      const _trustedForwarderTxs = _trustedForwarderTxsRes.data as any[];

      setTrustedForwarderTxs(prev => {
        let current = sharedService.clone(prev);
        if(!current) current = [];
        current.push(..._trustedForwarderTxs);
        return current;
      });

      setCanLoadMoreTxs(_trustedForwarderTxs.length === filter.limit);
      setLoadingMoreTxs(false);
    })();

  },[trustedForwarder, filter.searchPage, filter.limit]);


  const createTrustedForwarder = async() => {
    setSubmitting(true);
    const response = await trustedForwarderService.createTrustedForwarder();

    if(response.success) {
      setTrustedForwarder({
        _id: response.data._id,
        address: response.data.address,
        companyId: userInfo?.company?.id as string,
        apiKey: environment.apiKey,
        creationTS: Date.now()
      });

      setBalance('0');
    }

    setSubmitting(false);
  }

  const loadMoreTxs = () => {
    setLoadingMoreTxs(true);

    setFilter(prev => {
      const current = sharedService.clone(prev);
      current.searchPage++;
      return current;
    });
  }


  const txColumns = [
    {
      title: 'Txn Hash',
      dataIndex: 'hash',
      render: (value) =>
        <a
          style={{ textDecoration: 'underline' }}
          href={`${
            sharedService.etherscanURL[networkId as string]
          }/tx/${value}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {sharedService.minifyTxHash(value)}
        </a>
    },
    {
      title: 'Date',
      dataIndex: 'timeStamp',
      render: (value) =>  moment(+value*1000).format('LLL')
    },
    {
      title: 'From',
      dataIndex: 'from',
      render: (value: string) =>
        <a
          style={{ textDecoration: 'underline' }}
          href={`${
            sharedService.etherscanURL[networkId as string]
          }/address/${value}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {mappedWallets[value.toLowerCase()] || sharedService.minifyAddress(value)}
        </a>
    },
    {
      title: 'To',
      dataIndex: 'to',
      render: (value: string) =>
        <a
          style={{ textDecoration: 'underline' }}
          href={`${
            sharedService.etherscanURL[networkId as string]
          }/address/${value}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {mappedWallets[value.toLowerCase()] || sharedService.minifyAddress(value)}
        </a>
    },
    {
      title: 'Amount (ETH)',
      dataIndex: 'value',
      render: (value: string) =>
        new BigNumber(MetamaskService.web3.utils.fromWei(value))
          .decimalPlaces(12)
          .toFixed()
    },
    {
      title: 'Fee (ETH)',
      dataIndex: 'fee',
      render: (value, record) =>
        new BigNumber(MetamaskService.web3.utils.fromWei(new BigNumber(record.gasUsed).times(record.gasPrice).toFixed()))
          .decimalPlaces(12)
          .toFixed()
    },
  ];

  const getActionLabel = (prop: {record}): string => {
    if(prop.record.to.toLowerCase() === symbolDetailsAndSTData?.securityTokenData.contractAddress.toLowerCase())
      return sharedService.findSecurityTokenSignatures({data: prop.record.input})?.label || 'UNKNOWN';

    if(prop.record.to.toLowerCase() === securityTokenRegistryService.contract.address.toLowerCase())
      return securityTokenRegistryService.findSecurityTokenRegistrySignatures({data: prop.record.input})?.label || 'UNKNOWN';

    if(prop.record.to.toLowerCase() === trustedForwarder?.address.toLowerCase())
      return 'DEPOSIT';

    if(
      prop.record.from.toLowerCase() === trustedForwarder?.address.toLowerCase() &&
      prop.record.to.toLowerCase() !== symbolDetailsAndSTData?.securityTokenData.contractAddress.toLowerCase() &&
      prop.record.to.toLowerCase() === securityTokenRegistryService.contract.address.toLowerCase() &&
      prop.record.input.length <= 2
    ) return 'WITHDRAWAL';

    return 'UNKNOWN';
  }



  return (
    <CardHOC
      heading={'Trusted Transaction Forwarder'}
      component={<>
        {loading &&
          <div style={{textAlign:'center'}}>
            <br/><br/>
            <Spin size='large'/>
          </div>
        }

        {!loading &&
          <>
            {trustedForwarder &&
              <>
                <Title
                  level={2}
                  style={{ fontWeight: "bold", marginBottom: "0" }}
                >
                  Address
                </Title>
                <a
                  href={`${sharedService.etherscanURL[networkId as string]}/address/${trustedForwarder.address}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Title
                    style={{ color: "royalblue", textDecoration: "underline" }}
                    level={3}
                  >
                    {trustedForwarder.address}
                  </Title>
                </a>
                <Text>
                  Send funds to this address so Issuers can send transactions free of blockchain fee
                </Text>

                <br/><br/>

                <Title
                  level={3}
                  style={{ fontWeight: "bold", marginBottom: "0" }}
                >
                  Balance: {MetamaskService.web3.utils.fromWei(balance || '0')} ETH
                </Title>


                <br/>
                <hr/>
                <Title
                  level={3}
                  style={{ fontWeight: "bold", marginBottom: "0" }}
                >
                  Transactions
                </Title>
                <br/>
                <Table
                  columns={txColumns}
                  rowKey={"hash"}
                  dataSource={trustedForwarderTxs}
                  scroll={{y: 500}}
                  loading={!trustedForwarderTxs}
                  pagination={false}
                  expandable={{expandedRowRender: (record =>
                    <>
                      <Descriptions column={2} style={{}}>
                        <Descriptions.Item style={{textAlign:'center', fontWeight: 'bold'}} label="CALLED BY">
                          {mappedWallets[`0x${(record.input as string).substr(-40).toLowerCase()}`] || 'N/A'}
                        </Descriptions.Item>

                        <Descriptions.Item style={{textAlign:'center', fontWeight: 'bold'}} label="ACTION">
                          {getActionLabel({record})}
                        </Descriptions.Item>

                        <Descriptions.Item style={{textAlign:'center', fontWeight: 'bold'}} label="GAS COST">
                          {record.gasUsed} UNITS
                        </Descriptions.Item>

                        <Descriptions.Item style={{textAlign:'center', fontWeight: 'bold'}} label="GAS PRICE">
                          {
                            new BigNumber(record.gasPrice)
                              .times(new BigNumber(10).pow(-9))
                              .toFixed()
                          }
                          {' '}
                          GWEI
                        </Descriptions.Item>

                        <Descriptions.Item style={{textAlign:'center', fontWeight: 'bold'}} label="FEE">
                          {
                            new BigNumber(MetamaskService.web3.utils.fromWei(
                              new BigNumber(record.gasUsed)
                                .times(record.gasPrice)
                                .toFixed())
                            )
                            .decimalPlaces(12)
                            .toFixed()
                          }
                          {' '}
                          ETH
                        </Descriptions.Item>
                      </Descriptions>
                    </>
                  )}}

                />

                {trustedForwarderTxs &&
                  <div style={{textAlign: 'right'}}>
                    <br/>
                    <Button
                      loading={loadingMoreTxs}
                      type="primary"
                      disabled={!canLoadMoreTxs}
                      onClick={loadMoreTxs}
                    >
                      Load more
                    </Button>
                  </div>
                }
              </>

            }

            {!trustedForwarder &&
              <>
                <Title
                  level={2}
                  style={{ fontWeight: "bold", marginBottom: "0" }}
                >
                  NO TRUSTED FORWARDED CREATED
                </Title>

                <br/><br/>

                <Button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  loading={submitting}
                  onClick={createTrustedForwarder}
                >
                  CREATE TRUSTED FORWARDER
                </Button>
              </>
            }

          </>
        }
      </>}
    />
  )
};