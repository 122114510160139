import React, { useState, useEffect } from "react";
import { AuthService } from "../../Shared/Auth.service";
import {
  Button,
  Col,
  Form,
  Input,
  Radio,
  Row,
  Spin,
  Table,
  notification,
} from "antd";
import { TokenConfigurationService } from "../../TokenConfigurations/TokenConfiguration.service";
import { TokenOfferingModel, TokenPhase } from "../../Shared/interfaces";
import BigNumber from "bignumber.js";
import EscrowFacet from "../../Shared/SecurityToken/Facets/EscrowFacet";
import {
  Aggregator,
  aggregators,
} from "./chainlinkAggregators";
import { MetamaskService } from "../../Shared/Metamask.service";
import { SharedService } from "../../Shared/Shared.service";
import ERC20Facet from "../../Shared/SecurityToken/Facets/ERC20Facet/ERC20Facet";
import TransactionModal from "../../Shared/TransactionModal";
import TxFeeDelegationModal from "../../Shared/TxFeeDelegationModal";
import WrongMetamaskWalletWarning from "../../Shared/WrongMetamaskWalletWarning";
import { InvestorService } from "../Investor.service";
import { SecurityTokenRegistryService } from "../../Shared/SecurityTokenRegistery/SecurityTokenRegistry.service";
import { IssuerSuperAdminService } from "../../IssuerSuperAdmin/IssuerSuperAdmin.service";

const authService = new AuthService();

const useUserContext = () => new AuthService().useUserContext();
const escrowFacet = new EscrowFacet();
const tokenConfigurationService = new TokenConfigurationService();
const securityTokenRegistryService = new SecurityTokenRegistryService();
const useSelectedWalletContext = () =>
  new MetamaskService().useSelectedWalletContext();
const sharedService = new SharedService();
const erc20facet = new ERC20Facet();
const investorService = new InvestorService();
const issuerSuperAdminService = new IssuerSuperAdminService();

export default function EscrowCrypto(props: {
  onBack: () => void;
  onSuccess: () => void;
  currentToken: any;
  offeringInformation:any
}) {
  const { userInfo } = useUserContext();
  const { selectedWallet, networkId } = useSelectedWalletContext();
  const [tokenPhases, setTokenPhases] = useState<TokenPhase[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingPrice, setLoadingPrice] = useState<boolean>(false);
  const [aggregatorList, setAggregatorList] = useState<Aggregator[]>();
  const [amountToInvest, setAmountToInvest] = useState<string>("");
  const [selectedAggregator, setSelectedAggregator] = useState<Aggregator>();
  const [depositTokenAmount, setDepositTokenAmount] = useState<string>("");
  const [depositTokenDecimals, setDepositTokenDecimals] = useState<string>("");
  const [fetchedTokenAddress, setFetchedTokenAddress] = useState<string>("");
  const [savedEscrows, setSavedEscrows] = useState<any>();
  const [transactions, setTransactions] = useState<
    { submitting?: boolean; receipt?: any; details: string }[]
  >([]);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      render: (text: string, record: Aggregator) => (
        <span>{record.token.name}</span>
      ),
    },
    {
      title: "Symbol",
      dataIndex: "symbol",
      render: (text: string, record: Aggregator) => (
        <span>{record.token.symbol}</span>
      ),
    },
    {
      title: "Token Address",
      dataIndex: "name",
      render: (text: string, record: Aggregator) => (
        <>
          {record.token.symbol !== "ETH" ? (
            <a
              // href={`${sharedService.etherscanURL[networkId]}/token/${record.token.address}`}
              target="_blank"
            >
              {record.token.address}
            </a>
          ) : (
            "N/A"
          )}
        </>
      ),
    },
    {
      title: "Your Balance",
      dataIndex: "balance",
      render: (value: string, record: Aggregator) => (
        <span>
          {new BigNumber(value)
            .times(new BigNumber(10).pow(-record["decimals"]))
            .decimalPlaces(10)
            .toFixed()}{" "}
          {record.token.symbol}
        </span>
      ),
    },
  ];

  useEffect(() => {
    (async () => {
      if (!networkId || !selectedWallet) return;

      const symbolDetailsAndSTData = await securityTokenRegistryService.getSymbolDetailsAndSTData(props?.currentToken?.tokenSymbol);
      setFetchedTokenAddress(symbolDetailsAndSTData[1][0]);
      const fetchedAddress = symbolDetailsAndSTData[1][0];

      const [tokenPhasesResponse, _offeringModelsRes, _depositTokens] =
        await Promise.all([
          tokenConfigurationService.getPhasesByConfigProcessId({
            tokenConfigurationProcessId:
              props.currentToken.tokenConfigurationProcessId,
            limit: null,
            searchPage: null,
            all: true,
          }),
          tokenConfigurationService.getOfferingModels({
            tokenConfigurationProcessId:
              props.currentToken.tokenConfigurationProcessId,
            limit: null,
            searchPage: null,
            all: true,
          }),
          escrowFacet.getDepositTokens(fetchedAddress),
        ]);

      const _phases: TokenPhase[] = tokenPhasesResponse.data;
      const _aggregatorList = aggregators[networkId].filter((aggregator) => {
        const isEnabled = _depositTokens.find(
          (depositToken) =>
            depositToken.toLowerCase() ==
            aggregator.token.address.toLowerCase()
        );

        return isEnabled;
      });
      console.log(_aggregatorList,"_aggregatorList")

      const [balances, decimalsArr] = await Promise.all([
        Promise.all(
          _aggregatorList.map(async (aggregator) =>
            erc20facet.balanceOf(aggregator.token.address, selectedWallet)
          )
        ),
        Promise.all(
          _aggregatorList.map(async (aggregator) =>
            erc20facet.decimals(aggregator.token.address)
          )
        ),
      ]);
      console.log(_aggregatorList,"_aggregatorList162")
      _aggregatorList.forEach((aggregator, index) => {
        aggregator["balance"] = balances[index];
        aggregator["decimals"] = decimalsArr[index];
      });

      setAggregatorList(_aggregatorList);
      setTokenPhases(_phases);
      setLoading(false);
   
    })();
  }, [userInfo, networkId, selectedWallet]);

    // useEffect(() => {
    //   const fetchSavedEscrows = async () => {
    //     const savedData = await issuerSuperAdminService.getPaymentDetailsByPaymentTypeAndCompanyId(props.currentToken.issuerInfo[0].companyId,"escrowCrypto",);
    //     setSavedEscrows(savedData?.data?.cryptoType);
    //   };
  
    //   fetchSavedEscrows();
    // }, []);

  useEffect(() => {
    (async () => {
      console.log(selectedAggregator,"selectedAggregator")
      if (!selectedAggregator) return;

      setLoadingPrice(true);
      const _depositTokenDecimals = selectedAggregator?.["decimals"] as string;
      const [price] = await Promise.all([
        escrowFacet.getDepositTokenPrice(
          // props.currentToken.tokenAddress,
          fetchedTokenAddress,
          selectedAggregator?.token?.address
        ),
      ]);

      const _depositTokenAmount = new BigNumber(amountToInvest)
        .times(new BigNumber(10).pow(price.decimals))
        .times(new BigNumber(10).pow(_depositTokenDecimals))
        .div(price.value)
        .decimalPlaces(0)
        // .plus(1)
        .toFixed();

      setDepositTokenDecimals(_depositTokenDecimals);
      setDepositTokenAmount(_depositTokenAmount);
      setLoadingPrice(false);
    })();
  }, [selectedAggregator, amountToInvest]);

  const updateAmountToInvest = (e: any) => {
    if (e.target.value === "") {
      setAmountToInvest("");
      return;
    }
    
    const value = +e.target.value;

    if (Number.isInteger(value) && value > 0) {
      setAmountToInvest(value.toString());
    } else {
    }
  };

  const validateAmount = () => {
    const numValue = Number(amountToInvest);
      if (numValue < props.offeringInformation?.minInvestmentAmount) {
        notification.error({message: "Invalid Amount",description:`The amount must be at least ${props.offeringInformation?.minInvestmentAmount} USD`});
        setAmountToInvest("");
      } else if (numValue > props.offeringInformation.maxInvestmentAmount) {
        notification.error({message: "Invalid Amount",description:`The amount cannot exceed ${props.offeringInformation?.maxInvestmentAmount} USD`});
        setAmountToInvest("");
      } else {
        setAmountToInvest(numValue.toString());
      }
  };

  const securityTokenAmount = (amount: string) => {
    if (!props.currentToken) return;
    const pricePerToken = props.currentToken.pricePerToken;

    if (!pricePerToken) return;

    const tokenAmount = new BigNumber(amount)
      .div(new BigNumber(pricePerToken).div(100))
      .decimalPlaces(18)
      .toFixed();

    return tokenAmount;
  };

  const selectPaymentMethod = (aggregatorAddresses: string[]) => {
    setAggregatorList((prev) => {
      const current = sharedService.clone(prev) as Aggregator[];
      current.forEach((aggregator) => {
        const isEnabled = aggregatorAddresses.some(
          (aggregatorAddress) =>
            aggregatorAddress.toLowerCase() === aggregator.address.toLowerCase()
        );
        aggregator["isEnabled"] = isEnabled;
        if (isEnabled) setSelectedAggregator(aggregator);
      });

      return current;
    });
  };

  const closeTransactionModal = () => {
    setIsModalVisible(false);
    setTransactions([]);

    props.onSuccess();
  };


  const submit = async ({ delegate }) => {
    if (!selectedAggregator) {
      notification.error({
        message: "Payment Method Not Selected",
        description: "Please select a payment method before investing.",
      });
      return;
    }

    if (!selectedWallet) {
      notification.error({
        message: "Wallet Not Connected",
        description: "Please connect your wallet before investing.",
      });
      return;
    }

    if (new BigNumber(depositTokenAmount).gt(selectedAggregator["balance"])) {
      notification.error({
        message: "Insufficient Balance",
        description: "You do not have enough balance to invest in this token.",
      });
      return;
    }

    let allowance = "0";

      allowance = await erc20facet.allowance(
        selectedAggregator.token.address,
        selectedWallet,
        fetchedTokenAddress
      );

    setIsModalVisible(true);

    const maxUint256 = new BigNumber(2).pow(256).minus(1).toFixed();

    try {
      if (
        new BigNumber(allowance).lt(depositTokenAmount)
      ) {
        setTransactions([
          {
            details: "Approving Token Transfer (Once per Payment Method)",
            submitting: true,
          },
          { details: "Investing" },
        ]);
        const receipt0 = await erc20facet.approve(
          selectedAggregator.token.address,
          selectedWallet,
          fetchedTokenAddress,
          maxUint256
        );

        setTransactions((prev) => {
          const current = sharedService.clone(prev);
          current[0].submitting = false;
          current[0].receipt = receipt0;
          return current;
        });

        if (!receipt0.status) return;

        setTransactions((prev) => {
          const current = sharedService.clone(prev);
          current[1].submitting = true;
          return current;
        });

        const receipt1 = await escrowFacet.investInSecurities(
          fetchedTokenAddress,
          selectedWallet,
          selectedAggregator.token.address,
          amountToInvest,
          depositTokenAmount,
          { delegate }
        );

        setTransactions((prev) => {
          const current = sharedService.clone(prev);
          current[1].submitting = false;
          current[1].receipt = receipt1;
          return current;
        });

        if (receipt1.status) {
          await investorService.saveEscrowContractTransferPaymentDetails({
            amountToInvest: Number(amountToInvest),
            no_of_tokens: Number(securityTokenAmount(amountToInvest)),
            modeOfFundTransfer: selectedAggregator.token.symbol,
            tokenConfigurationProcessId:
              props.currentToken.tokenConfigurationProcessId,
            company: props.currentToken.issuerInfo[0].companyName.toLowerCase(),
            token: props.currentToken,
            transactionHash: receipt1.transactionHash,
          });
        }
      } else {
        setTransactions([{ details: "Investing", submitting: true }]);

        console.log({depositTokenAmount});

        const receipt0 = await escrowFacet.investInSecurities(
          fetchedTokenAddress,
          selectedWallet,
          selectedAggregator.token.address,
          amountToInvest,
          depositTokenAmount,
          { delegate }
        );

        setTransactions((prev) => {
          const current = sharedService.clone(prev);
          current[0].submitting = false;
          current[0].receipt = receipt0;
          return current;
        });

        if (receipt0.status) {
          await investorService.saveEscrowContractTransferPaymentDetails({
            amountToInvest: Number(amountToInvest),
            no_of_tokens: Number(securityTokenAmount(amountToInvest)),
            modeOfFundTransfer: selectedAggregator.token.symbol,
            tokenConfigurationProcessId:
              props.currentToken.tokenConfigurationProcessId,
            company: props.currentToken.issuerInfo[0].companyName.toLowerCase(),
            token: props.currentToken,
            transactionHash: receipt0.transactionHash,

          });
        }
      }
    } catch (err) {
      console.error(err);
    }

    setTransactions((prev) => {
      const current = sharedService.clone(prev);
      current.forEach((transaction) => (transaction.submitting = false));
      return current;
    });
  };

  return (
    <>
      <div>
        <p
          style={{
            textAlign: "left",
            fontWeight: "bold",
            fontSize: "30px",
            color: "#4285F4",
          }}
        >
          Investing in {props?.currentToken?.tokenName} (
          {props?.currentToken?.tokenSymbol})
        </p>

        {loading && (
          <div style={{ textAlign: "center" }}>
            <br />
            <Spin size="large" />
          </div>
        )}

        {!loading && (
          <>
            <div style={{ textAlign: "center" }}>
              You're about to invest in{" "}
              <span style={{ fontWeight: "bolder" }}>
                {props?.currentToken?.tokenName} ({props?.currentToken?.tokenSymbol}
                )
              </span>{" "}
              using the Polygon network. Your funds will be secured in a Smart
              Contract
            </div>

            <br />
            <br />

            {/* {selectedWallet?.toLowerCase() !==
              userInfo?.wallet?.toLowerCase() && (
              <WrongMetamaskWalletWarning
                address={userInfo?.wallet?.toLowerCase() ?? ''}
              />
            )} */}

            {/* {selectedWallet?.toLowerCase() ===
              userInfo?.wallet?.toLowerCase() && ( */}
              <div>
                <div style={{ textAlign: "center" }}>
                  <h4 style={{ fontWeight: "bold" }}>
                    Price per Token:{" "}
                    {new BigNumber(props.currentToken.pricePerToken)
                      .div(100)
                      .toFixed()}
                    {" USD"}
                  </h4>
                  <br />
                </div>

                <div>
                  <Row justify="start">
                    <Col span={8}>
                      <p style={{ fontWeight: "bold" }}>Amount to Invest</p>
                    </Col>
                    <Col span={10}>
                      <Input
                        addonAfter={"USD"}
                        value={amountToInvest}
                        onChange={updateAmountToInvest}
                        onBlur={validateAmount} 
                      />
                    </Col>
                  </Row>

                  <br />
                  {amountToInvest && (
                    <h3>
                      You'll receive: {securityTokenAmount(amountToInvest)}{" "}
                      {props?.currentToken?.tokenSymbol}{" "}
                    </h3>
                  )}
                </div>

                <hr />

                <div>
                  <p style={{ fontWeight: "bold" }}>Payment Method</p>
                  <Row justify="center">
                    <Col span={24}>
                      <Table
                        columns={columns}
                        dataSource={aggregatorList}
                        pagination={false}
                        rowKey="address"
                        rowSelection={{
                          type: "radio",
                          // onChange: selectPaymentMethod,
                          onChange: (selectedRowKeys) => selectPaymentMethod(selectedRowKeys.map(String)),
                          selectedRowKeys: aggregatorList
                            ?.filter((aggregator) => aggregator["isEnabled"])
                            .map((aggregator) => aggregator.address),
                        }}
                      />
                    </Col>
                  </Row>

                  <br />
                  {selectedAggregator && depositTokenAmount && (
                    <>
                      {loadingPrice && (
                        <div style={{ textAlign: "center" }}>
                          <br />
                          <Spin size="large" />
                        </div>
                      )}
                      {!loadingPrice && (
                        <h3>
                          You'll be charged:{" "}
                          {new BigNumber(depositTokenAmount)
                            .times(new BigNumber(10).pow(-depositTokenDecimals))
                            .decimalPlaces(10)
                            .toFixed()}{" "}
                          {selectedAggregator.token.symbol}
                        </h3>
                      )}
                    </>
                  )}

                  <div style={{ textAlign: "center" }}>
                    <br />
                    {/* <Button
                      style={{ marginRight: "10px" }}
                      size="large"
                      type="default"
                      onClick={props.onBack}
                    >
                      BACK
                    </Button> */}
                    <Button
                      disabled={
                        !selectedAggregator ||
                        !amountToInvest ||
                        !depositTokenAmount
                      }
                      size="large"
                      type="primary"
                      onClick={() => submit({ delegate: false })}
                    >
                      INVEST
                    </Button>
                  </div>
                </div>
              </div>
            {/* // )} */}

            <br />
            <br />

            <TransactionModal
              title={"Investment"}
              transactions={transactions}
              isModalVisible={isModalVisible}
              closeModal={() => closeTransactionModal()}
            />

          </>
        )}
      </div>
    </>
  );
}
