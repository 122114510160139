import {
  Button,
  Card,
  Col,
  Descriptions,
  Input,
  Modal,
  notification,
  Radio,
  Result,
  Row,
  Spin,
  Table,
  Typography,
} from "antd";
import React, { useState, useEffect } from "react";
import {
  CryptoAccount,
  WithdrawalRequest,
  User,
  TokenConfigurationProcess,
  SymbolDetailsAndSTData,
} from "../../../Shared/interfaces";
import { WithdrawalRequestsService } from "../WithdrawalRequests.service";
import moment from "moment";
import { ApproversAndAccountsService } from "../../ApproversAndAccounts/ApproversAndAccounts.service";
import { SharedService } from "../../../Shared/Shared.service";
import { AuthService } from "../../../Shared/Auth.service";
import { TokenConfigurationService } from "../../../TokenConfigurations/TokenConfiguration.service";
import { SecurityTokenRegistryService } from "../../../Shared/SecurityTokenRegistery/SecurityTokenRegistry.service";
import { SecurityTokenService } from "../../../Shared/SecurityToken/SecurityToken.service";
import { MetamaskService } from "../../../Shared/Metamask.service";
import BigNumber from "bignumber.js";
import TransactionModal from "../../../Shared/TransactionModal";
import TokenConfigSelect from "../../../TokenConfigSelect";
import EscrowFacet from "../../../Shared/SecurityToken/Facets/EscrowFacet";
import ERC20Facet from "../../../Shared/SecurityToken/Facets/ERC20Facet";
import MainFacet from "../../../Shared/SecurityToken/Facets/MainFacet";
import TxFeeDelegationModal from "../../../Shared/TxFeeDelegationModal";
import { aggregators } from "../../../Investor/Home/chainlinkAggregators";

const { Title } = Typography;

const withdrawalRequestsService = new WithdrawalRequestsService();
const approversAndAccountsService = new ApproversAndAccountsService();
const sharedService = new SharedService();
const tokenConfigurationService = new TokenConfigurationService();
const escrowFacet = new EscrowFacet();
const erc20Facet = new ERC20Facet();
const mainFacet = new MainFacet();

const securityTokenRegisteryService = new SecurityTokenRegistryService();
const securityTokenService = new SecurityTokenService();

const useUserContext = () => new AuthService().useUserContext();
const useSelectedWalletContext = () =>
  new MetamaskService().useSelectedWalletContext();

const radioStyle = {
  display: "block",
  height: "30px",
  lineHeight: "30px",
};

export default function ListWithdrawalRequests() {
  const [currencySymbol, setCurrencySymbol] = useState<string>();
  const [address, setAddress] = useState<string | undefined>("");
  const [withdrawnTokens, setWithdrawnTokens] = useState< Record<string, boolean>>({});
  const [escrowDepositTokenInfo, setEscrowDepositTokenInfo] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [withdrawalRequests, setWithdrawalRequests] = useState<WithdrawalRequest[]>();
  const [cryptoAccounts, setCryptoAccounts] = useState<CryptoAccount[]>();
  const [selectedDestAccount, setSelectedDestAccount] = useState<string>();
  const [amount, setAmount] = useState<string>();
  const [submitting, setSubmitting] = useState(false);
  const [approvers, setApprovers] = useState<User[]>();
  const { userInfo } = useUserContext();
  const [symbolDetailsAndSTData, setSymbolDetailsAndSTData] =
    useState<SymbolDetailsAndSTData>();
  const [availableToWithdraw, setAvailableToWithdraw] = useState("");
  const [pendingWithdrawalAmount, setPendingWithdrawalAmount] = useState("");
  const { selectedWallet, networkId } = useSelectedWalletContext();
  const [transactions, setTransactions] = useState<
    { submitting?: boolean; receipt?: any; details: string }[]
  >([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] =
    useState(false);
  const [actionOption, setActionOption] = useState<
    "request" | "approve" | "reject" | "withdraw"
  >();
  const [selectedRecord, setSelectedRecord] = useState<WithdrawalRequest>();
  const [tokenConfigurationProcess, setTokenConfigurationProcess] =
    useState<TokenConfigurationProcess>();
  const [tokenConfigSelectLoaded, setTokenConfigSelectLoaded] = useState(false);
  const [loadingButtonId, setLoadingButtonId] = useState<string | null>(null);
  const [tokenAddressValue, setTokenAddressValue] = useState("");
  const [isDelegationModalVisible, setIsDelegationModalVisible] = useState(false);
  const [handleMetaMaskOpen, setHandleMetaMaskOpen] = useState("");
  const [isFullScreenLoading, setIsFullScreenLoading] = useState(false);



  // const totalAvailable = new BigNumber(availableToWithdraw)
  //   .times(new BigNumber(10).pow(-18))
  //   .minus(pendingWithdrawalAmount)
  //   .decimalPlaces(18)
  //   .toString(10);

  useEffect(() => {
    (async () => {
      if (!userInfo || !tokenConfigSelectLoaded) return;

      if (!tokenConfigurationProcess?.tokenSymbol) return setLoading(false);

      const _symbolDetailsAndSTData =
        await securityTokenRegisteryService.getSymbolDetailsAndSTData(
          tokenConfigurationProcess.tokenSymbol
        );
      setSymbolDetailsAndSTData(_symbolDetailsAndSTData);

      if (!_symbolDetailsAndSTData?.symbolDetails.isDeployed)
        return setLoading(false);

      const depositTokens = await escrowFacet.getDepositTokens(
        _symbolDetailsAndSTData.securityTokenData.contractAddress
      );

      const [
        resWithdrawalRequests,
        resAmountUnderReview,
        resCryptoAccounts,
        resApprovers,
        _escrowDepositTokenInfo,
        issuerSuperAdminWallet,
      ] = await Promise.all([
        withdrawalRequestsService.getWithdrawalRequests(),
        withdrawalRequestsService.getPendingWithdrawalAmount(),
        approversAndAccountsService.getCryptoAccounts(),
        approversAndAccountsService.getApprovers(),
        //securityTokenService.availableToWithdraw(_symbolDetailsAndSTData.securityTokenData.contractAddress),
        await erc20Facet.getTokenDetails(
          depositTokens,
          _symbolDetailsAndSTData.securityTokenData.contractAddress
        ),
        mainFacet.issuerSuperAdmin(
          _symbolDetailsAndSTData?.securityTokenData?.contractAddress
        ),
      ]);
      let _availableToWithdraw = "0";
      const _withdrawalRequests = resWithdrawalRequests.data;
      const _amountUnderReview = resAmountUnderReview.data;
      const _cryptoAccounts = resCryptoAccounts.data;
      const _approvers = resApprovers.data;
      const tokenAddress =
        _symbolDetailsAndSTData?.securityTokenData?.contractAddress;
      setWithdrawnTokens(
        _escrowDepositTokenInfo.reduce((acc, item) => {
          if (_withdrawalRequests.some((req) => req.depositTokenAddress === item.address)) {
            acc[item.address] = true;
          }
          return acc;
        }, {})
      );
      setEscrowDepositTokenInfo(_escrowDepositTokenInfo);
      setWithdrawalRequests(_withdrawalRequests);
      setPendingWithdrawalAmount(_amountUnderReview);
      setCryptoAccounts(_cryptoAccounts);
      setApprovers(_approvers);
      setAvailableToWithdraw(_availableToWithdraw);
      setTokenAddressValue(tokenAddress);
      setSelectedDestAccount(issuerSuperAdminWallet);
      setLoading(false);
    })();
  }, [userInfo, tokenConfigurationProcess, tokenConfigSelectLoaded]);


  const columns = [
    {
      title: "TRANSFER ID",
      dataIndex: "_id",
    },
    {
      title: "DATE OF REQUEST",
      dataIndex: "creationTS",
      render: (value: string) => moment(value).format("lll"),
    },
    {
      title: "AMOUNT TO WITHDRAW (ETH)",
      dataIndex: "amount",
    },
    {
      title: "DESTINATION ACCOUNT",
      dataIndex: "wallet",
    },
    {
      title: "STATUS",
      dataIndex: "status",
      render: (value: WithdrawalRequest["status"],record: WithdrawalRequest) => {
        if (value === "rejected") return "Rejected";
        if (value === "verified") return "Approved";

        const approvalStatus:any = [];
        if (value === "pending") {
          
          if (record.issuerSuperAdminStatus === 'approved') {
            approvalStatus.push("Super Admin ✓");
          } else {
            approvalStatus.push("Super Admin ⌛");
          }
          
          if (record.issuerCounselStatus === 'approved') {
            approvalStatus.push("Counsel Admin ✓");
          } else {
            approvalStatus.push("Counsel Admin ⌛");
          }
          
          if (record.issuerTokenAdminStatus === 'approved') {
            approvalStatus.push("Token Admin ✓");
          } else {
            approvalStatus.push("Token Admin ⌛");
          }
        }
    return <span>{approvalStatus.join(' | ')}</span>;
    },
  }
  ];

  const isApprover = approvers?.find(
    (approver) => approver._id === userInfo?._id
  );

  if (isApprover || userInfo?.role === "issuer token admin") {
    columns.push({
      title: "ACTION",
      render: (record: WithdrawalRequest) => {
        const isPending = record.status === "pending";
        const currentUserRole = userInfo?.role;
        
        const isCurrentRoleApproved = 
          (currentUserRole === 'issuer super admin' && record.issuerSuperAdminStatus === 'approved') ||
          (currentUserRole === 'issuer counsel' && record.issuerCounselStatus === 'approved') ||
          (currentUserRole === 'issuer token admin' && record.issuerTokenAdminStatus === 'approved');
    
        const allAdminsApproved = 
          record.issuerSuperAdminStatus === 'approved' &&
          record.issuerCounselStatus === 'approved' &&
          record.issuerTokenAdminStatus === 'approved';
        return (
          <div style={{display:"grid"}}>
            {userInfo?.role === "issuer token admin" && (
              <>
                <Button
                  loading={loadingButtonId === `withdraw_${record._id}`}
                  disabled={record.status !== "verified"}
                  type="primary"
                  onClick={() => {
                    setSelectedRecord(record);
                    openConfirmationModal("withdraw");
                    setHandleMetaMaskOpen("withdraw");
                    // withdrawETH(record.wallet, record._id, record.amount)
                  }}
                  style={{ borderRadius:"8px"}}
                >
                  Withdraw
                </Button>
              </>
            )}
            {isApprover && (
              <>
                <Button
                  loading={loadingButtonId === `approve_${record._id}`}
                  disabled={!isPending || isCurrentRoleApproved || allAdminsApproved}
                  type="primary"
                  onClick={() => {
                    setSelectedRecord(record);
                    openConfirmationModal("approve");
                    setHandleMetaMaskOpen("approve");
                    // approveWithdrawalRequest(record._id)
                  }}
                  style={{backgroundColor:"Green" ,borderColor:"green", borderRadius:"8px", margin:"4px"}}
                >
                  Approve
                </Button>
                <Button
                  loading={loadingButtonId === `reject_${record._id}`}
                  disabled={!isPending || isCurrentRoleApproved || allAdminsApproved}
                  type="primary"
                  danger
                  onClick={() => {
                    setSelectedRecord(record);
                    openConfirmationModal("reject");
                    // rejectWithdrawalRequest(record._id)
                  }}
                  style={{ borderRadius:"8px"}}
                >
                  Reject
                </Button>
              </>
            )}
          </div>
        );
      },
    } as any);
  }

  // const updateAmount = (value) => {
  //   if (new BigNumber(value).isGreaterThanOrEqualTo(0)) return setAmount(value);
  //   if (!value) return setAmount("");
  // };


  const newWithdrawalRequest = async () => {
    if (!selectedDestAccount || !amount)
      return notification.error({
        message: "Error",
        description: "There are empty fields",
      });
    if (new BigNumber(amount).isEqualTo(0))
      return notification.error({
        message: "Error",
        description: "Amount has to be greather than zero",
      });
    // if(new BigNumber(amount).isGreaterThan(totalAvailable)) return notification.error({message: 'Error', description: 'Invalid amount'});
try{
    setLoadingButtonId("new_withdrawal_request");

    const response = await withdrawalRequestsService.newWithdrawalRequest({
      amount,
      destAccount: selectedDestAccount,
      symbol: currencySymbol,
      depositTokenAddress: address,
      tokenAddress: tokenAddressValue,
    });

    if (response.success) {
      notification.success({
        message: "Success",
        description: "Request sent succesfully",
      });

      setWithdrawnTokens((prev) => ({
        ...prev,
        [address!]: true,
      }));

      const requestId = response.data;

      const _withdrawalRequests = sharedService.clone(withdrawalRequests);

      _withdrawalRequests?.push({
        _id: requestId,
        status: "pending",
        creationTS: Date.now(),
        wallet: selectedDestAccount,
        searchAbleWallet: selectedDestAccount.toLowerCase(),
        companyId: "",
        approvedBy: [],
        rejectedBy: [],
        apiKey: "",
        amount,
        issuerSuperAdminStatus: 'pending',
        issuerCounselStatus: 'pending',
        issuerTokenAdminStatus: 'pending',
      });

      setWithdrawalRequests(_withdrawalRequests);
      setPendingWithdrawalAmount((prev) =>
        new BigNumber(prev).plus(amount).toString(10)
      );
      setIsConfirmationModalVisible(false);
      setAmount("");
      window.location.reload();
    } else {
      throw new Error(response.error.message);
    }
  } catch (error) {
    notification.error({
      message: "Error",
      description: error instanceof Error ? error.message : "An unexpected error occurred",
    });
  } finally {
    setLoadingButtonId(null);
  }
  };

  const approveWithdrawalRequest = async (requestId: string,delegate:boolean) => {
    setLoadingButtonId(`approve_${requestId}`);
    try {

      if(delegate === false){
        const metaMaskResponse = await escrowFacet.withdrawApproval(tokenAddressValue, selectedWallet!)

        if (!metaMaskResponse.status) {
          notification.error({ message: 'Error', description: 'Request Rejected' });
          return;
        }
      }

      const data = {
        requestId,
        adminRole: userInfo?.role,
        status: "approved",
      };

      const response = await withdrawalRequestsService.approveWithdrawalRequest(
        { data }
      );

      if (response.success) {
        notification.success({
          message: "Success",
          description: "Request Approved succesfully",
        });

        const _withdrawalRequests = sharedService.clone(withdrawalRequests);

        const withdrawalRequest = _withdrawalRequests?.find(
          (request) => request._id === requestId
        ) as WithdrawalRequest;

        // withdrawalRequest.approvedBy.push(userInfo?._id as string);

        const approvedBy = [
          ...new Set([
            ...withdrawalRequest.approvedBy,
            userInfo?._id as string,
          ]),
        ];

        const approversIds = approvers?.map(
          (approver) => approver._id
        ) as string[];

        const isPending = approversIds.find(
          (approverId) => !approvedBy.includes(approverId)
        );

        withdrawalRequest.approvedBy = approvedBy;
        withdrawalRequest.status = isPending ? "pending" : "verified";

        setWithdrawalRequests(_withdrawalRequests);
        setIsConfirmationModalVisible(false);
        setSubmitting(false);
        window.location.reload();
      } else {
        throw new Error(response.error.message);
      }
    } catch (error) {
      console.error("Error approving withdrawal request:", error);
      notification.error({
        message: "Error",
        description: "An unexpected error occurred",
      });
    } finally {
      setLoadingButtonId(null);
      setIsConfirmationModalVisible(false);
    }
  };

  const rejectWithdrawalRequest = async (requestId: string) => {
    setLoadingButtonId(`reject_${requestId}`);

    try {
      const response = await withdrawalRequestsService.rejectWithdrawalRequest({
        requestId,
      });

      if (response.success) {
        notification.success({
          message: "Success",
          description: "Request Rejected succesfully",
        });

        const _withdrawalRequests = sharedService.clone(withdrawalRequests);
        const withdrawalRequest = _withdrawalRequests?.find(
          (request) => request._id === requestId
        ) as WithdrawalRequest;

        const rejectedBy = [
          ...new Set([
            ...withdrawalRequest.rejectedBy,
            userInfo?._id as string,
          ]),
        ];

        withdrawalRequest.rejectedBy = rejectedBy;
        withdrawalRequest.status = "rejected";

        setWithdrawalRequests(_withdrawalRequests);
        setPendingWithdrawalAmount((prev) =>
          new BigNumber(prev).minus(withdrawalRequest.amount).toString(10)
        );
        setIsConfirmationModalVisible(false);
        window.location.reload();
      } else {
        throw new Error(response.error.message);
      }
    } catch (error) {
      console.error("Error rejecting withdrawal request:", error);
      notification.error({
        message: "Error",
        description: "An unexpected error occurred",
      });
    } finally {
      setLoadingButtonId(null);
      setIsConfirmationModalVisible(false);
    }
  };

  const withdrawETH = async (
    receiverAccount: string,
    requestId: string,
    amount: string,
    delegate:boolean
  ) => {
    setIsModalVisible(true);
    setLoadingButtonId(`withdraw_${requestId}`);
    setTransactions([{ details: "Initiating  withdrawal", submitting: true }]);
    try {
      if (!receiverAccount || !tokenAddressValue || !selectedWallet) {
        throw new Error(
          `Invalid parameters: receiverAccount=${receiverAccount}, tokenAddressValue=${tokenAddressValue}, selectedWallet=${selectedWallet}`
        );
      }
      const _amount = new BigNumber(amount || 0)
        .times(new BigNumber(10).pow(18))
        .decimalPlaces(0)
        .toString(10);

        if(delegate === false){

          const metaMaskResponse = await escrowFacet.withdrawCollectedAmountFromContract(tokenAddressValue, selectedWallet!, receiverAccount)
          
          if (!metaMaskResponse.status) {
            notification.error({ message: 'Error', description: 'Request Rejected' });
            return;
          }
        }

      await withdrawalRequestsService.markRequestAsIssued({ requestId });

      const _withdrawalRequests = sharedService.clone(withdrawalRequests);
      setWithdrawalRequests(
        _withdrawalRequests?.filter((request) => request._id !== requestId)
      );

      setAvailableToWithdraw((prev) =>
        new BigNumber(prev).minus(_amount).toString(10)
      );
      setPendingWithdrawalAmount((prev) =>
        new BigNumber(prev).minus(amount).toString(10)
      );
      setIsConfirmationModalVisible(false);

      setTransactions([
        { 
          details: "Withdrawal successful", 
          submitting: false, 
          // receipt:{

          // }
        }
      ]);

      notification.success({
        message: "Success",
        description: "Withdrawn successfully",
      });
      window.location.reload();
    } catch (error) {
      console.error(error);
      let errorMessage = "An unknown error occurred";
      if (error instanceof Error) {
        errorMessage = error.message;
      } else if (typeof error === "string") {
        errorMessage = error;
      }
      setTransactions((prev) => [
        {
          ...prev[0],
          submitting: false,
          details: `Error`,
        },
      ]);
      notification.error({ message: "Withdrawal Error", description: "Error" });
      console.log({ errorMessage });
    } finally {
      setLoadingButtonId(null);
      setIsConfirmationModalVisible(false);
    }
  };

  const openConfirmationModal = async (
    
    option: "request" | "approve" | "reject" | "withdraw",
    amountValue?: string,
    smybol?: string,
    addressValue?: string
  ) => {
    const amount = new BigNumber(amountValue ?? 0).isGreaterThanOrEqualTo(0)
      ? amountValue
      : "";
    setAmount(amount);
    setCurrencySymbol(smybol);
    setAddress(addressValue);
    if (option === "request") {
      if (!selectedDestAccount || !amount)
        return notification.error({
          message: "Error",
          description: "There are empty fields",
        });
      if (new BigNumber(amount).isEqualTo(0))
        return notification.error({
          message: "Error",
          description: "Amount has to be greather than zero",
        });
      // if(new BigNumber(amount).isGreaterThan(totalAvailable)) return notification.error({message: 'Error', description: 'Invalid amount'}); 
    }

    setActionOption(option);
    setIsConfirmationModalVisible(true);
  };

  const submitAction = async () => {
    setSubmitting(true);
    if (actionOption === "request") {
      await newWithdrawalRequest();
    } else {
      const record = selectedRecord;
      if (!record) return;

      try {
        if (actionOption === "approve") {
          setLoadingButtonId(`approve_${record._id}`);
          setHandleMetaMaskOpen("approve")
          setIsDelegationModalVisible(true);
        } else if (actionOption === "reject") {
          setLoadingButtonId(`reject_${record._id}`);
          await rejectWithdrawalRequest(record._id);
        } else {
          setLoadingButtonId(`withdraw_${record._id}`);
          setHandleMetaMaskOpen("withdraw")
          setIsDelegationModalVisible(true);
        }
      } catch (error) {
        console.error("Error in submitAction:", error);
      }
    }
    setSubmitting(false);
  };

  const selectTokenConfigProcess = (
    selectedTokenConfigurationProcess: TokenConfigurationProcess
  ) => {
    setLoading(true);
    setTokenConfigurationProcess(selectedTokenConfigurationProcess);
  };

  const handleDelegationModalSubmit = async ({ delegate }) => {
    console.log(delegate,"delegate");

    if (!selectedRecord) {
      console.warn('No record selected');
      return;
    }
 
    setIsDelegationModalVisible(false);

    try {
      const { _id, depositTokenAddress, amount } = selectedRecord;
  
      if (delegate) {
        setIsConfirmationModalVisible(false)
        setIsFullScreenLoading(true)
        await addPriceFeed(delegate);
      }
  
      switch (handleMetaMaskOpen) {
        case 'approve':
          setIsConfirmationModalVisible(false)
          setIsFullScreenLoading(true)
          await approveWithdrawalRequest(_id, delegate);
          break;
        case 'withdraw':
          setIsConfirmationModalVisible(false)
          await withdrawETH(depositTokenAddress, _id, amount,delegate);
          break;
        default:
          throw new Error(`Unexpected handleMetaMaskOpen value: ${handleMetaMaskOpen}`);
      }
  
      setIsConfirmationModalVisible(false);
      setIsFullScreenLoading(false)
    } catch (error) {
      console.error('Error in handleDelegationModalSubmit:', error);
      notification.error({
        message: 'Error',
        description: error instanceof Error ? error.message : 'An unexpected error occurred',
      });
    }
  };
  
  const addPriceFeed = async (delegate:boolean) => {
    try {
      setIsFullScreenLoading(true);
      const tokenAddress = selectedRecord?.tokenAddress;
      const owner = selectedRecord?.depositTokenAddress
      const selectedTokenSymbols = selectedRecord?.symbol;

      if (!tokenAddress || !owner ) {
        throw new Error('Required values are undefined');
      }
      
      if (!networkId) {
        throw new Error('Network ID is not defined');
      }
  
      if (!aggregators[networkId]) {
        throw new Error(`No aggregators found for network ID: ${networkId}`);
      }
  
      if (!Array.isArray(selectedTokenSymbols) || selectedTokenSymbols.length === 0) {
        throw new Error('No tokens selected');
      }
  
      // Filter aggregators based on selected tokens
      const selectedAggregators = aggregators[networkId].filter(agg => 
        selectedTokenSymbols.includes(agg.token.symbol)
      );
  
      if (selectedAggregators.length === 0) {
        throw new Error('No matching aggregators found for selected tokens');
      }
  
      // Extract deposit token addresses and aggregator addresses
      const depositTokens = selectedAggregators.map(agg => agg.token.address);
      const aggregatorAddresses = selectedAggregators.map(agg => agg.address);
      console.log(tokenAddress,"tokenAddress")
      console.log(owner,"owner")
      console.log(depositTokens,"depositTokens")
      console.log(aggregatorAddresses,"aggregatorAddresses")
      console.log(delegate,"delegate")
      const response = await escrowFacet.addPriceFeed(tokenAddress, owner, depositTokens, aggregatorAddresses,{delegate: delegate});
      console.log('Transaction Response:', response);
      notification.success({
        message: 'Success',
        description: 'Price feeds added successfully.',
      });
      setIsFullScreenLoading(false);
    } catch (error) {
      setIsFullScreenLoading(false);
      console.error('Error in addPriceFeed:', error);
      notification.error({
        message: 'Error',
        description: error || 'Failed to add price feeds. Please try again.',
      });
    }
  };

  return (

    <>

      <Row justify="center">
        <Col span={23}>
          {/* {tokenConfigurationProcess && ( */}
          <TokenConfigSelect
            value={tokenConfigurationProcess!}
            onChange={selectTokenConfigProcess}
            onLoad={() => setTokenConfigSelectLoaded(true)}
          />
          {/* )} */}
          {loading && (
            <div style={{ textAlign: "center" }}>
              <br />
              <Spin size="large" />
            </div>
          )}
          {!loading && (
            <Card>
              <Title level={1} style={{ textAlign: "center" }}>
                Withdrawal and Transfer Request Approvers
              </Title>

              {!symbolDetailsAndSTData?.symbolDetails.isDeployed && (
                <>
                  <Result title={`Security Token not deployed`} />
                </>
              )}

              {symbolDetailsAndSTData?.symbolDetails.isDeployed && (
                <>
                  {userInfo?.role === "issuer token admin" &&
                    selectedWallet?.toLowerCase() !==
                      symbolDetailsAndSTData.symbolDetails.owner.toLowerCase() && (

                      <>
                        <Title level={2} style={{ textAlign: "center" }}>
                          Wrong selected wallet on metamask
                        </Title>
                        <Result
                          status="error"
                          title={
                            <p>
                              Select the wallet{" "}
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`${
                                  sharedService.etherscanURL[
                                    networkId as string
                                  ]
                                }/address/${
                                  symbolDetailsAndSTData.symbolDetails.owner
                                }`}
                              >
                                {sharedService.minifyAddress(
                                  symbolDetailsAndSTData.symbolDetails.owner.toLowerCase()
                                )}
                              </a>{" "}
                              in order to Withdraw your Tokens
                            </p>
                          }
                        />
                      </>
                    )}
                  {(userInfo?.role !== "issuer token admin" ||
                    selectedWallet?.toLowerCase() ===
                      symbolDetailsAndSTData.symbolDetails.owner.toLowerCase()) && (
                    <>
                      <Table
                        columns={columns}
                        dataSource={withdrawalRequests}
                        rowKey="_id"
                        pagination={false}
                      />
                      <br/>
                      <br/>
                      {userInfo?.role === "issuer token admin" && (
                        <>
                          {escrowDepositTokenInfo.map((item, index) => (
                            <div key={item.address}>
                              <Descriptions column={2} bordered>
                                <Descriptions.Item
                                  label={`Available balance in Escrow`}
                                >
                                  {item.balance /
                                    Math.pow(10, Number(item.decimals))}{" "}
                                  {item.symbol}
                                </Descriptions.Item>

                                <Descriptions.Item label="Pending withdrawal">
                                  {item.balance /
                                    Math.pow(10, Number(item.decimals))}{" "}
                                  {item.symbol}
                                </Descriptions.Item>

                                <Descriptions.Item
                                  span={2}
                                  label="Available to Withdraw"
                                >
                                  {item.balance /
                                    Math.pow(10, Number(item.decimals))}{" "}
                                  {item.symbol}
                                </Descriptions.Item>
                                <Descriptions.Item>
                                  <Button
                                    key={item.address}
                                    loading={
                                      loadingButtonId ===
                                      `withdraw_${item.address}`
                                    }
                                    onClick={() => {
                                      openConfirmationModal(
                                        "request",
                                        String(
                                          item.balance /
                                            Math.pow(10, Number(item.decimals))
                                        ),
                                        item.symbol,
                                        item.address
                                      );
                                    }}
                                    type="primary"
                                    disabled={
                                      withdrawnTokens[item.address] ||
                                      item.balance <= 0
                                    }
                                  >
                                    Withdraw
                                  </Button>
                                </Descriptions.Item>
                              </Descriptions>
                              <br />
                              <br />
                            </div>
                          ))}
                        </>
                      )}
                    </>
                  )}
                  <br />
                  <br />
                </>
              )}
            </Card>
          )}
        </Col>
      </Row>

      {isFullScreenLoading && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 9999
        }}>
          <Spin size="large" />
        </div>
      )}

      <TxFeeDelegationModal
        isVisible={isDelegationModalVisible}
        onSubmit={handleDelegationModalSubmit}
        onCancel={() => setIsDelegationModalVisible(false)}
      />

      <TransactionModal
        title={"Withdrawal"}
        transactions={transactions}
        isModalVisible={isModalVisible}
        closeModal={() => setIsModalVisible(false)}
      />

      <Modal
        visible={isConfirmationModalVisible}
        okText={"YES"}
        onOk={submitAction}
        okButtonProps={{ loading: submitting }}
        onCancel={() => setIsConfirmationModalVisible(false)}
        cancelText={"NO"}
        title={"Confirm"}
      >
        {actionOption === "request" && (
          <p style={{ textAlign: "center", fontWeight: "bold" }}>
            Are you sure you want to perform this request?
          </p>
        )}
        {actionOption === "approve" && (
          <p style={{ textAlign: "center", fontWeight: "bold" }}>
            Are you sure you want to approve this request?
          </p>
        )}
        {actionOption === "reject" && (
          <p style={{ textAlign: "center", fontWeight: "bold" }}>
            Are you sure you want to reject this request?
          </p>
        )}
        {actionOption === "withdraw" && (
          <p style={{ textAlign: "center", fontWeight: "bold" }}>
            Are you sure you want to perform this withdrawal?
          </p>
        )}
      </Modal>
    </>
  );
}
