import React, { useEffect, useState } from 'react';
import { AuthService } from '../Shared/Auth.service';
import {
  Steps,
} from 'antd';
import {useHistory} from 'react-router-dom';
const stepsContent = {
  minHeight: "200px",
  marginTop: "16px",
  paddingTop: "80px",
  textAlign: 'center',
  backgroundColor: "#fafafa",
  border: "1px dashed #e9e9e9",
  borderRradius: "2px"
}

const { Step } = Steps;
const authService = new AuthService();
const useUserContext = () => authService.useUserContext()

export default ({key}) => {
  const { userInfo, setUserInfo } = useUserContext();
  const [current, setCurrent] = useState(0);
  const history =useHistory();
  const [isBasicInfo, setBasicInfo] = useState(userInfo?.status?.emailVerified);
  const [isTAndC, setTAndC] = useState(userInfo?.tAndCSigned);
  const [IsDisclaimerDocument, setIsDisclaimerDocument] = useState(userInfo?.disclaimerDocument);
  const [isKYC, setKYC] = useState(userInfo?.KYCSubmittedCompany);
  const [isAccreditation, setAccreditation] = useState(userInfo.aiSubmittedCompany);
  const [isOfferingDoc, setOfferingDocs] = useState(userInfo.offeringDocsSigned);
  const [isPayment, setPayments] = useState(false);
  const [isConfirmation, setConfirmation] = useState(false);

  const [basicInfoTitle, setBasicInfoTitle] = useState("In Progress");
  const [tAndCTitle, setTAndCTitle] = useState("Waiting");
  const [disclaimerDocument, setDisclaimerDocument] = useState("Waiting");
  const [kYCTitle, setKYCTitle] = useState("Waiting");
  const [accreditationTitle, setAccreditationTitle] = useState("Waiting");
  const [offeringDocTitle, setOfferingDocsTitle] = useState("Waiting");
  const [paymentTitle, setPaymentsTitle] = useState("Waiting");
  const [confirmationTitle, setConfirmationTitle] = useState("Waiting");

  useEffect(() => {
    (async () => {
      if (isBasicInfo) { //if basic info done
        setBasicInfoTitle('Completed');
        setTAndCTitle('In Progress');
        setDisclaimerDocument('Waiting');
        setKYCTitle('Waiting');
        setAccreditationTitle('Waiting');
        setOfferingDocsTitle('Waiting');
        setPaymentsTitle('Waiting');
        setConfirmationTitle('Waiting');
        setCurrent(1);
        if (isTAndC) { // if tandc
          setBasicInfoTitle('Completed');
          setTAndCTitle('Completed');
          setAccreditationTitle('Waiting');
          setDisclaimerDocument('In Progress');
          setKYCTitle('Waiting');
          setOfferingDocsTitle('Waiting');
          setPaymentsTitle('Waiting');
          setConfirmationTitle('Waiting');
          setCurrent(2);
          if(IsDisclaimerDocument){ //if disclaimer docs done
            setDisclaimerDocument('Completed');
            setKYCTitle('In Progress');
            setCurrent(3);

            if (isKYC.length > 0) { //if kyc done
              console.log('kyc done.......')
              setBasicInfoTitle('Completed');
              setTAndCTitle('Completed');
              setAccreditationTitle('Completed');
              setKYCTitle('Completed');
              setOfferingDocsTitle('In Progress');
              setPaymentsTitle('Waiting');
              setConfirmationTitle('Waiting');
              setCurrent(4);

              if (isAccreditation.length > 0 || userInfo.country.toLowerCase()!=="united states of america") {
                setBasicInfoTitle('Completed');
                setTAndCTitle('Completed');
                setAccreditationTitle('Completed');
                setKYCTitle('In Progress');
                setOfferingDocsTitle('Waiting');
                setPaymentsTitle('Waiting');
                setConfirmationTitle('Waiting');
                setCurrent(4);
              }else{
                setCurrent(4)
                setAccreditationTitle('In Progress');
              }
            }else{ //if kyc not done
              setCurrent(3);
              setKYCTitle('In Progress');
            }
          }else{ //if disclaimer docs not done
            setCurrent(2);
            setDisclaimerDocument('In Progress');
            setKYCTitle('Waiting');
          }
        } else { //if tandc not done
          setBasicInfoTitle('Completed');
          setTAndCTitle('In Progress');
          setKYCTitle('Waiting');
          setAccreditationTitle('Waiting');
          setOfferingDocsTitle('Waiting');
          setPaymentsTitle('Waiting');
          setConfirmationTitle('Waiting');
          setCurrent(1);
        }
      } else { //if basic info not done 
        setBasicInfoTitle('In Progress');
        setTAndCTitle('Waiting');
        setKYCTitle('Waiting');
        setAccreditationTitle('Waiting');
        setOfferingDocsTitle('Waiting');
        setPaymentsTitle('Waiting');
        setConfirmationTitle('Waiting');
        setCurrent(0);
      }
    }
    )();
  }, [userInfo,key]);

  const steps = [
    {
      title: "Your Request Is In Review By Platform Issuer Admin.",
      status: 'info'
    },
    {
      content: "You Have To Accept Terms & Condition To Invest.",
      status: 'info'
    },
    {
      content: "You Have to Complete Your AI Verification , Your Request will be Reviewed By Issuer Compiliance Admin.",
      status: 'info'
    },
    {
      content: "You Have to Complete Your KYC Verification , Your Request will be Reviewed By Issuer Compiliance Admin.",
      status: 'info'
    },
    {
      content: offeringDocTitle == 'In Progress' ? "You Have To Sign Offering Documents To Invest " : "Your Can Invest Now .",
      status: offeringDocTitle == 'In Progress' ? 'info' : 'success'
    }
  ];


  return (
    <>
      <Steps
        size="small"
        current={current}
        onChange={()=> history.push('/investor/home')}
      >
        <Step title={basicInfoTitle}
          description="Self verification" />

        <Step title={tAndCTitle}
          description="Acceptance of NDA." />

        <Step title={disclaimerDocument}
          description="Acceptance of Disclaimer Document." />

        <Step title={kYCTitle}
          description="KYC" />

        { userInfo?.country.toLowerCase() ==="united states of america" && <Step title={accreditationTitle}
          description="Accredition" />}


        {/* <Step title={offeringDocTitle}
          description="Offering Docs" /> */}

        {/*
          <Step title={paymentTitle}
          description="Payments" />

        <Step title={confirmationTitle}
          description="Confirmation" />
        */}

      </Steps>
    </>
  );
}