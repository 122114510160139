import { Col, Row, Button } from "antd";
import React,{useEffect}from "react";
import { Container } from "reactstrap";
import sign from "../../assets/images/icons/tick.png";
const TermsOfUse = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  return (
    <div style={{ paddingTop: "150px", textAlign: "justify" }}>
  
      <Container>
        <div style={{ padding: "20px" }}></div>
        <div style={{ textAlign: "center" }}>
          <p style={{ color: "#141838", fontSize: "55px", fontWeight: "bold", marginBottom:'80px'}}>
            <span className="underline">Terms Of Use</span>
          </p>
        </div>
        <Row>
          <Col className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <ul class="tick">
              <div style={{ padding: "15px" }}></div>
              Welcome to Apex Defi Labs (“ADL”) and our Terms of Service (“Terms”). These Terms are important and affect your legal rights, so please read them carefully. Note that Section 13 of these Terms contains a mandatory arbitration provision that requires the use of arbitration on an individual basis and limits the remedies available to you in the event of certain disputes. By accessing or using the websites, mobile applications, tokens, and wallets (collectively, the “Sites”) or Services provided by ADL that link to these Terms, you agree to be bound by these Terms and all terms incorporated herein by reference. If you do not agree to these Terms, you may not access or use the Sites or Services.
              The services provided by ADL (the “Services”) assist you in understanding and participating in the world of decentralized finance (DeFi). These Terms govern your access and use of the Sites and your use of such Services.
              Please note that the Sites and Services do not guarantee against identity theft or online identity fraud and are not substitutes for online security software. These Terms do not alter in any way the terms or conditions of any other agreement you may have with us regarding any products, services, or otherwise.
              If you are using the Sites or Services on behalf of any entity, you represent and warrant that you are authorized to accept these Terms on such entity’s behalf and that such entity agrees to be responsible to us if you or such entity violates these Terms. ADL reserves the right to change or modify these Terms at any time at our sole discretion. If we make changes to these Terms, we will provide notice of such changes, such as by providing notice through the Sites or updating the “Last Updated” date at the beginning of these Terms. By continuing to access or use the Sites or Services, you confirm your acceptance of the revised Terms and all terms incorporated therein by reference. We encourage you to review the Terms frequently to ensure that you understand the terms and conditions that apply when you access or use the Sites or Services. If you do not agree to the revised Terms, you may not access or use the Sites or Services.
              <div style={{ padding: "15px" }}></div>
              <h3 style={{ color: "#00b6e3" }}>1.Privacy Policy</h3>
              Please refer to our Privacy Policy for information about how we collect, use, and disclose information about you.
              <div style={{ padding: "15px" }}></div>
              <h3 style={{ color: "#00b6e3" }}>
                2.Registration, Account, and Communication Preferences
              </h3>
              To access and use certain areas or features of the Sites and Services, you will need to register for an ADL account. By creating an account, you agree to (a) provide accurate, current, and complete account information about yourself as necessary, (b) maintain and promptly update your account information as necessary, (c) maintain the security of your password and accept all risks of unauthorized access to your account and the information you provide to us, and (d) immediately notify us if you discover or otherwise suspect any security breaches related to the Sites, Services, or your account.
              <div style={{ padding: "15px" }}></div>
              By creating an ADL account, you also consent to receive electronic communications from ADL (e.g., via email or by posting notices to the Sites or Services). These communications may include notices about your account (e.g., password changes and other transactional information) and are part of your relationship with us. You agree that any notices, agreements, disclosures, or other communications that we send to you electronically will satisfy any legal communication requirements, including, but not limited to, that such communications be in writing.
              <div style={{ padding: "15px" }}></div>
              <h3 style={{ color: "#00b6e3" }}>3.Payment Obligations</h3>
              A subscription may be necessary for you to access certain parts of a Service. By agreeing to these Terms, you accept the responsibility to pay these subscription costs as indicated during your enrollment with ADL or based on future requests made through the Services. Payment must be made by you or on your behalf via any accepted cryptocurrency or a valid credit or debit card. By providing payment information to ADL, you represent that you are authorized to make transactions using that payment method and you authorize ADL to make necessary charges against that payment method—automatic, recurring, or individual—as part of providing the Services. You may cancel payment by downgrading or stopping services through your member settings. You further acknowledge that you are responsible for any applicable state, federal, or other taxes as well as any fees associated with your subscription to Services unless otherwise noted.
              <div style={{ padding: "15px" }}></div>
              <h3 style={{ color: "#00b6e3" }}>4.Trademarks</h3>
              “Apex Defi Labs,” the ADL logo, and any other ADL product or service names, logos, or slogans that may appear on the Sites or Services are trademarks of ADL and may not be copied, imitated, or used, in whole or in part, without our prior written permission. You may not use any metatags or other “hidden text” utilizing “Apex Defi Labs” or any other name, trademark, product, or service name of ADL without our prior written permission. In addition, the look and feel of the Sites and Services, including, without limitation, all page headers, custom graphics, button icons, and scripts, constitute the service mark, trademark, or trade dress of ADL and may not be copied, imitated, or used, in whole or in part, without our prior written permission. All other trademarks, registered trademarks, product names, and company names or logos mentioned on the Sites or Services are the property of their respective owners and may not be copied, imitated, or used, in whole or in part, without the permission of the applicable trademark holder. Reference to any products, services, processes, or other information by name, trademark, manufacturer, supplier, or otherwise does not constitute or imply endorsement, sponsorship, or recommendation by ADL.
              <div style={{ padding: "15px" }}></div>
              <h3 style={{ color: "#00b6e3" }}>5.Hyperlinks</h3>
              You are granted a limited, nonexclusive, non-transferable right to create a text hyperlink to the Sites for non-commercial purposes, provided that such link does not portray ADL or any of our products or services in a false, misleading, derogatory, or otherwise defamatory manner, and provided further that the linking site does not contain any adult or illegal material or any material that is offensive, harassing, or otherwise objectionable. This limited right may be revoked at any time. You may not use an ADL logo or other proprietary graphic of ADL to link to the Sites without our express written permission. Further, you may not use, frame, or utilize framing techniques to enclose any ADL trademark, logo, or other proprietary information, including the images found on the Sites or Services, the content of any text, or the layout or design of any page, or form contained on a page, on the Sites without our express written consent.
              <div style={{ padding: "15px" }}></div>
              The ADL Services may provide, or third parties may provide, links to other World Wide Web sites or resources. Because ADL has no control over such sites and resources, you acknowledge and agree that ADL is not responsible for the availability of such external sites or resources, and does not endorse and is not responsible or liable for any content, advertising, products, or other materials on or available from such sites or resources. You further acknowledge and agree that ADL shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods, or services available on or through any such site or resource.
              <h3 style={{ color: "#00b6e3" }}>6.Third Party Content</h3>
              We may display content from third parties through our Sites and Services (collectively, “Third-Party Content”). We do not control, endorse, or adopt any Third-Party Content, and we make no representations or warranties of any kind regarding such Third-Party Content, including, without limitation, its accuracy or completeness. You acknowledge and agree that your interactions with third parties providing Third-Party Content are solely between you and those third parties, and that Apex Defi Labs is not responsible or liable in any manner for such interactions or Third-Party Content.
              <div style={{ padding: "15px" }}></div>
              <h3 style={{ color: "#00b6e3" }}>7.User Conduct</h3>
              You agree that you will not violate any laws, contracts, intellectual property rights, or other third-party rights or commit a tort, and that you are solely responsible for your conduct while accessing or using the Sites or Services. You agree that you will abide by these Terms and will not:
              <div style={{ padding: "15px" }}></div>
              <li style={{ paddingBottom: "20px", textAlign: "justify" }}>
                <img
                  src={sign}
                  style={{ height: "30px", width: "30px", marginRight: "20px" }}
                />
                <span style={{ textAlign: "justify" }}>
                Provide false or misleading information to Apex Defi Labs.
                </span>
              </li>
              <li style={{ paddingBottom: "20px", textAlign: "justify" }}>
                <img
                  src={sign}
                  style={{ height: "30px", width: "30px", marginRight: "20px" }}
                />
                <span style={{ textAlign: "justify" }}>
                Use or attempt to use another user’s account without authorization from such user and Apex Defi Labs.
                </span>
              </li>
              <li style={{ paddingBottom: "20px", textAlign: "justify" }}>
                <img
                  src={sign}
                  style={{ height: "30px", width: "30px", marginRight: "20px" }}
                />
                <span style={{ textAlign: "justify" }}>
                Use the Sites or Services in any manner that could interfere with, disrupt, negatively affect, or inhibit other users from fully enjoying the Sites or Services, or that could damage, disable, overburden, or impair the functioning of the Sites or Services in any manner.
                </span>
              </li>
              <li style={{ paddingBottom: "20px", textAlign: "justify" }}>
                <img
                  src={sign}
                  style={{ height: "30px", width: "30px", marginRight: "20px" }}
                />
                <span style={{ textAlign: "justify" }}>
                Develop, utilize, or disseminate any software, or interact with our API in any manner, that could damage, harm, or impair the Sites or Services.
                </span>
              </li>
              <li style={{ paddingBottom: "20px", textAlign: "justify" }}>
                <img
                  src={sign}
                  style={{ height: "30px", width: "30px", marginRight: "20px" }}
                />
                <span style={{ textAlign: "justify" }}>
                Reverse engineer any aspect of the Sites or Services, or do anything that might discover source code or bypass or circumvent measures employed to prevent or limit access to any Content, area, or code of the Sites or Services.
                </span>
              </li>
              <li style={{ paddingBottom: "20px", textAlign: "justify" }}>
                <img
                  src={sign}
                  style={{ height: "30px", width: "30px", marginRight: "20px" }}
                />
                <span style={{ textAlign: "justify" }}>
                Attempt to circumvent any content-filtering techniques we employ or attempt to access any feature or area of the Sites or Services that you are not authorized to access.
                </span>
              </li>
              <li style={{ paddingBottom: "20px", textAlign: "justify" }}>
                <img
                  src={sign}
                  style={{ height: "30px", width: "30px", marginRight: "20px" }}
                />
                <span style={{ textAlign: "justify" }}>
                Use any robot, spider, crawler, scraper, script, browser extension, offline reader, or other automated means or interface not authorized by us to access the Sites and Services, extract data, or otherwise interfere with or modify the rendering of Site pages or functionality.{" "}
                </span>
              </li>
              <li style={{ paddingBottom: "20px", textAlign: "justify" }}>
                <img
                  src={sign}
                  style={{ height: "30px", width: "30px", marginRight: "20px" }}
                />
                <span style={{ textAlign: "justify" }}>
                Use data collected from our Sites and Services to contact individuals, companies, or other persons or entities.{" "}
                </span>
              </li>
              <li style={{ paddingBottom: "20px", textAlign: "justify" }}>
                <img
                  src={sign}
                  style={{ height: "30px", width: "30px", marginRight: "20px" }}
                />
                <span style={{ textAlign: "justify" }}>
                Use data collected from our Sites and Services for any direct marketing activity (including, without limitation, email marketing, SMS marketing, telemarketing, and direct marketing).
                </span>
              </li>
              <li style={{ paddingBottom: "20px", textAlign: "justify" }}>
                <img
                  src={sign}
                  style={{ height: "30px", width: "30px", marginRight: "20px" }}
                />
                <span style={{ textAlign: "justify" }}>
                Use the Sites or Services for any illegal or unauthorized purpose, or engage in, encourage, or promote any activity that violates these Terms.
                </span>
              </li>
              <div style={{ padding: "15px" }}></div>
              <h3 style={{ color: "#00b6e3" }}>8.Feedback</h3>
              You can submit questions, comments, suggestions, ideas, original or creative materials, or other information about Apex Defi Labs, the Sites, or the Services (collectively, “Feedback”). Feedback is nonconfidential and shall become the sole property of Apex Defi Labs. Apex Defi Labs shall own exclusive rights, including, without limitation, all intellectual property rights in and to such Feedback and shall be entitled to the unrestricted use and dissemination of this Feedback for any purpose, commercial or otherwise, without acknowledgment or compensation to you.
              <div style={{ padding: "15px" }}></div>
              <h3 style={{ color: "#00b6e3" }}>9.Indemnification</h3>
              To the fullest extent permitted by applicable law, you agree to indemnify, defend, and hold harmless Apex Defi Labs and our respective past, present, and future employees, officers, directors, contractors, consultants, equity holders, suppliers, vendors, service providers, parent companies, subsidiaries, affiliates, agents, representatives, predecessors, successors, and assigns (individually and collectively, the “Apex Defi Labs Parties”), from and against all actual or alleged claims, damages, awards, judgments, losses, liabilities, obligations, penalties, interest, fees, expenses (including, without limitation, attorneys’ fees and expenses), and costs (including, without limitation, court costs, costs of settlement, and costs of pursuing indemnification and insurance), of every kind and nature whatsoever, whether known or unknown, foreseen or unforeseen, matured or unmatured, or suspected or unsuspected, in law or equity, whether in tort, contract, or otherwise (collectively, “Claims”), including, but not limited to, damages to property or personal injury, that are caused by, arise out of, or are related to (a) your use or misuse of the Sites, Content, or Services, (b) any Feedback you provide, (c) your violation of these Terms, and (d) your violation of the rights of another. You agree to promptly notify Apex Defi Labs of any third-party Claims and cooperate with the Apex Defi Labs Parties in defending such Claims. You further agree that the Apex Defi Labs Parties shall have control of the defense or settlement of any third-party Claims. This indemnity is in addition to, and not in lieu of, any other indemnities set forth in a written agreement between you and Apex Defi Labs.
              <div style={{ padding: "15px" }}></div>
              <h3 style={{ color: "#00b6e3" }}>10. Disclaimers</h3>
              Apex Defi Labs MAKES NO WARRANTIES OF ANY KIND AS TO THE SERVICES AND CONTENT CONTAINED THEREIN, WHICH ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. Apex Defi Labs DISCLAIMS ALL OTHER WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT. WHILE WE STRIVE TO PROVIDE ACCURATE AND RELIABLE INFORMATION, DUE TO THE VOLATILE NATURE OF THE CRYPTOCURRENCY SPACE, WE CANNOT REPRESENT OR WARRANT THAT THE CONTENT IS ENTIRELY ACCURATE, COMPLETE, RELIABLE, CURRENT, OR ERROR-FREE. IF A USER IDENTIFIES ERRONEOUS CONTENT, WE ENCOURAGE THEM TO REPORT IT IMMEDIATELY.

              <div style={{ padding: "15px" }}></div>
              SOME JURISDICTIONS MAY NOT ALLOW THE DISCLAIMER OF IMPLIED TERMS IN CONTRACTS WITH CONSUMERS, SO SOME OR ALL OF THE DISCLAIMERS IN THIS SECTION MAY NOT APPLY TO YOU. 
              <div style={{ padding: "15px" }}></div>
              Apex Defi Labs MAKES NO WARRANTIES, EXPRESS OR IMPLIED, WITH RESPECT TO ANY REFERENCED THIRD-PARTY PRODUCTS AND EXPRESSLY DISCLAIMS ANY WARRANTY OR CONDITION OF MERCHANTABILITY, NON-INFRINGEMENT, OR FITNESS FOR A PARTICULAR PURPOSE. IN NO EVENT WILL Apex Defi Labs BE LIABLE FOR ANY INCIDENTAL, CONSEQUENTIAL, OR OTHER DAMAGES ARISING OUT OF YOUR USE OF OR INABILITY TO USE SUCH THIRD-PARTY SERVICES.
              <div style={{ padding: "15px" }}></div>
              
              <h3 style={{ color: "#00b6e3" }}>
                11.Limitation of Liability; Release
              </h3>
              TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL Apex Defi Labs OR ANY OF ITS AFFILIATES BE LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, EXEMPLARY, OR PUNITIVE DAMAGES OF ANY KIND (INCLUDING, BUT NOT LIMITED TO, LOSS OF REVENUE, INCOME OR PROFITS, LOSS OF USE OR DATA, LOSS OR DIMINUTION IN VALUE OF ASSETS OR SECURITIES, OR DAMAGES FOR BUSINESS INTERRUPTION) ARISING OUT OF OR IN ANY WAY RELATED TO THE ACCESS OR USE OF THE SITES OR CONTENT OR THE USE OF ANY SERVICE OR OTHERWISE RELATED TO THESE TERMS (INCLUDING, BUT NOT LIMITED TO, ANY DAMAGES CAUSED BY OR RESULTING FROM RELIANCE BY ANY USER ON ANY INFORMATION OBTAINED FROM Apex Defi Labs, OR FROM MISTAKES, OMISSIONS, INTERRUPTIONS, DELETIONS OF FILES OR EMAILS, ERRORS, DEFECTS, BUGS, VIRUSES, TROJAN HORSES, DELAYS IN OPERATION OR TRANSMISSION OR ANY FAILURE OF PERFORMANCE, WHETHER OR NOT RESULTING FROM ACTS OF GOD, COMMUNICATIONS FAILURE, THEFT, DESTRUCTION OR UNAUTHORIZED ACCESS TO Apex Defi Labs’ RECORDS, PROGRAMS OR SYSTEMS), REGARDLESS OF THE FORM OF ACTION, WHETHER BASED IN CONTRACT, TORT (INCLUDING, BUT NOT LIMITED TO, SIMPLE NEGLIGENCE, WHETHER ACTIVE, PASSIVE OR IMPUTED), STRICT PRODUCT LIABILITY, OR ANY OTHER LEGAL OR EQUITABLE THEORY (EVEN IF THE PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES AND REGARDLESS OF WHETHER SUCH DAMAGES WERE FORESEEABLE).

              <div style={{ padding: "15px" }}></div>
              TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL THE MAXIMUM AGGREGATE LIABILITY OF Apex Defi Labs ARISING OUT OF OR IN ANY WAY RELATED TO THESE TERMS, THE ACCESS TO AND USE OF THE SITES, SERVICES, AND MATERIALS OR ANY PRODUCTS OR SERVICES PURCHASED FROM US EXCEED THE AMOUNT OF $1.00 USD PER WALLET. DAMAGES AND RESTITUTION ARE PAYABLE IN USD AS OPPOSED TO CRYPTOCURRENCY.
              <div style={{ padding: "15px" }}></div>
              Some jurisdictions do not allow the exclusion or limitation of incidental or consequential damages, so the above limitation or exclusion may not apply to you. Some jurisdictions also limit disclaimers or limitations of liability for personal injury from consumer products, so this limitation may not apply to personal injury claims.
              <div style={{ padding: "15px" }}></div>
              <h3 style={{ color: "#00b6e3" }}>
                12.Modifications to the Sites and Services
              </h3>
              Apex Defi Labs reserves the right in its sole discretion to modify, suspend, or discontinue, temporarily or permanently, the Sites (or any features or parts thereof) or the provision of the Services at any time and without liability therefor.
              <div style={{ padding: "15px" }}></div>
              <h3 style={{ color: "#00b6e3" }}>
                13.Dispute Resolution; Arbitration
              </h3>
              PLEASE READ THE FOLLOWING SECTION CAREFULLY BECAUSE IT REQUIRES YOU TO ARBITRATE CERTAIN DISPUTES AND CLAIMS WITH Apex Defi Labs AND LIMITS THE MANNER IN WHICH YOU CAN SEEK RELIEF FROM US.
              <div style={{ padding: "15px" }}></div>
              <li style={{ paddingBottom: "20px", textAlign: "justify" }}>
                <div class="rule-row" style={{display:'flex'}}>
                  <img
                    src={sign}
                    style={{ height: "30px", width: "30px", marginRight: "20px" }}
                  />
                  <h3 style={{ color: "#00b6e3",display:'inline', marginBottom:'10px' }}>13.1 Binding Arbitration:</h3>
                </div>
                <span style={{ textAlign: "justify" }}>
                
                  Except with respect to any disputes, claims, suits, actions, causes of action, demands, or proceedings (collectively, “Disputes”) in which either party seeks to bring an individual action in small claims court or seeks injunctive or other equitable relief for the alleged unlawful use of intellectual property, including, without limitation, copyrights, trademarks, trade names, logos, trade secrets, or patents, you and Apex Defi Labs agree (a) to waive your and Apex Defi Labs’ respective rights to have any and all Disputes arising from or related to these Terms, or the Sites, Content, or Services, resolved in a court, and (b) to waive your and Apex Defi Labs’ respective rights to a jury trial. Instead, you and Apex Defi Labs agree to arbitrate Disputes through binding arbitration (which is the referral of a Dispute to one or more persons charged with reviewing the Dispute and making a final and binding determination to resolve it instead of having the Dispute decided by a judge or jury in court).
                </span>
              </li>
              <li style={{ paddingBottom: "20px", textAlign: "justify" }}>
              <div class="rule-row" style={{display:'flex'}}>
                  <img
                    src={sign}
                    style={{ height: "30px", width: "30px", marginRight: "20px" }}
                  />
                  <h3 style={{ color: "#00b6e3",display:'inline', marginBottom:'10px' }}>13.2 No Class Arbitrations, Class Actions or Representative
                  Actions:</h3>
                </div>
                <span style={{ textAlign: "justify" }}>
                  You and Apex Defi Labs agree that any Dispute arising out of or related to these Terms or the Sites, Content, or Services is personal to you and Apex Defi Labs and that such Dispute will be resolved solely through individual arbitration and will not be brought as a class arbitration, class action, or any other type of representative proceeding. You and Apex Defi Labs agree that there will be no class arbitration or arbitration in which an individual attempts to resolve a Dispute as a representative of another individual or group of individuals. Further, you and Apex Defi Labs agree that a Dispute cannot be brought as a class or other type of representative action, whether within or outside of arbitration, or on behalf of any other individual or group of individuals.
                </span>
              </li>
              <li style={{ paddingBottom: "20px", textAlign: "justify" }}>
              <div class="rule-row" style={{display:'flex'}}>
                  <img
                    src={sign}
                    style={{ height: "30px", width: "30px", marginRight: "20px" }}
                  />
                  <h3 style={{ color: "#00b6e3",display:'inline', marginBottom:'10px' }}>13.3 Federal Arbitration Act:</h3>
                </div>
                <span style={{ textAlign: "justify" }}>
                  You and Apex Defi Labs agree that these Terms affect interstate commerce and that the enforceability of this Section shall be both substantively and procedurally governed by and construed and enforced in accordance with the Federal Arbitration Act, 9 U.S.C. § 1 et seq. (the “FAA”), to the maximum extent permitted by applicable law.
                </span>
              </li>
              <li style={{ paddingBottom: "20px", textAlign: "justify" }}>
              <div class="rule-row" style={{display:'flex'}}>
                  <img
                    src={sign}
                    style={{ height: "30px", width: "30px", marginRight: "20px" }}
                  />
                  <h3 style={{ color: "#00b6e3",display:'inline', marginBottom:'10px' }}>13.4 Notice; Informal Dispute Resolution:</h3>
                </div>
                <span style={{ textAlign: "justify" }}>
                  You and Apex Defi Labs agree that each party will notify the other party in writing of any arbitrable or small claims Dispute within thirty (30) days of the date it arises, so that the parties can attempt in good faith to resolve the Dispute informally. Notice to Apex Defi Labs shall be sent by certified mail or courier to Apex Defi Labs, Attn: Registered Agent Name, Street, City, State, Zip. Your notice must include (a) your name, postal address, telephone number, the email address you use or used for your Apex Defi Labs account, and, if different, an email address at which you can be contacted, (b) a description in reasonable detail of the nature or basis of the Dispute, and (c) the specific relief that you are seeking. Our notice to you will be sent electronically and will include (x) our name, postal address, telephone number, and an email address at which we can be contacted with respect to the Dispute, (y) a description in reasonable detail of the nature or basis of the Dispute, and (z) the specific relief that we are seeking. If you and Apex Defi Labs cannot agree on how to resolve the Dispute within thirty (30) days after the applicable party receives the notice, then either you or Apex Defi Labs may, as appropriate, commence an arbitration proceeding or, to the extent specifically provided for in Section 13.1, file a claim in court.
                </span>
              </li>
              <li style={{ paddingBottom: "20px", textAlign: "justify" }}>
              <div class="rule-row" style={{display:'flex'}}>
                  <img
                    src={sign}
                    style={{ height: "30px", width: "30px", marginRight: "20px" }}
                  />
                  <h3 style={{ color: "#00b6e3",display:'inline', marginBottom:'10px' }}>13.5 Process:</h3>
                </div>
                <span style={{ textAlign: "justify" }}>
                  Except for Disputes arising out of or related to a violation of user conduct or Disputes in which either party seeks to bring an individual action in small claims court or seeks injunctive or other equitable relief for the alleged unlawful use of intellectual property, including, without limitation, copyrights, trademarks, trade names, logos, trade secrets, or patents, you and Apex Defi Labs agree that any Dispute must be commenced or filed by you or Apex Defi Labs within one (1) year of the date the Dispute arose; otherwise, the underlying claim is permanently barred (which means that you and Apex Defi Labs will no longer have the right to assert such claim regarding the Dispute). You and Apex Defi Labs agree that (a) any arbitration will occur in the State of New Jersey, (b) arbitration will be conducted confidentially by a single arbitrator in accordance with the rules of the Judicial Arbitration and Mediation Services (“JAMS”), which are hereby incorporated by reference, and (c) that the state or federal courts of the State of New Jersey and the United States, respectively, sitting in the State of New Jersey, have exclusive jurisdiction over any appeals and the enforcement of an arbitration award. You may also litigate a Dispute in the small claims court located in the county of your billing address if the Dispute meets the requirements to be heard in small claims court.
                </span>
              </li>
              <li style={{ paddingBottom: "20px", textAlign: "justify" }}>
              <div class="rule-row" style={{display:'flex'}}>
                  <img
                    src={sign}
                    style={{ height: "30px", width: "30px", marginRight: "20px" }}
                  />
                  <h3 style={{ color: "#00b6e3",display:'inline', marginBottom:'10px' }}>13.6 Authority of Arbitrator:</h3>
                </div>
                <span style={{ textAlign: "justify" }}>
                  As limited by the FAA, these Terms and the applicable JAMS rules, the arbitrator will have (a) the exclusive authority and jurisdiction to make all procedural and substantive decisions regarding a Dispute, including the determination of whether a Dispute is arbitrable, and (b) the authority to grant any remedy that would otherwise be available in court; provided, however, that the arbitrator does not have the authority to conduct a class arbitration or a representative action, which is prohibited by these Terms. The arbitrator may only conduct an individual arbitration and may not consolidate more than one individual’s claims, preside over any type of class or representative proceeding, or preside over any proceeding involving more than one individual.
                </span>
              </li>
              <li style={{ paddingBottom: "20px", textAlign: "justify" }}>
              <div class="rule-row" style={{display:'flex'}}>
                  <img
                    src={sign}
                    style={{ height: "30px", width: "30px", marginRight: "20px" }}
                  />
                  <h3 style={{ color: "#00b6e3",display:'inline', marginBottom:'10px' }}>13.7 Rules of JAMS:</h3>
                </div>
                <span style={{ textAlign: "justify" }}>
                  The rules of JAMS and additional information about JAMS are available on the JAMS website. By agreeing to be bound by these Terms, you either (a) acknowledge and agree that you have read and understand the rules of JAMS, or (b) waive your opportunity to read the rules of JAMS and any claim that the rules of JAMS are unfair or should not apply for any reason.
                </span>
              </li>
              <li style={{ paddingBottom: "20px", textAlign: "justify" }}>
              <div class="rule-row" style={{display:'flex'}}>
                  <img
                    src={sign}
                    style={{ height: "30px", width: "30px", marginRight: "20px" }}
                  />
                  <h3 style={{ color: "#00b6e3",display:'inline', marginBottom:'10px' }}>13.8 Severability:</h3>
                </div>
                <span style={{ textAlign: "justify" }}>
                  If any term, clause, or provision of this Agreement is held invalid or unenforceable, it will be so held to the minimum extent required by law, and all other terms, clauses, and provisions of this Section 13 will remain valid and enforceable. Further, the waivers set forth in this Agreement are severable from the other provisions of these Terms and will remain valid and enforceable, except as prohibited by applicable law.
                </span>
              </li>
              <li style={{ paddingBottom: "20px", textAlign: "justify" }}>
              <div class="rule-row" style={{display:'flex'}}>
                  <img
                    src={sign}
                    style={{ height: "30px", width: "30px", marginRight: "20px" }}
                  />
                  <h3 style={{ color: "#00b6e3",display:'inline', marginBottom:'10px' }}>13.9 Opt-Out Right:</h3>
                </div>
                <span style={{ textAlign: "justify" }}>
                  You have the right to opt out of binding arbitration within thirty (30) days of the date you first accessed our site by writing to Apex Defi Labs.
                </span>
              </li>
              <div style={{ padding: "15px" }}></div>
              <h3 style={{ color: "#00b6e3" }}>14.Governing Law and Venue</h3>
              These Terms, your access to and use of the Apex Defi Labs website and your use of the Services shall be governed by and construed and enforced in accordance with the laws of the State of New Jersey, without regard to conflict of law rules or principles (whether of the State of New Jersey or any other jurisdiction) that would cause the application of the laws of any other jurisdiction. Any dispute between the parties that is not subject to arbitration or cannot be heard in small claims court shall be resolved in the state or federal courts of the State of New Jersey, respectively, sitting in the State of New Jersey.
              <div style={{ padding: "15px" }}></div>
              <h3 style={{ color: "#00b6e3" }}>15.Termination</h3>
              Notwithstanding anything contained in these Terms, Apex Defi Labs reserves the right, without notice and in its sole discretion, to terminate your right to access or use the Sites and Services at any time and for any or no reason. You acknowledge and agree that Apex Defi Labs shall have no liability or obligation to you in such an event and that you will not be entitled to a refund of any amounts that you have already paid to Apex Defi Labs, to the fullest extent permitted by applicable law.
              <div style={{ padding: "15px" }}></div>
              <h3 style={{ color: "#00b6e3" }}>16.Severability</h3>
              If any term, clause, or provision of these Terms is held invalid or unenforceable, then that term, clause, or provision will be severable from these Terms and will not affect the validity or enforceability of any remaining part of that term, clause, or provision, or any other term, clause, or provision of these Terms.
              <div style={{ padding: "15px" }}></div>
              <h3 style={{ color: "#00b6e3" }}>17.Miscellaneous</h3>
              These Terms constitute the entire agreement between you and Apex Defi Labs relating to your access to and use of the Sites and Services. These Terms, and any rights and licenses granted hereunder, may not be transferred or assigned by you without the prior written consent of Apex Defi Labs. Apex Defi Labs' failure to assert any right or provision under these Terms shall not constitute a waiver of such right or provision. Except as otherwise provided herein, these Terms are intended solely for the benefit of the parties and are not intended to confer third-party beneficiary rights upon any other person or entity.
              <div style={{ padding: "15px" }}></div>
              By creating an Apex Defi Labs account, you consent to receive electronic communications from Apex Defi Labs (e.g., via email or by posting notices to the Sites or Services). These communications may include notices about your account (e.g., password changes and other transactional information) and are part of your relationship with Apex Defi Labs. You agree that any notices, agreements, disclosures, or other communications that we send to you electronically will satisfy any legal communication requirements, including, but not limited to, that such communications be in writing.
              <div style={{ padding: "15px" ,borderBottom:'1px solid'}}></div>
            </ul>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default TermsOfUse;
