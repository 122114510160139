import React from 'react';
import HeaderNav from './HeaderNav';
import Sidenav from './SideNav';
import { Layout } from 'antd';
import { AuthService } from '../Shared/Auth.service';
import './Nav.scss';
import { useLocation } from 'react-router-dom';
import Header from '../../layout/header';
import queryString from "query-string";

const { Content } = Layout;

const authService = new AuthService();

const useUserContext = () => authService.useUserContext();

export default ({children, isNotConnectedToMetamask}) => {

  const {userInfo} = useUserContext();
  const location = useLocation();
  const query = queryString.parse(location.search);
  const currentPath = location.pathname;

  return (
    <Layout>
      {(userInfo) && <HeaderNav/> } {/* if user loggedIn */}
      { (!userInfo  &&  ['/account/register/investor','/account/login'].includes(currentPath)) &&  <Header/>}  {/* if (user is loggedOut) and (routes login or register)*/}
      

      <Layout style={{marginTop:'64px'}} className="full-layout-height">
        {userInfo && !isNotConnectedToMetamask && 
          <Sidenav/>
        }

        <Layout className='main-content-u' style={{ marginLeft: (userInfo && !isNotConnectedToMetamask)? 200 : 0}}>
          <Content>
            {children}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}