import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo/logo.png";
import Config from "../../configure";
import $ from "jquery";
import "./index.css";
class OuterHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navMenuMobile: false,
      redirect: false,
    };
  }
  toggleNavMenu = () => {

    this.setState({ navMenuMobile: !this.state.navMenuMobile });
  };
  handleScroll = () => {
    if (this.mount) {
      this.setState({ scroll: window.scrollY });
    }
  };

  componentDidMount() {
    this.mount = true;
    const el = document.querySelector(".gc_main_menu_wrapper");
    this.setState({ top: el.offsetTop + 700, height: el.offsetHeight });
    window.addEventListener("scroll", this.handleScroll);
  }
  componentDidUpdate() {
    this.state.scroll > this.state.top
      ? (document.body.style.paddingTop = `${this.state.height}px`)
      : (document.body.style.paddingTop = "0");
  }
  componentWillUnmount() {
    this.mount = false;
  }
  render() {
    $(document).ready(() => {
      $('.wd_single_index_menu ul li a[href^="#"]').bind(
        "click",
        function (event) {
          event.preventDefault();
          event.stopPropagation();
          var $anchor = $(this);
          if ($(window).width() > 991) {
            var headerH = "60";
          } else {
            headerH = "56";
          }
          $("html, body")
            .stop()
            .animate(
              {
                scrollTop:
                  $($anchor.attr("href")).offset().top - headerH + "px",
              },
              800
            );
        }
      );
      $(window).scroll(function () {
        var windscroll = $(window).scrollTop();
        var target = $(".wd_single_index_menu ul li");
        if (windscroll >= 0) {
          $(".wd_scroll").each(function (i) {
            if ($(this).position().top <= windscroll + 90) {
              target.removeClass("active");
              target.eq(i).addClass("active");
            }
          });
        } else {
          target.removeClass("active");
          $(".wd_single_index_menu ul li:first").addClass("active");
        }
      });
    });
    return (
      <div id="default" className="wd_scroll_wrap wd_scroll">
        <header
          className={`gc_main_menu_wrapper ${
            this.state.scroll > this.state.top
              ? "menu_fixed animated fadeInDown"
              : ""
          }`}
        >
          <Container fluid>
            <Row style={{marginTop:'5px'}}>
              <Col className="col-xs-12" sm={12} md={3} lg={3} >
                <div className="logo-area">
                  <Link to={Config.defaultPath}>
                    <img
                      src={logo}
                      style={{ height: "60px", marginLeft: "40px" }}
                      alt="logo"
                    />
                  </Link>
                  <div className="menu-area" style={{display:'flex'}}>
                    <div id="login" className="login-btn">
                      <Link to="/account/register/investor" className="btn1">
                        <i className="fa fa-user-plus">Register</i>
                      </Link>
                    </div>
                    <div id="register" className="login-btn">
                      <Link to="/account/login" className="btn1">
                        <i className="fa fa-user">Login</i>
                      </Link>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={9} md={9} sm={12} className="col-xs-6">
                <div className="menu-area hidden-xs">
                  <div className="login-btn">
                    <Link to="/account/login" style={{paddingLeft:'35px',paddingRight:'35px',fontSize:'16px'}} className="primary-btn">
                      <i className="fa fa-user"></i>
                      <span>Login</span>
                    </Link>
                  </div>
                  {/* <div className="login-btn" >
                    <Link to="/account/register/investor" className="btn1">
                      <i className="fa fa-user-plus"></i>
                      <span>Register</span>
                    </Link>
                  </div> */}
                </div>
              </Col>
            </Row>
          </Container>
        </header>
      </div>
    );
  }
}

export default OuterHeader;
