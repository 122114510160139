import React, { useEffect, useState } from 'react';
import { Empty, Divider, Card, Row, Table, Progress ,Statistic,Space, Button, Typography, Col, Tabs} from 'antd';
import { AuthService } from '../../Shared/Auth.service'
import { InvestorService } from '../Investor.service'
import './Dashboard.scss'
import my_tokens from '../../../assets/images/logo/my-tokens.svg'
const { Text, Title } = Typography;
const { TabPane } = Tabs;

const authService = new AuthService();
const useUserContext = () => authService.useUserContext()
const colorConfiguration = {
    textAlign: 'left',
    color: '#1890ff',
    fontSize: '15px',
    fontWeight: 'bold',
}


const MyTokens = (props) => {
    
    const handleTabChange = (activeKey) => {
        const selectedToken = props.paymentDetails[activeKey];
        props.setTokenDetails(selectedToken);
      };

    return (
      <Card
        className="my-tokens-card"
        bordered={false}
        bodyStyle={{ padding: '20px', borderRadius: '10px' }}
      >
        <Row justify="space-between" align="middle" style={{ width: '100%',marginBottom:'15px' }}>
          <Col>
          <Space align="center" size="middle">
            <img src={my_tokens} alt="" />
            <Title level={4} style={{ marginBottom: 0 }}>My Tokens</Title>
          </Space>
          </Col>
          <Col>
            <Button type="primary" ghost shape="round">View All</Button>
          </Col>
        </Row>
  
        <Tabs defaultActiveKey="1" className="my-tokens-tabs" style={{ width: '100%' }} onChange={handleTabChange} >
          {props.paymentDetails.map((pd, index) => (
            <TabPane tab={pd?.no_of_tokens + " " + pd?.token?.tokenSymbol} key={index.toString()}/>
          ))}
        </Tabs>
  
        <div className="token-details">
          <Row gutter={[16, 16]} style={{ width: '100%' }}>
            <Col xs={24} sm={12} md={8} lg={4}>
              <Text strong>Regulation :</Text> {props.tokenDetails?.token?.regulation}
            </Col>
            <Col xs={24} sm={12} md={8} lg={4}>
              <Text strong>Token Values :</Text> {props.tokenDetails?.token?.pricePerToken}
            </Col>
            <Col xs={24} sm={12} md={8} lg={4}>
              <Text strong>Amount Paid :</Text> {props.tokenDetails?.amountToInvest}

            </Col>
            <Col xs={24} sm={12} md={8} lg={4}>
              <Text strong>Type of Security :</Text> {props.tokenDetails?.token?.typeOfSecurity}

            </Col>
            <Col xs={24} sm={12} md={8} lg={4}>
              <Text strong>Issuer :</Text> {props.tokenDetails?.company}

            </Col>
            <Col xs={24} sm={12} md={8} lg={4}>
              <Text strong>Mode Of Fund Transfer :</Text> {props.tokenDetails?.modeOfFundTransfer}
            </Col>
          </Row>
        </div>
      </Card>
    );
  };

export default () => {
    const { userInfo, setUserInfo } = useUserContext();
    const [totalInvestor, setTotalInvestor] = useState();
    const [sharePercentage, setSharePercentage] = useState();
    const [paymentDetails, setPaymentDetails] = useState([]);
    const [lastUpdatedDate, setLastUpdatedDate] = useState('');
    const [tokenDetails, setTokenDetails ] = useState(null);
    const transactionDataSource = [];
    const netEstimatedDataSource = [];
    const netEstimatedColumns = [
        {
            title: 'Exchange',
            dataIndex: 'exchange',
            key: 'exchange',
        },
        {
            title: 'Estimated Value',
            dataIndex: 'estimatedValue',
            key: 'estimatedValue',
        }
    ];
    const transactionColumns = [
        {
            title: 'Tokens',
            dataIndex: 'tokens',
            key: 'tokens',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Sent | Recived',
            dataIndex: 'sent|recived',
            key: 'sent|recived',
        },
        {
            title: 'From | To',
            dataIndex: 'from|to',
            key: 'from|to',
        }
    ];
    useEffect(() => {
        (async () => {
            const result = await new InvestorService().getPayments({ investorId: userInfo._id });
            if (result.success && result.data) {
                setPaymentDetails(result.data.filter(t => t.accept === true));
            }
        })();
    }, [userInfo._id])

    useEffect(()=>{
        if(paymentDetails && paymentDetails.length){
            setTokenDetails(paymentDetails[0])
        }
    },[paymentDetails])
    
    return (
        <>
            <Row>
                <MyTokens paymentDetails={paymentDetails} tokenDetails={tokenDetails} setTokenDetails={setTokenDetails}></MyTokens>
            </Row>
            <Row>
                <br></br>
            </Row>
            {/* <Row>
                <Card bordered={true} style={{ width: "70%" }} title={
                    < Divider orientation="left" style={colorConfiguration}> Net Estimated Value </Divider>
                }>
                    Last Updated On : {lastUpdatedDate}
                    <br></br>
                    <br></br>
                    <Table dataSource={netEstimatedDataSource} columns={netEstimatedColumns} />
                </Card>

                <Card bordered={true} style={{ width: "30%" }} title={
                    < Divider orientation="left" style={colorConfiguration}> Investors & Shares </Divider>
                }>
                    <Statistic title="Total Investors" value={totalInvestor} />
                    <br></br>
                    Your Shares : 
                    <br></br>
                    <br></br>
                    <Progress  type="circle" percent={sharePercentage}  />      
                </Card>
            </Row>   */}
            <Row>
                <br></br>
            </Row>
            <Row>
            <div className="custom-table-container">
              <div className="table-header">
                <h2>Transactions</h2>
                <Button type="link" className="view-all-btn">View All</Button>
              </div>
              <Table dataSource={transactionDataSource} columns={transactionColumns} pagination={false} rowClassName={(record, index) => (index % 2 === 0 ? 'even-row' : 'odd-row')} />
            </div>
            </Row>
        </>
    )
}