import { MetamaskService } from '../Metamask.service';
import { RegLaunched, TokenModule, TokenPurchaseTrx, WhitelistedWallet } from '../interfaces';

export class SecurityTokenService {
  public contract = {
    ABI: require('./ABI.json')
  };

  private get web3() {
    return MetamaskService.web3;
  }

  public precision = 10;

  async addInvestorLockedAmountMulti(tokenAddress: string, owner: string, lockPeriod: number, _from: string[], _amount: string[]) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return contractInstance.methods.addInvestorLockedAmountMulti(lockPeriod, _from, _amount).send({ from: owner });
  }

  async addWhitelistedWallet(tokenAddress: string, owner: string, _from: string, _isAffiliate: boolean, _isFromUSA: boolean, _isAccredetedInvestor: boolean) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return contractInstance.methods.addWhitelistedWallet(_from, _isAffiliate, _isFromUSA, _isAccredetedInvestor).send({ from: owner });
  }

  async addWhitelistedWalletMulti(tokenAddress: string, owner: string, _from: string[], _isAffiliate: boolean[], _isFromUSA: boolean[], _isAccredetedInvestor: boolean[]) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return contractInstance.methods.addWhitelistedWalletMulti(_from, _isAffiliate, _isFromUSA, _isAccredetedInvestor).send({ from: owner });
  }

  async removeWhitelistedWallet(tokenAddress: string, owner: string, _from: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return contractInstance.methods.removeWhitelistedWallet(_from).send({ from: owner });
  }

  async addModule(tokenAddress: string, owner: string, _moduleFactoryName: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return contractInstance.methods.addModule(_moduleFactoryName).send({ from: owner });
  }

  async transfer(tokenAddress: string, owner: string, _to: string, _value: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return contractInstance.methods.transfer(_to, _value).send({ from: owner });
  }

  async regTransfer(tokenAddress: string, owner: string, _to: string, _value: string, _launchIndex: number) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return contractInstance.methods.regTransfer(_to, _value, _launchIndex).send({ from: owner });
  }

  async removeLegend(tokenAddress: string, owner: string, _creationTS: number) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return contractInstance.methods.removeLegend(_creationTS).send({ from: owner });
  }

  async approveFiledNoticeWithSEC(tokenAddress: string, owner: string, _from: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return contractInstance.methods.approveFiledNoticeWithSEC(_from).send({ from: owner });
  }

  async purchaseToken(tokenAddress: string, owner: string, amount: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return contractInstance.methods.purchaseToken().send({ from: owner, value: amount });
  }

  async issueInvestorTokens(tokenAddress: string, owner: string, lockPeriod: number, indexArr: string[], _amount: string[]) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return contractInstance.methods.issueInvestorTokens(lockPeriod, indexArr, _amount).send({ from: owner });
  }

  async withdrawFromContract(tokenAddress: string, owner: string, receiverAccount: string, _amount: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return contractInstance.methods.withdrawFromContract(receiverAccount, _amount).send({ from: owner });
  }

  async relaunchToken(tokenAddress: string, owner: string, _regulation: string, _regDTransferableOutsideUSA: boolean, _creationTS: number, _typeOfSecurity: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return contractInstance.methods.relaunchToken(_regulation, _regDTransferableOutsideUSA, _creationTS, _typeOfSecurity).send({ from: owner });
  }

  async setDocument(tokenAddress: string, owner: string, _name: string, _uri: string, _documentHash: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return contractInstance.methods.setDocument(_name, _uri, _documentHash).send({ from: owner });
  }

  async removeDocument(tokenAddress: string, owner: string, _name: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return contractInstance.methods.removeDocument(_name).send({ from: owner });
  }

  async setDefaultSenderReg(tokenAddress: string, owner: string, _index: number) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return contractInstance.methods.setDefaultSenderReg(_index).send({ from: owner });
  }

  async burnAllRegs(tokenAddress: string, owner: string, target: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return contractInstance.methods.burnAllRegs(target).send({ from: owner });
  }

  async issueReg(tokenAddress: string, owner: string, target: string, mintedAmount: string, _launchIndex: number) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return contractInstance.methods.issueReg(target, mintedAmount, _launchIndex).send({ from: owner });
  }

  async getDefaultSenderReg(tokenAddress: string, _from: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return (await contractInstance.methods.getDefaultSenderReg(_from).call()) as RegLaunched;
  }

  async getRegLaunchedByIndex(tokenAddress: string, _index: number) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return (await contractInstance.methods.getRegLaunchedByIndex(_index).call()) as RegLaunched;
  }

  async getRegLaunchedByTS(tokenAddress: string, _creationTS: number) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return (await contractInstance.methods.getRegLaunchedByTS(_creationTS).call()) as RegLaunched;
  }

  async getDocument(tokenAddress: string, _name: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return (await contractInstance.methods.getDocument(_name).call()) as [string, string, string];
  }

  async getAllDocuments(tokenAddress: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return (await contractInstance.methods.getAllDocuments().call()) as string[];
  }

  async balanceOf(tokenAddress: string, _from: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return (await contractInstance.methods.balanceOf(_from).call()) as string;
  }

  async getRegulationsLaunched(tokenAddress: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return (await contractInstance.methods.getRegulationsLaunched().call()) as RegLaunched[];
  }

  async regBalanceOf(tokenAddress: string, _from: string, _launchIndex: number) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return (await contractInstance.methods.regBalanceOf(_from, _launchIndex).call()) as string;
  }

  async availableToWithdraw(tokenAddress: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return ("0") as string;

  }

  async getTokensToIssue(tokenAddress: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return (await contractInstance.methods.getTokensToIssue().call()) as TokenPurchaseTrx[];
  }

  async legendRemoved(tokenAddress: string, _creationTS: number) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return (await contractInstance.methods.legendRemoved(_creationTS).call()) as boolean;
  }

  async canTransfer(tokenAddress: string, _from: string, _to: string, _value: string, _launchIndex: number, _isDefaultTransfer: boolean) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return (await contractInstance.methods.canTransfer(_from, _to, _value, _launchIndex, _isDefaultTransfer).call()) as [boolean, string];
  }

  async monthUnit(tokenAddress: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return (await contractInstance.methods.monthUnit().call()) as string;
  }

  async owner(tokenAddress: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return (await contractInstance.methods.owner().call()) as string;
  }

  async issuerComplianceAdmin(tokenAddress: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return (await contractInstance.methods.issuerComplianceAdmin().call()) as string;
  }

  async issuerCounsel(tokenAddress: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return (await contractInstance.methods.issuerCounsel().call()) as string;
  }

  async totalSupply(tokenAddress: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return (await contractInstance.methods.totalSupply().call()) as string;
  }

  async getWhitelist(tokenAddress: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return (await contractInstance.methods.getWhitelist().call()) as WhitelistedWallet[];
  }

  async getWhitelistedWallet(tokenAddress: string, _from: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return (await contractInstance.methods.getWhitelistedWallet(_from).call()) as WhitelistedWallet;
  }

  async getTotalWhitelist(tokenAddress: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return (await contractInstance.methods.getTotalWhitelist().call()) as string;
  }

  async USDPrice(tokenAddress: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return (await contractInstance.methods.USDPrice().call()) as string;
  }

  async regulation(tokenAddress: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return (await contractInstance.methods.regulation().call()) as string;
  }

  async getAffiliatesWithApprovedFileNotice(tokenAddress: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return (await contractInstance.methods.getAffiliatesWithApprovedFileNotice().call()) as string[];
  }

  async isModule(tokenAddress: string, _name: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return (await contractInstance.methods.isModule(_name).call()) as boolean;
  }

  async getModule(tokenAddress: string, _name: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return (await contractInstance.methods.getModule(_name).call()) as TokenModule;
  }

  async rule144ConfigInitialized(tokenAddress: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return (await contractInstance.methods.rule144ConfigInitialized().call()) as boolean;
  }


  getGeneralTransferManagerModule(tokenAddress: string) {
    return this.getModule(tokenAddress, 'GENERALTRANSFERMANAGER');
  }

  async transferOwnershipIssuerTokenAdmin(tokenAddress: string, oldOwner: any, newOwner: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    console.log(contractInstance);
    return contractInstance.methods.transferOwnershipIssuerTokenAdmin(newOwner).send({ from: oldOwner });
  }


}
