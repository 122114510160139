import React, { useState, useEffect } from 'react';
import { AuthService } from "../../Shared/Auth.service";
import { Typography,Card, Table, Modal, Form, Button, Tooltip, Input, Select, Spin, message, notification } from "antd";
import moment from "moment";
import { useHistory } from 'react-router-dom';
import { InvestorService } from '../Investor.service';

const { Option } = Select;
const { Title } = Typography;

const formItemLayout = {
  labelCol: {
    xs: { span: 26 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 28 },
    sm: { span: 16 },
  },
};

const investorService = new InvestorService();
const useUserContext = () => new AuthService().useUserContext();

export default () => {
  const { userInfo } = useUserContext();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [investors, setInvestors] = useState([]);
  const [series, setSeries] = useState();
  const [inviteNo, setInviteNo] = useState(0);
  const [modalForm] = Form.useForm();
  const [isInviteFormModalVisible, setIsInviteFormModalVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalInvites, setTotalInvites] = useState(0);

  const [seriesId, setSeriesId] = useState();


  const fetchData = async (page, size) => {
    if (!userInfo) return;

    setLoading(true);
    const response = await investorService.getInvites(
      userInfo?.role === (
        "issuer super admin" 
        || "platform super admin"
        || "platform compliance admin" 
        || "platform issuer admin"

      ) ? '' :userInfo?._id,
       page, size);
    if (response.data) {
      setInvestors(response.data.invites);
      setInviteNo(+response.data.invites.length + 1);
      setSeries(response.data.seriesName);
      setSeriesId(response.data.seriesId);
      setTotalInvites(response.data.totalCount);
    }
    setLoading(false);
  }

  useEffect(() => {
    fetchData(currentPage, pageSize);
  }, [userInfo, currentPage, pageSize]);
  
  useEffect(() => {
    fetchData(currentPage, pageSize);
  }, [userInfo, currentPage, pageSize]);


  const columns = [
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Created Date',
      dataIndex: 'creationTs',
      render: (value) => moment(value).format('lll')
    }
  ];



  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
    fetchData(pagination.current, pagination.pageSize);
  };

  //Invite new investor Start
  const openInviteFormModal = () => {
    modalForm.resetFields();
    setIsInviteFormModalVisible(true);
  }

  const inviteInvestors = async(formValue) => {
    setLoading(true);

    const newInviteNo = investors.length + 1;

    const response = await investorService.inviteInvestor({
      firstName: formValue.firstName,
      lastName: formValue.lastName,
      email: formValue.email,
      inviteNo: newInviteNo,
      seriesId: seriesId,
      userId: userInfo?._id
    });

    if(response.success) {
      notification.success({
        message: 'Success',
        description: 'Invite sent to Investor Successfully'
      });
      modalForm.resetFields();
      setIsInviteFormModalVisible(false);
      fetchData(currentPage, pageSize);
    } else {
      console.error(response.error);
      message.error(response.error.message);
    }

    setLoading(false);
  }

  return (
    <div>
      {loading && (<div style={{ textAlign: 'center' }}> <br /> <Spin size='large' /></div>)}
      {!loading &&
        <>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'right', margin: '23px 25px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}>
              <Title className='fontForBold' level={2} style={{ margin: '0px 10px 0px 0px' }}>Investors</Title>
              <Tooltip title="Invite Investors"><Button style={{ borderRadius: '18px', fontWeight: 'bold' }} className="custom-plus-button" onClick={() => openInviteFormModal()}>+</Button>
              </Tooltip>
            </div>

          </div>
          <Card style={{ borderRadius: '10px', margin: '20px' }}>
            <Table
              columns={columns}
              dataSource={investors}
              scroll={{ x: 'max-content' }}
              className="table"
              style={{ borderRadius: '10px', padding: '0px' }}
              pagination={{
                current: currentPage,
                pageSize: pageSize,
                total: totalInvites,
                showSizeChanger: true,
                pageSizeOptions: ['10', '20', '30', '50'],
              }}
              onChange={handleTableChange}
            />
          </Card>
          <Modal
            title={`Invite Investor`}
            okText='SUBMIT'
            cancelText='CANCEL'
            visible={isInviteFormModalVisible}
            onOk={() => modalForm.submit()}
            onCancel={() => {
              setIsInviteFormModalVisible(false);
            }}
            okButtonProps={{
              loading: loading,
            }}
          >

            <Form
              form={modalForm}
              autoComplete={'off'}
              {...formItemLayout}
              labelAlign="left"
              onFinish={inviteInvestors}
            >
              <Form.Item label="First Name" name="firstName"
                rules={[{ required: true, message: 'Please input First Name!' }]}>
                <Input size="large" />
              </Form.Item>
              <Form.Item label="Last Name" name="lastName"
                rules={[{ required: true, message: 'Please input Last Name!' }]}>
                <Input size="large" />
              </Form.Item>
              <Form.Item label="Email" name="email"
                rules={[{ required: true, message: 'Please input Email!' }]}>
                <Input size="large" />
              </Form.Item>
            </Form>
          </Modal>
        </>
      }
    </div>
  );
}