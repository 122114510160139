import React, { useState, useEffect } from 'react';
import { Menu, Layout, Typography } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { AuthService } from '../../Shared/Auth.service';
import { MetamaskService } from '../../Shared/Metamask.service';
import { useLocation, useHistory } from 'react-router-dom';

import './HeaderNav.scss';
import { SharedService } from '../../Shared/Shared.service';
import { User } from '../../Shared/interfaces';
import { environment } from '../../../environments/environment';
import apex from '../../../assets/images/logo/apext.png'
const { Header } = Layout;
const { Title } = Typography;
const { SubMenu } = Menu;

const authService = new AuthService();
const sharedService = new SharedService();
const metamaskService = new MetamaskService();

const useUserContext = () => authService.useUserContext();
const useSelectedWalletContext = () => metamaskService.useSelectedWalletContext();

const menuHeader = sharedService.menuHeader;

export default () => {
  const history = useHistory();
  const {pathname} = useLocation();

  const {userInfo, setUserInfo} = useUserContext();
  const {selectedWallet, networkId} = useSelectedWalletContext();

  // console.log(userInfo);

  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

  useEffect(() => {
    setSelectedKeys([pathname]);
  }, [pathname]);

  const logout = async() => {
    setUserInfo(null);
    sharedService.deleteToken();
    history.push('/');
  }

  const changeSelectedRole = async(role: User['role']) => {
    if(!userInfo) return;
    
    setUserInfo({...userInfo, role});
    

    const newToken: string = (await sharedService.changeSelectedRole(role)).data;

    sharedService.setToken(newToken);
  }


  const goTo = ({key}) => history.push(key);

  const viewWalletOnEtherscan = () => {
    if(!selectedWallet || !sharedService.allowedNetworks.includes(networkId as string)) return;

    window.open(`${sharedService.etherscanURL[networkId as string]}/address/${selectedWallet}`);
  }
  const onClickChangePassword = async () => {
    history.push("/account/change-password");
  };
  

  return (
    <Header style={{ position: 'fixed', zIndex: 2, width: '100%' }} id="components-layout-demo-top-side-2" className="header">
      <div className="logo is-clickable">
        <img src={apex} alt="" />
        <Title id='title' style={{paddingLeft: '10px', paddingRight: '10px'}} level={3}>{environment.headerTitle}</Title>
      </div>
      <Menu className='horizontal-dd' style={{float: 'right'}}  mode="horizontal" selectedKeys={selectedKeys}>

        {userInfo && 
          <Menu.Item key="network" onClick={viewWalletOnEtherscan}>
            <div 
              className={`${sharedService.allowedNetworks.includes(networkId as string)? 'green' : 'red'}-blockchain-dot`}>
            </div> 
            ({sharedService.ethereumNetworks[networkId as string] || 'Disconnected'}) {selectedWallet && sharedService.minifyAddress(selectedWallet)}
          </Menu.Item>
        }
        
        {userInfo && 
          <SubMenu
            title={
              <span className="submenu-title-wrapper" >
                <UserOutlined/>{userInfo.firstName}
              </span>
            }
            >
            {userInfo.roles?.map((role, index) => 
              <Menu.Item 
              className='dd-menu-nav' key={index} style={{fontWeight: userInfo.role === role? 'bold' : 'unset'}} onClick={()=>changeSelectedRole(role)}>
                {role === 'contractor_consultant_Advisor' || role === 'employee'? 
                 `PROFILE (${role === 'contractor_consultant_Advisor'? 'CONTRACTOR' : menuHeader[role]})` : menuHeader[role]
                }
              </Menu.Item>
            )}
            {/* <Menu.ItemGroup title="Item 1"> */}
              <Menu.Item onClick={onClickChangePassword}>Change password</Menu.Item>
              <Menu.Item key="setting:2" onClick={logout}>Logout</Menu.Item>
            {/* </Menu.ItemGroup> */}
          </SubMenu>
        }

        {!userInfo && 
          <Menu.Item key="/account/login" onClick={goTo}>
            <UserOutlined/>Login
          </Menu.Item>
        }
      </Menu>
    </Header>
  );
}