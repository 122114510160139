import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Typography, Form, Input, Button, message, Result, Spin } from 'antd';
import { LinkOutlined } from '@ant-design/icons';
import { SharedService } from '../../Shared/Shared.service';
import { IssuerSuperAdminService } from '../../IssuerSuperAdmin/IssuerSuperAdmin.service';
import { TokenConfigurationProcess, SymbolDetailsAndSTData } from '../../Shared/interfaces';
import { useHistory, useParams } from 'react-router-dom';
import { SecurityTokenRegistryService } from '../../Shared/SecurityTokenRegistery/SecurityTokenRegistry.service';
import { MetamaskService } from '../../Shared/Metamask.service';
import TransactionModal from '../../Shared/TransactionModal';
import { TokenConfigurationService } from '../../TokenConfigurations/TokenConfiguration.service';

// import { SecurityTokenService } from '../../Shared/SecurityToken/SecurityToken.service';
import MainFacet from '../../Shared/SecurityToken/Facets/MainFacet/index';
import ERC1410Facet from "../../Shared/SecurityToken/Facets/ERC1410Facet";
import TxFeeDelegationModal from "../../Shared/TxFeeDelegationModal";

const {Title} = Typography;
const {TextArea} = Input;
const sharedService = new SharedService();
const issuerSuperAdminService = new IssuerSuperAdminService();
const securityTokenRegisteryService = new SecurityTokenRegistryService();
const tokenConfigurationService = new TokenConfigurationService();
const erc1410Facet = new ERC1410Facet();
// const securityTokenService = new SecurityTokenService();
const mainFacet = new MainFacet();

const useSelectedWalletContext = () => new MetamaskService().useSelectedWalletContext();

export default () => {
  const [form] = Form.useForm();
  const history = useHistory();

  const {selectedWallet, networkId} = useSelectedWalletContext();

  const [submitting, setSubmitting] = useState(false);
  const [requestToRemoveLegendSent, setRequestToRemoveLegendSent] = useState<boolean>();
  const [tokenConfigurationProcess, setTokenConfigurationProcess] = useState<TokenConfigurationProcess>();
  const [loading, setLoading] = useState<boolean>(true);
  const [symbolDetailsAndSTData, setSymbolDetailsAndSTData] = useState<SymbolDetailsAndSTData>();
  const [legendRemoved, setLegendRemoved] = useState<boolean>();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [transactions, setTransactions] = useState<{submitting?: boolean, receipt?: any, details: string}[]>([]);

  const {tokenConfigurationProcessId} = useParams<{tokenConfigurationProcessId: string}>();
  const [partition, setPartition] = useState<string>()

  const [isDelegationModalVisible, setIsDelegationModalVisible] = useState(false);


  useEffect(() => {
    (async () => {
      const response = await tokenConfigurationService.getTokenConfigurationProcessById({tokenConfigurationProcessId});
      // const response = await tokenConfigurationService.getLastTokenConfigurationProcess();
      const _tokenConfigurationProcess: TokenConfigurationProcess = response.data;

      if(!_tokenConfigurationProcess?.tokenSymbol) return setLoading(false);

      const _symbolDetailsAndSTData = await securityTokenRegisteryService.getSymbolDetailsAndSTData(_tokenConfigurationProcess.tokenSymbol);
      
      setSymbolDetailsAndSTData(_symbolDetailsAndSTData);
      setTokenConfigurationProcess(_tokenConfigurationProcess);
      
      if(!_symbolDetailsAndSTData?.symbolDetails.isDeployed) return setLoading(false);

      const [_totalPartitions] = await Promise.all([
        erc1410Facet.totalPartitions(
          _symbolDetailsAndSTData.securityTokenData.contractAddress,
        )
      ]);

      const _partitionInfos = await Promise.all(
        _totalPartitions.map(_partition => mainFacet.getPartitionInfo(
          _symbolDetailsAndSTData.securityTokenData.contractAddress,
          _partition,
        ))
      );

      let _partition = '';

      for(let i = 0; i < _partitionInfos.length; i++) {
        // _partitionInfos[i]['partition'] = _totalPartitions[i];
        if (_partitionInfos[i].creationTS === _tokenConfigurationProcess.creationTS.toString()) _partition = _totalPartitions[i];
      }


      const _legendRemoved = await mainFacet.legendRemoved(
        _symbolDetailsAndSTData.securityTokenData.contractAddress,
        _partition
      );

      setPartition(_partition);
      setLegendRemoved(_legendRemoved);


      setLoading(false);
    })();
  }, []);


  const sendRequestToRemoveLegend = async(formValue) => {
    const req = sharedService.clone(formValue);

    setSubmitting(true);
    const response = await issuerSuperAdminService.sendRequestToRemoveLegend(req);

    if (response.success) {
      setRequestToRemoveLegendSent(true);
      
    } else {
      console.error(response.error);
      message.error(response.error);
    }

    setSubmitting(false);
  }


  const openTxFeeDelegationModal = async() => {
    setIsDelegationModalVisible(true);
  }

  const removeLegend = async(prop: {delegate: boolean}) => {
    setIsModalVisible(true);

    setTransactions([
      {details: 'Removing Legend', submitting: true}
    ]);

    try {

      const receipt = await mainFacet.removeLegend(
        symbolDetailsAndSTData?.securityTokenData.contractAddress as string, 
        selectedWallet as string,
        partition as string,
        {delegate: prop.delegate}
      );

      if(receipt.status) setLegendRemoved(true);

      setTransactions(prev => {
        const current = sharedService.clone(prev);
        current[0].receipt = receipt;
        return current;
      });
      
    } catch (err) {
      console.error(err);
    }

    setTransactions(prev => {
      const current = sharedService.clone(prev);
      current[0].submitting = false;
      return current;
    });
  }



  return (
    <>
      <br/><br/>
      <Row justify="center">
        <Col span={20}>
          {loading && 
            <div style={{textAlign:'center'}}>
              <br/>
              <Spin size='large'/>
            </div>
          }

          {!loading && 
            <Card>
              <Title level={1} style={{textAlign:'center'}}>Remove Legend from Securities</Title>

              {!symbolDetailsAndSTData?.symbolDetails.isDeployed &&
                <Result
                  title={`Security Token not deployed`}
                  subTitle={"You have to deploy your Security Token first"}
                  extra={
                    <Button key="console" onClick={() => history.push('/token-configurations')}>
                      Create Token
                    </Button>
                  }
                />
              }

              {symbolDetailsAndSTData?.symbolDetails.isDeployed && selectedWallet?.toLowerCase() !== symbolDetailsAndSTData.symbolDetails.owner.toLowerCase() && 
                <>
                  <Title level={2} style={{textAlign:'center'}}>Wrong selected wallet on metamask</Title>
                  <Result
                    status="error"
                    title = {
                      <p>
                        Select the wallet {' '}
                        <a target="_blank" rel="noopener noreferrer" href={`${sharedService.etherscanURL[networkId as string]}/address/${symbolDetailsAndSTData.symbolDetails.owner}`}>
                          {sharedService.minifyAddress(symbolDetailsAndSTData.symbolDetails.owner)}
                        </a> 
                        {' '} in order to add Wallets to the Whitelist
                      </p>
                    }
                  />
                </>
              }

              {symbolDetailsAndSTData?.symbolDetails.isDeployed && selectedWallet?.toLowerCase() === symbolDetailsAndSTData.symbolDetails.owner.toLowerCase() && 
                <Card bordered={false} className='card-shadow'>
                  <br/>

                  {!requestToRemoveLegendSent && 
                    <>
                      {legendRemoved && 
                        <Result status='success' title={`Legend Removed`} subTitle='Users can now transfer funds'/>
                      }
                      {!legendRemoved && 
                        <>
                          {!tokenConfigurationProcess?.opinionLetterFile && 
                            <Form labelAlign="left" form={form} onFinish={sendRequestToRemoveLegend}>
                              <h3>
                                <b>Request to Remove Legend</b>
                              </h3>
                              <Form.Item labelCol={{span: 4}}
                                name="message"
                              >
                                <TextArea/>
                              </Form.Item>

                              <div style={{textAlign:'right'}}>
                                <Button htmlType='submit' size='large' type='primary' loading={submitting}>
                                  REQUEST FOR AN OPINION LETTER
                                </Button>
                              </div>
                            </Form>
                          }

                          {tokenConfigurationProcess?.opinionLetterFile && 
                            <div>
                              <h3>
                                <b>View Opinion Letter</b>
                              </h3>
                              <a 
                                href={tokenConfigurationProcess.opinionLetterFile.URL} 
                                download={tokenConfigurationProcess.opinionLetterFile.name} 
                                target="_blank" 
                                rel="noopener noreferrer">
                                <LinkOutlined /> Opinion Letter By Issuer Counsel for Removing Legend
                              </a>
                              
                              <br/><br/>
                                <div style={{textAlign:'right'}}>
                                  <Button size='large' type='primary' loading={submitting} onClick={openTxFeeDelegationModal}>
                                    APPROVE AND REMOVE LEGEND
                                  </Button>
                                </div>
                              
                            </div>
                          }
                        </>
                      }

                    </>
                  }

                  {requestToRemoveLegendSent && 
                    <>
                      <Result status='success' title={`Request sent successfully`}/>
                    </>
                  }
                </Card>
              
              }
            </Card>
          }
        </Col>
      </Row>

      <TxFeeDelegationModal
        isVisible={isDelegationModalVisible}
        onSubmit={({delegate}) => {
          setIsDelegationModalVisible(false);
          removeLegend({delegate}).then();
        }}
        onCancel={() => setIsDelegationModalVisible(false)}
      />

      <TransactionModal
        title = {'Legend Removal'}
        transactions = {transactions}
        isModalVisible = {isModalVisible}
        closeModal = {() => setIsModalVisible(false)}
      />
    </>
  );
}