import React, { useState, useEffect } from 'react'
import {List, Popconfirm, Button} from 'antd'
import {PlatformIssuerAdminService} from '../PlatformIssuerAdmin.service'
import {Input,Form,Select} from 'antd'
const {TextArea}=Input;
const {Option}=Select;
const platformIssuerAdminService=new PlatformIssuerAdminService();
export default (props)=>{
  const [questions,setQuestions]=useState([])
  const [expired,setExpired]=useState(false)
  const [displayFlag,setDisplayFlag]=useState(true)
  const [defaultSelectedValue,setDefaultSelectedValue]=useState(-1)
  const [questionValue,setQuestionValue]=useState('')
  const [categories,setCategories]=useState([])
  const [defaultSelectedValueForAnswer,setDefaultSelectedValueForAnswer]=useState(-1)
  const [questionToUpdate,setQuestionToUpdate]=useState({})
  const [submitting,setSubmitting]=useState(false)  

  useEffect(()=>{
    (async ()=>{
      const response=await platformIssuerAdminService.getQuestions( );
      console.log(response.data)
      setQuestions(response.data)
    })();
  },[questions])

  useEffect(() => {
    (async () => {
      const response= await platformIssuerAdminService.getQuestionCategories();
      const cat=response.data['0'].categories
      setCategories(cat);
    })();
  },[]);
  
  const switchToUpdateQuestion=(index)=>{
    const question=questions[index]
    setQuestionToUpdate(question)
    setDisplayFlag(false)
    setDefaultSelectedValue(question.category)
    setDefaultSelectedValueForAnswer(question.type)
    setQuestionValue(question.question)
  }
  const deleteItem=(index)=>{
    (async ()=>{
      const response=await platformIssuerAdminService.removeQuestion(questions[index])
      if(response.success)
      {
        const que=questions.filter(question=>question!==questions[index])
        setQuestions(que)
      }
      else
      {
        alert("try again");
      }
    })();
  }
  const updateQuestion=(values)=>{
    (async ()=>{
      const response=await platformIssuerAdminService.updateQuestion({category:values.category,question:values.question,type:values.type,questionToUpdate:questionToUpdate.question,company:questionToUpdate.company});
      setSubmitting(true)
      setExpired(true)
      if(response.success)
      {
        console.log('response',response.data)
        setQuestions(response.data);
        setSubmitting(false)
        setDisplayFlag(true);
        setExpired(false)
      }
      else
      {
        setSubmitting(false) 
      }
    })();
  }
  
  return(
    <>
    {displayFlag?<div >
    <List disabled={expired} loading={!questions} itemLayout='horizontal' style={{textAlign:'left'}}>
    {questions?.map((obj,index)=><List.Item textalign='left' style={{cursor:'pointer'}} key={index} actions={[
      <Popconfirm title='This question will be deleted!' onConfirm={()=>deleteItem(index)}>
      <Button type='primary' danger>X</Button>
      </Popconfirm>,
      <Button  onClick={()=>switchToUpdateQuestion(index)}>EDIT</Button>]}>
      <List.Item.Meta title={obj.question} description={`Answer type:${obj.type}  Category:${obj.category}  Issuer:${obj.company}`}/></List.Item>)}
    </List>
    </div>:<div>
            <Form
            name='questionForm'
            onFinish={updateQuestion}
            loading={submitting}
            >
              <Form.Item
              label='Select Category of Questions'
              name='category'
              initialValue={defaultSelectedValue}
              rules={[{
                required:true,
                message:'Please select category'
              }]}>
              <Select  loading={!categories}  id='selectCategoryBox' style={{width:280}} disabled>
              <Option value={-1}>&lt;select&gt;</Option>
              {categories?.map((category,index)=><Option value={category} key={index}>{category}</Option>)}
              </Select>
              </Form.Item>
              <Form.Item
              label='Enter the Information Question for issuer'
              name='question'
              initialValue={questionValue}
              rules={[{
                required:true,
                message:'Please enter the question'
              }]}> 
              <TextArea rows={5} style={{width:280,marginRight:70}}></TextArea>
              </Form.Item>
              <Form.Item
              label='Type of answer to the question'
              name='type'
              initialValue={defaultSelectedValueForAnswer}
              rules={[{
                required:true,
                message:'Please select the type of answer'
              }]}>
              <Select  id='answerType'  style={{width:280}} disabled>
              <Option value={-1}>&lt;select&gt;</Option>
              <Option value='option'>Yes/No</Option>
              <Option value='textArea'>Rich Text Area</Option>
              <Option value='textBox'>TextBox</Option>
              <Option value='attachement'>Upload attachement</Option>
              </Select>
              </Form.Item>
              <Form.Item>
              <Button type='primary' htmlType='submit' loading={submitting} >UPDATE THE QUESTION</Button>&nbsp;&nbsp;&nbsp;<Button onClick={()=>setDisplayFlag(true)}>CANCEL</Button>
              </Form.Item>

            </Form>
      </div>}
    </>
  )
}