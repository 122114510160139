import React, { useEffect, useState } from "react";
import { AccountService } from "../../Account.service";
import {
  Form,
  Input,
  Button,
  Card,
  Select,
  InputNumber,
  Col,
  Row,
  message,
  Alert,
  Result,
  DatePicker,Checkbox
} from "antd";
import { Link, useHistory,useLocation } from "react-router-dom";
import moment from "moment";
import { SharedService } from "../../../Shared/Shared.service";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import axios from "axios";
import { AuthService } from "../../../Shared/Auth.service";
import './investroRegistration.css';
import queryString from "query-string";
import regfig from '../../../../assets/images/banner/regfig.png'
import check from '../../../../assets/images/logo/Subtract.png'
import faq from '../../../../assets/images/logo/faq.png'


const { Option } = Select;

const sharedService = new SharedService();
const accountService = new AccountService();

const UserContext = AuthService.UserContext;
const useUserInfo = () => new AuthService().useUserInfo();


const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

export default () => {
  const history = useHistory();
  const [userInfo, setUserInfo] = useUserInfo();
  const [registered, setRegistered] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [countries, setCountries] = useState();
  const [usaStates, setUSAStates] = useState();
  const [form] = Form.useForm();
  const [userCountry, setUserCountry] = useState();
  const [cCountry, setCCountry] = useState(false);
  const [countryError, setCountryError] = useState(false);
  const [referral, setReferral] = useState('');
  const [isAccepted, setIsAccepted] = useState(false);
  const [regUser, setRegUser] = useState('');



  useEffect(() => {
    (async () => {
      const response = await sharedService.getCountries();
      setCountries(response.data);
      const responsee = await sharedService.getUSAStates();
      setUSAStates(responsee.data);
    })();

    (async () => {
      await axios
        .get(
          "https://geolocation-db.com/json/697de680-a737-11ea-9820-af05f4014d91"
        )
        .then((response) => {
          console.log(response.country_name);
          setUserCountry(response.country_name);
        })
        .catch((response) => console.log(response));
    })();
  }, []);

  const location = useLocation();
  let { email,referrer } = queryString.parse(location.search);
  if (email?.length > 0 && email.indexOf(" ") > -1) {
    email = email.replaceAll(" ", "+");
  }

  const forwardToLogin = () => {
    history.push("/account/login");
  };

  const currentCountry = (value) => {
    if (value == "United States of America") setCCountry(true);
    else setCCountry(false);
  };

  const calculateAge = (dateOfBirth) => {
    var dob = new Date(dateOfBirth);

    var month_diff = Date.now() - dob.getTime();

    var age_dt = new Date(month_diff);

    var year = age_dt.getUTCFullYear();

    var age = Math.abs(year - 1970);

    return age;
  };

  const register = async (req) => {
    if (!req.country.toUpperCase().startsWith(userCountry.toUpperCase())) {
      setCountryError(true);
      return;
    }
    setCountryError(false);
    setSubmitting(true);
    req.dateOfBirth = moment(req.dateOfBirth).format('YYYY-MM-DD');
    const age = calculateAge(req.dateOfBirth);
    if (age < 18) {
      message.error({content:"Investor age should be greater than or eqauls to 18!",style:{marginTop: '90vh'}});
      setSubmitting(false);
      return;
    }

    if(referrer){
      req.referral = referrer?.split('-')?.join(' ')?.replaceAll('_',' ');
    }

    const response = await accountService.registerInvestor(req);
    if (response.success) {
      window.scrollTo(0, 0);
      message.success({content:"Successfully registered.",style:{marginTop: '90vh'}});
      setRegistered(true);
      setRegUser(req)
      form.resetFields();
    } else {
      message.error({content:response.error.message,style:{marginTop: '90vh'}});
    }
    setSubmitting(false);
  };



  const handleReferralChange = (value) => {
    setReferral(value);
  };
  if (email) {
    form.setFieldsValue({ email: email });
    form.setFieldsValue({ firstName: referrer?.split('-')[0]?.replaceAll('_',' ') });
    form.setFieldsValue({ lastName: referrer?.split('-')[1]?.replaceAll('_',' ') });

  }

  const onFormValuesChange = (changedValues, allValues) => {
    setIsAccepted(allValues.acceptterms); // Update state based on checkbox value
  };

  return (
    <>

        <div className="registration-wrapper">

        <div>
          <Row gutter={56} className="registration-row-main">
            <Col xs={24} sm={24} md={12} className="registration-form-left">

                <div hidden={registered}>
                  {registered && (
                    <Alert style={{marginBottom:'15px'}}
                      message="We have received your registration request. Please self verify your email address to proceed."
                      type="success"
                    ></Alert>
                  )}

                  <h2 style={{color:'white'}}>Investor <br/> <span style={{color:'#EEC217'}}>Registration</span> </h2>
                  <Form
                    // {...formItemLayout}
                    layout="vertical"
                    form={form}
                    onFinish={register}
                    onValuesChange={onFormValuesChange}
                    // style={{ margin: "2% 10% 0 10%", width: "60%" }}
                  >
                    <Form.Item
                      label="First name"
                      name="firstName"
                      rules={[
                        {
                          required: true,
                          message: "Please input your First Name!",
                          whitespace: true,
                        },
                      ]}
                    >
                      <Input size="large"/>
                    </Form.Item>

                    <Form.Item
                      label="Last name"
                      name="lastName"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Last Name!",
                          whitespace: true,
                        },
                      ]}
                    >
                      <Input size="large"/>
                    </Form.Item>

                    <Form.Item
                      label="E-mail"
                      name="email"
                      rules={[
                        {
                          type: "email",
                          message: "The input is not valid E-mail!",
                        },
                        {
                          required: true,
                          message: "Please input your E-mail!",
                        },
                      ]}
                    >
                      <Input size="large" disabled={!!email}/>
                    </Form.Item>

                    <Form.Item
                      label="Password"
                      name="password"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: "Please input your password!",
                        },
                        {
                          validator: (rule, value) => {
                            const strongRegex =
                              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$\-_[\](){}<>|°¬+=~".,;:'#!%*?&])[A-Za-z\d@$\-_[\](){}<>|°¬+=~".,;:'#!%*?&]{8,}$/;
                            if (strongRegex.test(value))
                              return Promise.resolve();
                            return Promise.reject(
                              "The password must contains at least 1 number, 1 lowercase, 1 uppercase and 1 special character and 8 characters long"
                            );
                          },
                        },
                      ]}
                    >
                      <Input.Password size="large"/>
                    </Form.Item>

                    <Form.Item
                      label="Confirm Password"
                      name="confirm"
                      dependencies={["password"]}
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: "Please confirm your password!",
                        },
                        ({ getFieldValue }) => ({
                          validator(rule, value) {
                            if (!value || getFieldValue("password") === value)
                              return Promise.resolve();
                            return Promise.reject(
                              "The two passwords that you entered do not match!"
                            );
                          },
                        }),
                      ]}
                    >
                      <Input.Password size="large" />
                    </Form.Item>
                    <Form.Item
                      label="Date of birth"
                      name="dateOfBirth"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your date of birth",
                        },
                      ]}
                    >
                      <DatePicker placeholder="MM/DD/YYYY" size="large" style={{width:'100%'}} format="MM/DD/YYYY"
                        onChange={(date, dateString) => {
      console.log("Selected Date:", dateString);
    }}
    onBlur={() => form.validateFields(['dateOfBirth'])} 
                      />
                    </Form.Item>
                {/* <Form.Item
                        label="Wallet Address"
                        name="walletAddress"
                        rules={[
                          {
                            message: "Please enter your wallet address",
                          },
                          {
                            validator: (_, value) => {
                              if (!value || (value.length === 42 && value.startsWith('0x'))) {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error('Wallet address must be 42 characters and start with "0x"'));
                            },
                          },
                        ]}
                    >
                      <Input size="large" style={{ color: "green" }} />
                    </Form.Item>*/}

                    <Form.Item
                      label="Country"
                      name="country"
                      validateStatus={countries ? "" : "validating"}
                      //hasFeedback
                      help={countries ? "" : "Loading Countries..."}
                      rules={[
                        {
                          required: true,
                          message: "Select Country",
                        },
                      ]}
                    >
                      <Select
                      size="large"
                        showSearch
                        placeholder="Select Country"
                        onChange={currentCountry}
                        autoComplete="new-password"
                      >
                        {countries?.map((country) => (
                          <Option key={country._id} value={country.name["en"]}>
                            {country.name["en"]}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item >
                    {countryError && (
                      <Alert
                        message="The country you select and the country you are operating are different you can only register from the country you select"
                        type="error"
                      ></Alert>
                    )}
                    <Form.Item
                      label="Contact Number"
                      name="contactNumber"
                      rules={[
                        {
                          required: true,
                          message: "Enter number here",
                          whitespace: true,
                        },
                      ]}
                    >
                      <PhoneInput size="large" country={"us"} className='contactCustom'/>
                    </Form.Item>
                    {cCountry && (
                      <Form.Item
                        label="State"
                        name="state"
                        rules={[
                          {
                            required: true,
                            message: "Please input state!",
                            whitespace: true,
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          placeholder="Select state"
                          autoComplete="new-password"
                        >
                          {usaStates?.map((state) => (
                            <Option
                              key={state.state_id}
                              value={state.state_name}
                            >
                              {state.state_name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    )}
                    {!cCountry && (
                      <Form.Item
                        label="State"
                        name="state"
                        rules={[
                          {
                            required: true,
                            message: "Please input state!",
                            whitespace: true,
                          },
                        ]}
                      >
                        <Input size="large"/>
                      </Form.Item>
                    )}
                    <Form.Item
                      label="City"
                      name="city"
                      rules={[{ required: true, message: "City is required" }]}
                    >
                      <Input size="large" placeholder="" />
                    </Form.Item>
                    <Form.Item
                      label="Street Address 1"
                      name="streetAddress1"
                      rules={[
                        {
                          required: true,
                          message: "Street address 1 is required",
                        },
                      ]}
                    >
                      <Input size="large" placeholder="" />
                    </Form.Item>
                    <Form.Item label="Street Address 2" name="streetAddress2">
                      <Input size="large" placeholder="" />
                    </Form.Item>

                    <Form.Item
                      label="Zip code"
                      name="zipCode"
                      rules={[
                        {
                          required: true,
                          message: "Please input zip code!",
                        },
                      ]}
                    >
                      <Input size="large" min={0}  />
                    </Form.Item>
                  { !referrer &&
                    <>
                    <Form.Item label="Referral" name="referral" rules={[{ required: true, message: 'Please select a referral!' }]}>
                      <Select size="large" placeholder="Select Referral" onChange={handleReferralChange}>
                        <Option value="Rajeev Thorat">Rajeev Thorat</Option>
                        <Option value="Gautam Shah">Gautam Shah</Option>
                        <Option value="Social Media">Social Media</Option>
                        <Option value="Others">Others</Option>
                      </Select>
                    </Form.Item>

                    {referral === 'Social Media' && (
                      <Form.Item label="Select Social Media" name="socialMedia" rules={[{ required: true, message: 'Please select a social media platform!' }]}>
                        <Select size="large" placeholder="Select Social Media">
                          <Option value="Facebook">Facebook</Option>
                          <Option value="Instagram">Instagram</Option>
                          <Option value="LinkedIn">LinkedIn</Option>
                          <Option value="Twitter">Twitter</Option>
                        </Select>
                      </Form.Item>
                    )}

                    {referral === 'Others' && (
                      <Form.Item label="Referral Source" name="referralSource" rules={[{ required: true, message: 'Please provide the referral source!' }]}>
                        <Input size="large" placeholder="Please specify the referral source" />
                      </Form.Item>
                    )}
                    </>
                    }
                  <br />
                  <Form.Item name="acceptterms" valuePropName="checked">
                    <Checkbox style={{color:'white'}}>I hereby accept all term and conditions of the platform</Checkbox>
                  </Form.Item>

                    <Form.Item>
                      <Button
                        htmlType="submit"
                        className="login-form-button"
                        loading={submitting}
                        disabled={!isAccepted} // Negate the value here
                        >
                        Register Now
                      </Button>
                      <br />
                      {/* <>Or </>
                      <Link to="/account/login">login now!</Link> */}
                    </Form.Item>
                  </Form>

                  <Link to='/faq' target='_blank'><img className='faq-logo' src={faq} alt="" /></Link>
                  </div>

                <div className='success-banner' hidden={!registered}>
                  <Result
                    status="success"
                    title="We have sent a verification email to :"
                    subTitle={`${regUser.email}`}
                    extra={[
                      <Button
                        type="primary"
                        htmlType="button"
                        onClick={forwardToLogin}
                      >
                        Login
                      </Button>,
                    ]}
                  ></Result>
                </div>

            </Col>

            <Col xs={24} sm={24} md={12} className="registration-form-right">
                <p>Begin Your Investment Journey Register Today and Unlock New Opportunities.</p>

                 <Link to="/account/login">
                    <h4 className="login-now">Already An Investor? <span>Login Now</span></h4>
                  </Link>

                  <div className="reg-fig">
                    <img src={regfig} className="fig"  alt="none"/>

                    <ul>
                      <li><img src={check} className="check" alt='check'/>Empower Your Investments with the Future of Digital Finance.</li>
                      <li><img src={check} className="check" alt='check'/>Unlock the Power of Blockchain </li>
                      <li><img src={check} className="check" alt='check'/>Invest in Tomorrow's Technology Today</li>
                    </ul>
                  </div>
            </Col>
          </Row>
        </div>

        </div>

    </>
  );
};
